import { setDefaultOptions } from 'date-fns';
import { enUS, sv, de, da, nl, nb } from 'date-fns/locale';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from 'constants/dateFormat';
import { LANGS } from 'constants/enums';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';

import { TRANSLATIONS, getLanguage } from './lang.utils';

const LOCALE_MAP = {
  en: enUS,
  sv,
  de,
  da,
  nl,
  no: nb,
};

const LangContext = React.createContext({
  switchLang: noop,
  lang: DEFAULT_LOCALE,
});

const { Provider, Consumer } = LangContext;

interface Props {
  children: React.ReactNode;
}

const IntlProviderWrapper: React.FC<Props> = ({ children }: Props) => {
  const initialLanguage = getLanguage();
  const [lang, setLang] = useState(initialLanguage);
  const [messages, setMessages] = useState(TRANSLATIONS[initialLanguage]);

  useEffect(() => {
    setDefaultOptions({
      weekStartsOn: 1,
      locale: LOCALE_MAP[lang],
    });
  }, [lang]);

  const switchLang = (lang: LANGS) => {
    setLang(lang);
    setMessages(TRANSLATIONS[lang]);
    localStorage.setItem(LOCAL_STORAGE_KEYS.LANG, lang);
    window.location.reload();
  };

  return (
    <Provider value={{ lang, switchLang }}>
      <IntlProvider
        key={lang}
        locale={lang}
        messages={messages}
        defaultLocale="en"
        textComponent={React.Fragment}
      >
        {children}
      </IntlProvider>
    </Provider>
  );
};

export { IntlProviderWrapper as IntlProvider, Consumer as IntlConsumer };

export const useLang = () => React.useContext(LangContext);
