import { toJS } from 'mobx';

import { CareUnit } from 'api/careUnitsApi';

import { CareProvidersListItem } from './stores/types';

export const traverseCareProviderTreeAndAddCareunitPermissions = (
  careProviders: CareProvidersListItem[],
  careUnitPredicateFn: (careProvider: string, careUnitId: string) => boolean,
  careProviderPredicateFn: (careProvider: string) => boolean
) => {
  const newCareProviders = [...toJS(careProviders)];
  newCareProviders?.forEach(careProvider => {
    if (careProvider?.careUnits && careProvider?.careUnits.length > 0) {
      careProvider.children = careProvider.careUnits.map((careUnit: CareUnit) => ({
        ...careUnit,
        careProviderId: careProvider.id,
        canView: careUnitPredicateFn(careProvider.id, careUnit.id),
      }));
      careProvider.canView = careProviderPredicateFn(careProvider.id);
    }
  });
  return newCareProviders;
};
