import { observable, action, runInAction, IObservableArray, toJS } from 'mobx';

import RootStore from 'stores/RootStore';
import { generateUUID } from 'utils/uuidUtils';

import { NewsItem, fetchNewsItems, updateNewsItems } from '../api/newsItemsApi';

export default class AlertsStore {
  @observable isLoading = false;
  newsItems: IObservableArray<NewsItem> = observable.array([]);

  @observable activeItem?: NewsItem;

  constructor(private rootStore: RootStore, private originId: string) {}

  @action
  fetchNewsItems = async () => {
    try {
      this.isLoading = true;
      const { data } = await fetchNewsItems(this.originId);

      runInAction(() => {
        this.newsItems.replace(data);
      });
      /* eslint-disable no-empty */
    } catch {
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  saveData = async (newsItems: NewsItem[]) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      await updateNewsItems(newsItems, this.originId);
      runInAction(() => {
        this.newsItems.replace(newsItems);
      });
      this.rootStore.flashMessageService.translatedSuccess('origin.alerts.alerts-updated');
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  handleEdit = (uuid: string) => {
    this.activeItem = this.newsItems.find(item => item.uuid === uuid);
  };

  @action
  handleAdd = () => {
    this.activeItem = {
      subject: '',
      body: '',
      link: '',
      uuid: '',
    };
  };

  @action
  handleCancel = () => {
    this.activeItem = undefined;
  };

  handleDelete = async (uuid: string) => {
    this.saveData(this.newsItems.filter(item => item.uuid !== uuid));
  };

  handleSubmit = async (newNewsItem: NewsItem) => {
    try {
      const newNewsItems = toJS(this.newsItems);

      if (newNewsItem.uuid === '') {
        newNewsItems.push({ ...newNewsItem, uuid: generateUUID() });
      } else {
        const newNewsItemIndex = this.newsItems.findIndex(item => item.uuid === newNewsItem.uuid);

        if (newNewsItemIndex === -1) {
          throw new Error(`No item with index ${newNewsItemIndex}`);
        }

        newNewsItems[newNewsItemIndex] = newNewsItem;
      }

      await this.saveData(newNewsItems);

      runInAction(() => {
        this.activeItem = undefined;
      });
      /* eslint-disable no-empty */
    } catch {}
  };
}
