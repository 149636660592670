import { Button } from 'antd';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';

interface Props {
  isActive: boolean;
  onClick: () => void;
}

const HsaIdLogin = ({ isActive, onClick }: Props) => {
  const { authStore } = useContext(RootStoreContext);
  const history = useHistory();

  const handleClick = useCallback(async () => {
    try {
      await authStore.loginWithHsaId();
      onClick();
      /* eslint-disable no-empty */
    } catch (error: any) {
    } finally {
      history.push('/login/select-partner');
    }
  }, [authStore, onClick, history]);

  return (
    <Button
      onClick={handleClick}
      type="primary"
      block
      size="large"
      loading={isActive}
      data-testid="externalId-login-btn"
    >
      <FormattedMessage id="login.with-siths-card" />
    </Button>
  );
};

export default HsaIdLogin;
