export const LOGIN_ENDPOINTS = {
  HSAID_DEV: '/rest/test/practitioner/login',
  NORWEGIAN_BANK_ID_DEV: '/rest/test/practitioner/login',
  SITHS: '/rest/siths/login',
  SWEDISH_BANK_ID_START: '/rest/bankid/start',
  SWEDISH_BANK_ID_COLLECT: '/rest/bankid/collect',
  NORWEGIAN_BANK_ID_START: '/rest/openid/idfyclinic/login',
  NORWEGIAN_BANK_ID_COLLECT: '/rest/openid/idfyclinic/collect',
};

export const CHANGE_PARTNER_URL = '/rest/practitioner-auth/manage/v1/token';

export const LOGIN_SSO = {
  OPENID: '/rest/practitioner-auth/oidc/v2/startsso',
  SAML: '/rest/practitioner-auth/saml2/v2/startsso',
};

export enum LOGIN_METHODS {
  DEV_HSA_ID = 'DEV_HSA_ID',
  DEV_NORWEGIAN_BANK_ID = 'DEV_NORWEGIAN_BANK_ID',
  HSA_ID = 'HSA_ID',
  SWEDISH_BANK_ID = 'SWEDISH_BANK_ID',
  NORWEGIAN_BANK_ID = 'NORWEGIAN_BANK_ID',
  OPENIDCONNECT = 'OPENIDCONNECT',
  SAML = 'SAML',
}
