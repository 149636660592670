import toString from 'lodash/toString';

import {
  NO_SPACES_REGEX,
  EMAIL_REGEX,
  AT_LEAST_ONE_LETTER_REGEX,
  ALPHANUMERIC_CHARS_SPACES_REGEX,
  ALPHANUMERIC_CHARS_HYPHENS_REGEX,
  ALPHANUMERIC_LOWERCASE_DOT_DASHES_REGEX,
} from '../constants/regex';

const emailRegex = new RegExp(EMAIL_REGEX);

export const isEmpty = (val: string | number | null | undefined) =>
  val === null || val === undefined || val === '';

export const validateNotEmpty = (val: string | number | null | undefined) => !isEmpty(val);

export const validateLength = (val: string, maxLength: number, minLength = 0) => {
  const stringifiedVal = toString(val);
  return stringifiedVal.length >= minLength && stringifiedVal.length <= maxLength;
};

export const validateRegex = (val: string | number | null | undefined, regex: RegExp) => {
  if (!val && val !== 0) {
    return true;
  }
  return regex.test(toString(val));
};

export const validateEmail = (val: string) => validateRegex(val, emailRegex);

// validations reflect Twilio requirements - https://support.twilio.com/hc/en-us/articles/223181348-Getting-Started-with-Alphanumeric-Sender-ID-for-Twilio-Programmable-SMS
export const validateSmsSender = (val: string) =>
  validateLength(val, 11) &&
  validateRegex(val, ALPHANUMERIC_CHARS_SPACES_REGEX) &&
  validateRegex(val, AT_LEAST_ONE_LETTER_REGEX);

export const validateNoSpaces = (val: string) => validateRegex(val, NO_SPACES_REGEX);

export const validateNoUppercase = (val: string | undefined) => {
  if (typeof val !== 'string') {
    return false;
  }
  return val.toLowerCase() === val;
};

export const validateAlphanumericAndHyphens = (val: string) =>
  validateRegex(val, ALPHANUMERIC_CHARS_HYPHENS_REGEX);
