import { action, observable, computed, IObservableArray, runInAction, toJS } from 'mobx';

import { ACCESS_LEVEL } from 'api/permissionsApi';
import { LANGS } from 'constants/enums';
import { RESOURCE_TYPES } from 'constants/permissions';
import { RICH_TEXT_CONTENT_TYPES } from 'constants/text';
import RootStore from 'stores/RootStore';
import { InputOption } from 'types/types';

import {
  SelfCareInfo,
  fetchSelfCareInfoList,
  fetchSelfCareFile,
  saveSelfCareFile,
  fetchSelfCareInfo,
  deleteSelfCareFile,
  SELF_CARE_CONTENT_STATUS,
  SELF_CARE_STATUS,
} from '../Selfcare/api/selfcareApi';

export interface SelfCareAdvice {
  text: string;
  type: RICH_TEXT_CONTENT_TYPES;
}

class SelfcareStore {
  @observable searchQuery = '';
  @observable list: IObservableArray<SelfCareInfo> = observable.array([]);
  @observable isPreviewMode = true;
  @observable current?: SelfCareInfo;
  // Only Markdown formatted self care advices can be created as we plan to migrate to Markdown entirely.
  // So if there is no selfCareLocalizedFile (it's a new language version of the advice), we set the type
  // to be Markdown.
  @observable selfCareLocalizedFile: SelfCareAdvice = {
    type: RICH_TEXT_CONTENT_TYPES.MARKDOWN,
    text: '',
  };
  @observable chosenLanguage?: LANGS;
  @observable isNewSelfCareAdviceModalVisible = false;
  @observable isLoading = false;
  @observable isSaving = false;

  // Have to cache default self care advice content when replacing it
  // in case replacement is cancelled.
  cachedSelfCareLocalizedFile?: SelfCareAdvice;

  constructor(private rootStore: RootStore) {}

  @computed
  get filteredList(): SelfCareInfo[] {
    if (!this.searchQuery) {
      return this.list;
    }

    return this.list.filter((item: SelfCareInfo) =>
      item.id.toLowerCase().includes(this.searchQuery)
    );
  }

  @computed
  get selfcareAdvicesAsSelectOptions(): InputOption[] {
    return this.list.map(selfcareAdvice => ({
      label: selfcareAdvice.id,
      value: selfcareAdvice.id,
    }));
  }

  @computed
  get lowerCasedSelfcareAdvicesIds() {
    return this.list.map(({ id }) => id.toLowerCase());
  }

  @computed
  get lang(): LANGS | undefined {
    const { availableLanguages } = this.rootStore.content24Store;
    if (this.chosenLanguage && availableLanguages.includes(this.chosenLanguage)) {
      return this.chosenLanguage;
    }

    return availableLanguages[0];
  }

  @action
  handleSearchChange = (value: string) => {
    this.searchQuery = value;
  };

  @action
  handleSelfcareAdviceUpdate = async (text: string) => {
    const { partnerId } = this.rootStore.partnersStore;
    const {
      lang,
      current,
      selfCareLocalizedFile: { type },
    } = this;

    if (!current || !lang) {
      throw new Error('Cannot update self care advice without its params');
    }

    runInAction(() => {
      this.isSaving = true;
    });

    try {
      await saveSelfCareFile(partnerId, current.id, lang, type, text);

      runInAction(() => {
        const isCreatingBrandNewContent =
          !current.languageToContentPolicy[lang] &&
          !current.languageToStatus[lang] &&
          !current.languages.includes(lang);
        const isOverwritingDefaultSelfCareAdvice =
          current.languageToContentPolicy[lang] === SELF_CARE_CONTENT_STATUS.ONLY_REPLACE;

        // Update three content related params of self care advice data needed if we create new content
        if (isCreatingBrandNewContent) {
          current.languageToContentPolicy[lang] = SELF_CARE_CONTENT_STATUS.EDITABLE;
          current.languageToStatus[lang] = SELF_CARE_STATUS.LOCAL;
          current.languages.push(lang);
        }

        // Update statuses needed if we edit system default content
        if (isOverwritingDefaultSelfCareAdvice) {
          current.languageToContentPolicy[lang] = SELF_CARE_CONTENT_STATUS.EDITABLE;
          current.languageToStatus[lang] = SELF_CARE_STATUS.LOCAL;

          if (current.status === SELF_CARE_STATUS.SYSTEM_DEFAULT) {
            current.status = SELF_CARE_STATUS.SYSTEM_DEFAULT_AND_LOCAL;
          } else if (
            current.status === SELF_CARE_STATUS.SYSTEM_DEFAULT_AND_LOCAL &&
            !Object.values(current.languageToStatus).includes(SELF_CARE_STATUS.SYSTEM_DEFAULT)
          ) {
            current.status = SELF_CARE_STATUS.REPLACED_SYSTEM_DEFAULT;
          }

          if (!current.contentTypes.includes(RICH_TEXT_CONTENT_TYPES.MARKDOWN)) {
            current.contentTypes.push(RICH_TEXT_CONTENT_TYPES.MARKDOWN);
          }
        }

        // Then update self care advice content
        this.selfCareLocalizedFile = { text, type };
      });

      this.rootStore.flashMessageService.translatedSuccess('selfcare.data-saved');
    } finally {
      runInAction(() => {
        this.isSaving = false;
      });
    }
  };

  @action
  handleSelfcareAdviceDelete = async () => {
    const { partnerId } = this.rootStore.partnersStore;
    const { lang, current } = this;

    if (!current || !lang) {
      throw new Error('Cannot remove self care advice without its params');
    }

    if (current.languageToContentPolicy[lang] !== SELF_CARE_CONTENT_STATUS.EDITABLE) {
      throw new Error('Cannot remove non-editable self care advice');
    }

    runInAction(() => {
      this.isSaving = true;
    });

    try {
      const selfcareId = current.id;

      await deleteSelfCareFile(partnerId, selfcareId, lang);

      runInAction(() => {
        // First, reset to preview mode, as there is nothing to edit in deleted content
        this.isPreviewMode = true;
      });

      // Then refresh self care advice content. We cannot update it on our side, as we don't
      // know if the removed content was not the default one replacement.
      await this.fetchSelfcareAdvice(current.id, true);

      this.rootStore.flashMessageService.translatedSuccess('selfcare.data-deleted');
    } finally {
      runInAction(() => {
        this.isSaving = false;
      });
    }
  };

  @action
  handleLangTabChange = (key: LANGS) => {
    this.clearDefaultSelfCareAdviceContentIfNeeded(false);
    this.isPreviewMode = true;
    this.setLang(key);
  };

  @action
  togglePreviewMode = () => {
    this.clearDefaultSelfCareAdviceContentIfNeeded(this.isPreviewMode);
    this.isPreviewMode = !this.isPreviewMode;
  };

  @action
  clearDefaultSelfCareAdviceContentIfNeeded = (isGoingToEditSelfCareAdvice: boolean) => {
    const { lang, current } = this;

    if (!current || !lang) {
      throw new Error('Cannot edit self care advice without its params');
    }

    if (current.languageToContentPolicy[lang] === SELF_CARE_CONTENT_STATUS.ONLY_REPLACE) {
      if (isGoingToEditSelfCareAdvice) {
        this.cachedSelfCareLocalizedFile = toJS(this.selfCareLocalizedFile);
        this.clearCurrentLanguageVersionContent();
      } else if (!this.selfCareLocalizedFile?.text.trim() && this.cachedSelfCareLocalizedFile) {
        this.selfCareLocalizedFile = toJS(this.cachedSelfCareLocalizedFile);
        this.cachedSelfCareLocalizedFile = undefined;
      }
    }
  };

  @action
  setLang = (lang: LANGS) => {
    this.chosenLanguage = lang;
    this.clearCurrentLanguageVersionContent();

    // This is only a type guard, as self care data has to be set before language version
    // can be updated
    if (!this.current) {
      throw new Error('Cannot change language version without having self care data in place');
    }

    if (this.current.languages.includes(lang)) {
      this.fetchSelfcareLocalizedFile();
    }
  };

  @action
  clearCurrentLanguageVersionContent = () => {
    this.selfCareLocalizedFile = { type: RICH_TEXT_CONTENT_TYPES.MARKDOWN, text: '' };
  };

  @action
  clearCurrentSelfCareAdvice = () => {
    this.current = undefined;
    this.clearCurrentLanguageVersionContent();
    this.isPreviewMode = true;
    this.cachedSelfCareLocalizedFile = undefined;
  };

  @action
  handleSelfcareAdviceCreate = async (selfCareId: string, lang: LANGS) => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    runInAction(() => {
      this.isSaving = true;
    });

    try {
      await saveSelfCareFile(
        partnerId,
        selfCareId,
        lang,
        // Only Markdown formatted self care advices can be created as we plan to migrate to Markdown entirely
        RICH_TEXT_CONTENT_TYPES.MARKDOWN,
        ' '
      );

      runInAction(() => {
        // Initialize data in store, so that we don't have to fetch the same set from API
        // after user is redirected to edit advice screen.
        this.current = {
          id: selfCareId,
          status: SELF_CARE_STATUS.LOCAL,
          contentTypes: [RICH_TEXT_CONTENT_TYPES.MARKDOWN],
          languageToStatus: { [lang]: SELF_CARE_STATUS.LOCAL },
          languageToContentPolicy: { [lang]: SELF_CARE_CONTENT_STATUS.EDITABLE },
          languages: [lang],
        };
      });

      this.handleCancelAddSelfcareAdvice();

      this.rootStore.flashMessageService.translatedSuccess('selfcare.created');
    } finally {
      runInAction(() => {
        this.isSaving = false;
      });
    }
  };

  @action
  handleAddSelfcareAdvice = () => {
    this.isNewSelfCareAdviceModalVisible = true;
  };

  @action
  handleCancelAddSelfcareAdvice = () => {
    this.isNewSelfCareAdviceModalVisible = false;
  };

  fetchAll = async () => {
    const {
      partnersStore: { partnerId },
      userPermissionsStore,
    } = this.rootStore;

    if (
      !userPermissionsStore.getPermission(
        {
          // Currently there's no way to set partner scope permission in Manage24
          // Details: https://platform24.atlassian.net/browse/AX-30158
          originId: partnerId,
          resourceType: RESOURCE_TYPES.SELFCARE,
          accessLevel: ACCESS_LEVEL.READ,
        },
        true
      )
    ) {
      return;
    }

    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const { data } = await fetchSelfCareInfoList(partnerId);

      runInAction(() => {
        this.list.replace(data);
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  fetchSelfcareAdvice = async (id: string, forceRefresh?: boolean) => {
    if (!forceRefresh && this.current?.id === id) {
      return;
    }

    await this.fetchSelfcareAdviceInfo(id);

    // Lang and current self care advice data are initialized from different API requests.
    // They has to be completed beforehand, if any of them is empty, it means there is no advice with specific id.
    if (!this.lang || !this.current) {
      return;
    }

    // Fetch the current language content, if present in self care info
    if (this.current.languages.includes(this.lang)) {
      this.fetchSelfcareLocalizedFile();
    } else {
      // If self care info does not include current language, clear the content. Content can be there if self care
      // advice is refreshed, for example after language content removal.
      this.clearCurrentLanguageVersionContent();
    }
  };

  fetchSelfcareAdviceInfo = async (id: string) => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const { data } = await fetchSelfCareInfo(partnerId, id);

      runInAction(() => {
        this.current = data;
      });
    } catch (error: any) {
      const isNotFoundError =
        error.response?.status === 400 &&
        error.response?.data?.message === 'No code24 text found for specified parameters';

      if (isNotFoundError) {
        runInAction(() => {
          this.current = undefined;
        });
      } else {
        throw error;
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  fetchSelfcareLocalizedFile = async () => {
    const { partnerId } = this.rootStore.partnersStore;
    const { lang, current } = this;

    if (!current?.id || !lang) {
      throw new Error('Cannot fetch self care advice text without its id or language');
    }

    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const response = await fetchSelfCareFile(
        partnerId,
        current.id,
        lang,
        // We cannot be sure what's the content type for specific language version,
        // as the self care advice contentTypes property only tells which ones are used,
        // but does not map them to languages.
        // That's why we ask API to send Markdown if available, HTML either way.
        RICH_TEXT_CONTENT_TYPES.MARKDOWN_OR_HTML
      );

      runInAction(() => {
        // Content-type header includes also charset information, we have to ignore it when matching.
        this.selfCareLocalizedFile = {
          text: response.data,
          type: response.headers['content-type'].includes(RICH_TEXT_CONTENT_TYPES.HTML)
            ? RICH_TEXT_CONTENT_TYPES.HTML
            : RICH_TEXT_CONTENT_TYPES.MARKDOWN,
        };
      });
    } catch (error: any) {
      const isNotFoundError = error.response?.status === 404;

      if (isNotFoundError) {
        this.clearCurrentLanguageVersionContent();

        // Because of the API pod sync issue we can have stale language content data delivered as a self care info.
        // So if we fetch the language content and it is not there, we should fix the self care info.
        runInAction(() => {
          current.languages = current.languages.filter(currentLang => lang !== currentLang);
          delete current.languageToContentPolicy[lang];
          delete current.languageToStatus[lang];
        });
      } else {
        throw error;
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default SelfcareStore;
