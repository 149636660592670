import { NodeExpandOutlined } from '@ant-design/icons';
import { RectClipPath } from '@vx/clip-path';
import { Group } from '@vx/group';
import { Graph } from '@vx/network';
import { LinkProvidedProps, NodeProvidedProps } from '@vx/network/lib/types';
import { LinkHorizontalStep, LinkVerticalStep } from '@vx/shape';
import { Text } from '@vx/text';
import { Zoom as MZoom } from '@vx/zoom';
import { ProvidedZoom } from '@vx/zoom/lib/types';
import { Avatar } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Ref } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { COLORS } from 'constants/colors';
import RootStoreContext from 'context/RootStoreContext';
import {
  CODE24_MODEL_TYPES,
  QUESTION_SHORT_RESPONSES,
} from 'modules/Content24/Condition/constants/code24types';
import RootStore from 'stores/RootStore';

import styles from './Diagram.module.css';
import { MARGIN, SECTION, TOPBAR_HEIGHT } from '../../constants';
import DiagramStore, { DiagramStoreConfig, NodeType, Section } from '../../stores/DiagramStore';
import { getNodePositionForAxis, getText } from '../../utils';
import DiagramNavigation from '../DiagramNavigation';
import Minimap from '../Minimap';
import Circle from '../shapes/Circle';
import Hexagon from '../shapes/Hexagon';
import Rectangle from '../shapes/Rectangle';

/**
 * @notExported
 */
interface DiagramProps extends WrappedComponentProps {
  width: number;
  height: number;
  conditionId?: string;
  selectedNodeId?: string;
  focusedElementId?: string;
  showExits?: boolean;
  innerRef?: Ref<SVGSVGElement>;
  showMiniMap?: boolean;
}

// todo: fix all any types associated with zoom
const Zoom = MZoom as any;

@observer
class Diagram extends Component<DiagramProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  private diagramStore: DiagramStore;
  private parentQuestionResponsesWidth = 0;
  private zoom: ProvidedZoom | undefined;
  scale = 0.7;

  constructor(props: DiagramProps, context: RootStore) {
    super(props);
    const config: DiagramStoreConfig = {
      conditionId: this.props.conditionId,
      columnWidth: this.props.width,
    };
    this.diagramStore = new DiagramStore(context, config);
  }

  componentDidMount() {
    const { selectedNodeId, focusedElementId } = this.props;

    if (selectedNodeId) {
      this.setSelectedNode(selectedNodeId);
    }

    if (focusedElementId) {
      const node = this.diagramStore.nodes.find(node => node.data.id === focusedElementId);
      node && this.focusOnNode(node);
    }
  }

  componentDidUpdate(prevProps: DiagramProps) {
    const { selectedNodeId: oldSelectedNodeId, focusedElementId: oldFocusedElementId } = prevProps;
    const { selectedNodeId, focusedElementId } = this.props;

    if (selectedNodeId && selectedNodeId !== oldSelectedNodeId) {
      this.setSelectedNode(selectedNodeId);
    } else if (oldSelectedNodeId && selectedNodeId === undefined) {
      this.diagramStore.clearSelectedNode();
    }

    if (focusedElementId && focusedElementId !== oldFocusedElementId) {
      const node = this.diagramStore.nodes.find(node => node.data.id.includes(focusedElementId));
      node && this.focusOnNode(node);
    }
  }

  setSelectedNode = (id: string) => {
    const selectedNode = this.diagramStore.nodes.find(node => node.data.id === id);

    if (selectedNode) {
      this.diagramStore.setSelectedNode(selectedNode);
    }
  };

  handleNodeClick = (node: NodeType) => {
    const { selectedNodeId, setSelectedNodeId, clearSelectedNodeId, setNodeNavigation } =
      this.context.conditionVisualizationStore;
    setNodeNavigation(true);
    if (!node.data.dependencies || node.data.dependencies.length < 1) {
      return;
    }

    if (selectedNodeId && selectedNodeId === node.data.id) {
      clearSelectedNodeId();
    } else {
      setSelectedNodeId(node.data.id);
    }
  };

  focusOnNode = (node: NodeType) => {
    if (!this.zoom) {
      return;
    }

    const { x, y, width, height } = node;

    this.zoom.setTransformMatrix({
      scaleX: this.scale,
      scaleY: this.scale,
      translateX: (-x + (this.props.width + width / 2) / 2) * this.scale,
      translateY: (-y + (this.props.height + height) / 2) * this.scale,
      skewX: 0,
      skewY: 0,
    });
  };

  goToTheBottom = () => {
    if (!this.zoom) {
      return;
    }

    const { diagramHeight, diagramWidth } = this.diagramStore;
    this.zoom.setTransformMatrix({
      scaleX: this.scale,
      scaleY: this.scale,
      translateX: ((-diagramWidth * this.scale) / 2 + this.props.width / 2) / 2,
      translateY: -diagramHeight * this.scale + this.props.height / 2,
      skewX: 0,
      skewY: 0,
    });
  };

  goToTheTop = () => {
    if (!this.zoom) {
      return;
    }

    const { diagramWidth, transformMatrix } = this.diagramStore;
    this.zoom.setTransformMatrix({
      ...transformMatrix,
      scaleX: this.scale,
      scaleY: this.scale,
      translateX: ((-diagramWidth * this.scale) / 2 + this.props.width / 2) / 2,
    });
  };

  getInvertedZoomString = () => {
    if (!this.zoom) {
      return '';
    }

    const invertedZoomMatrix = this.zoom.invert();
    invertedZoomMatrix.translateX =
      this.diagramStore.coordinates.left * -1 + MARGIN.left + invertedZoomMatrix.translateX;
    const { scaleX, scaleY, skewX, skewY, translateX, translateY } = invertedZoomMatrix;

    const invertedZoomMatrixAsString =
      'matrix(' +
      scaleX +
      ', ' +
      skewY +
      ', ' +
      skewX +
      ', ' +
      scaleY +
      ', ' +
      translateX +
      ', ' +
      translateY +
      ')';

    return invertedZoomMatrixAsString;
  };

  renderNodeComponent = (props: NodeProvidedProps<NodeType>) => {
    const { node } = props;
    const { intl } = this.props;
    const hasDependencies = node.data.dependencies?.length > 0;

    switch (node.data.type) {
      case CODE24_MODEL_TYPES.QUESTION:
        if (props.node.childrenRowWidth) {
          this.parentQuestionResponsesWidth = props.node.childrenRowWidth;
        }

        return (
          <Rectangle
            width={node.width}
            height={node.height}
            type="question"
            x={getNodePositionForAxis(node.width)}
            y={getNodePositionForAxis(node.height)}
            title={node.data.questionId}
            text={getText(node.data.text, intl)}
            onClick={() => this.handleNodeClick(props.node)}
            isDimmed={node.data.isDimmed}
            min={node.data.min}
            max={node.data.max}
            className={classNames({ [styles.hasDependencies]: hasDependencies })}
            icon={
              hasDependencies ? (
                <Avatar
                  size="small"
                  icon={<NodeExpandOutlined />}
                  className={classNames(styles.dependenciesIcon, {
                    [styles.dimmed]: node.data.isDimmed,
                  })}
                />
              ) : null
            }
          />
        );
      case CODE24_MODEL_TYPES.RESPONSE:
        return (
          <Rectangle
            width={node.width}
            height={node.height}
            type="answerOption"
            x={getNodePositionForAxis(this.parentQuestionResponsesWidth)}
            y={getNodePositionForAxis(node.height)}
            title={node.data.responseId}
            text={getText(node.data.text, intl)}
            onClick={() => this.handleNodeClick(props.node)}
            isDimmed={node.data.isDimmed}
            isNotCondition={node.data.isNotConditionResponse}
            isYesCondition={node.data.isYesConditionResponse}
            isDependencyOfSelectedNode={node.data.isDependencyOfSelectedNode}
          />
        );
      case CODE24_MODEL_TYPES.GOTO:
        return (
          <Hexagon
            width={node.width}
            height={node.height}
            type="goto"
            x={getNodePositionForAxis(node.width)}
            y={getNodePositionForAxis(node.height)}
            title={node.data.id}
            text={node.data.text}
            onClick={() => this.handleNodeClick(props.node)}
            isDimmed={node.data.isDimmed}
            className={classNames({ [styles.hasDependencies]: node.data.dependencies.length > 0 })}
          />
        );
      case CODE24_MODEL_TYPES.EXIT:
        return (
          <Hexagon
            width={node.width}
            height={node.height}
            type={node.data.breaking ? 'breakingExit' : 'exit'}
            x={getNodePositionForAxis(node.width)}
            y={getNodePositionForAxis(node.height)}
            text={getText(node.data.text, intl)}
            isDimmed={node.data.isDimmed}
            className={classNames({ [styles.hasDependencies]: node.data.dependencies.length > 0 })}
          />
        );
      case QUESTION_SHORT_RESPONSES.MAYBE:
      case QUESTION_SHORT_RESPONSES.NO:
      case QUESTION_SHORT_RESPONSES.YES:
        return (
          <Circle
            width={node.width}
            height={node.height}
            radius={50}
            cx={node.width / 2}
            cy={50}
            x={getNodePositionForAxis(this.parentQuestionResponsesWidth)}
            y={getNodePositionForAxis(node.height)}
            type={node.data.type}
            title={node.data.responseId}
            text={node.data.text}
            onClick={() => this.handleNodeClick(props.node)}
            isDimmed={node.data.isDimmed}
          />
        );
      default:
        return null;
    }
  };

  renderLinkComponent = (
    props: LinkProvidedProps<{
      source: NodeType;
      target: NodeType;
      data: { [key: string]: any };
    }>
  ) => {
    const targetIsGoto = props.link.target.data.type === CODE24_MODEL_TYPES.GOTO;
    const isLastQuestionInSection =
      props.link.source.isLastInSection &&
      props.link.target.data.type === CODE24_MODEL_TYPES.QUESTION;

    if (targetIsGoto || isLastQuestionInSection) {
      return (
        <LinkHorizontalStep
          key={props.link.source.data.id}
          data={props.link}
          stroke={props.link.data.isDimmed ? 'grey' : COLORS.PRIMARY}
          strokeWidth="1"
          fill="none"
          opacity={props.link.data.isDimmed ? 0.1 : 1}
        />
      );
    }
    return (
      <LinkVerticalStep
        key={props.link.source.data.id}
        data={props.link}
        percent={0.55}
        stroke={props.link.data.isDimmed ? 'grey' : COLORS.PRIMARY}
        strokeWidth="1"
        fill="none"
        opacity={props.link.data.isDimmed ? 0 : 1}
        x={(node: NodeType) =>
          this.diagramStore.isChildNode(node)
            ? this.diagramStore.getChildXPositionForLink(node)
            : node.x
        }
      />
    );
  };

  renderSectionComponent = (section: Section, index: number) => (
    <svg
      width={section.width}
      height={section.height}
      x={section.x}
      y={section.y}
      // section id is not a unique value
      key={index}
    >
      <rect
        className={styles.sectionBackground}
        x={0}
        y={0}
        width={section.width}
        height={section.height}
      />
      <rect
        className={styles.sectionHeader}
        x={0}
        y={0}
        width={section.width}
        height={SECTION.header.height}
      />
      <Text
        width={section.width}
        x={section.width / 2}
        y={15}
        verticalAnchor="start"
        textAnchor="middle"
        className={styles.sectionText}
      >
        {section.data.id}
      </Text>
    </svg>
  );

  render() {
    const { width: columnWidth, height, intl, showMiniMap } = this.props;
    const innerHeight = height - TOPBAR_HEIGHT;
    const minimapScale = 0.03;
    const {
      externalSections,
      nodes,
      links,
      transformMatrix,
      diagramHeight,
      diagramWidth,
      coordinates,
    } = this.diagramStore;
    const { conditionVisualizationStore } = this.context;

    const graph = {
      nodes: this.props.showExits
        ? nodes
        : nodes.filter(node => node.data.type !== CODE24_MODEL_TYPES.EXIT),
      links,
    };

    return columnWidth < 10 ? null : (
      <div className={styles.wrapper}>
        <div className={styles.topBar}>{conditionVisualizationStore.getTitle(intl.locale)}</div>
        <Zoom
          width={columnWidth}
          height={innerHeight}
          scaleXMin={0.05}
          scaleXMax={1}
          scaleYMin={0.05}
          scaleYMax={1}
          transformMatrix={transformMatrix}
          wheelDelta={(event: any) =>
            -event.deltaY > 0 ? { scaleX: 1.04, scaleY: 1.04 } : { scaleX: 0.96, scaleY: 0.96 }
          }
        >
          {(zoom: any) => {
            this.zoom = zoom;
            return (
              <>
                <svg
                  width={columnWidth}
                  height={innerHeight}
                  fill="white"
                  ref={this.props.innerRef}
                >
                  <RectClipPath id="zoom-clip" width={diagramWidth} height={diagramHeight} />
                  <rect
                    className={styles.zoomArea}
                    onTouchStart={zoom.dragStart}
                    onTouchMove={zoom.dragMove}
                    onTouchEnd={zoom.dragEnd}
                    onMouseDown={zoom.dragStart}
                    onMouseMove={zoom.dragMove}
                    onMouseUp={zoom.dragEnd}
                    onMouseLeave={() => {
                      if (zoom.isDragging) zoom.dragEnd();
                    }}
                  />
                  <Group transform={zoom.toString()}>
                    {externalSections.map((section, index) =>
                      this.renderSectionComponent(section, index)
                    )}
                    <Graph
                      graph={graph}
                      linkComponent={this.renderLinkComponent}
                      nodeComponent={this.renderNodeComponent}
                    />
                  </Group>
                </svg>
                <DiagramNavigation
                  zoom={zoom}
                  goToTheBottom={this.goToTheBottom}
                  goToTheTop={this.goToTheTop}
                  nodes={nodes}
                  selectedNode={this.diagramStore.selectedNode}
                  margin={showMiniMap ? diagramWidth * minimapScale + 10 : 10}
                />

                {showMiniMap && (
                  <Minimap
                    svgWidth={diagramWidth}
                    svgHeight={diagramHeight}
                    minimapTrasform={`translate(${coordinates.left * -1 + MARGIN.left},0)`}
                    minimapTranslate={{ x: 0, y: 30 }}
                    visibleAreaWidth={columnWidth}
                    visibleAreaHeight={innerHeight}
                    visibleAreaTransform={this.getInvertedZoomString()}
                    minimapScale={minimapScale}
                    content={
                      <>
                        {externalSections.map((section, index) =>
                          this.renderSectionComponent(section, index)
                        )}
                        <Graph
                          graph={graph}
                          linkComponent={this.renderLinkComponent}
                          nodeComponent={this.renderNodeComponent}
                        />
                      </>
                    }
                  />
                )}
              </>
            );
          }}
        </Zoom>
      </div>
    );
  }
}

export default injectIntl(
  React.forwardRef((props: DiagramProps, innerRef: Ref<SVGSVGElement>) => (
    <Diagram innerRef={innerRef} {...props} />
  ))
);
