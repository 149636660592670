import { Typography, Col, Row, Select as AntdSelect } from 'antd';
import { DefaultOptionType, SelectValue } from 'antd/lib/select';
import { Select, Form } from 'formik-antd';
import get from 'lodash/get';
import { toJS } from 'mobx';
import React, { FunctionComponent, useContext, Fragment, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LANGS } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';
import { EXIT_CATEGORY_SOURCE } from 'modules/Content24/Condition/api/partnerCode24api';
import {
  CODE24_MODEL_TYPES,
  DISABLED_EXIT_ATTRIBUTES,
  EXIT_ATTRIBUTES,
} from 'modules/Content24/Condition/constants/code24types';
import { ExitAttributes } from 'modules/Content24/Condition/models/Code24Model';
import { InputOption } from 'types/types';
import { filterSelectOption } from 'utils/formUtils';
import { sortWithLocale } from 'utils/textUtils';

import styles from './ExitAttributeFormFields.module.css';
import { ExitFormData } from '../ExitForm/ExitForm';

export interface ExitAttributesExtended extends ExitAttributes {
  [key: string]: unknown;
}

/**
 * @notExported
 */
interface ExitAttributeFormFieldsProps {
  values: ExitFormData;
  isDisabled?: boolean;
  setFieldValue: (fieldName: string, value: unknown) => void;
}

const ExitAttributeFormFields: FunctionComponent<ExitAttributeFormFieldsProps> = ({
  isDisabled,
  values,
  setFieldValue,
}) => {
  const intl = useIntl();
  const { content24Store, selfcareStore, partnersStore } = useContext(RootStoreContext);
  const { urgency, capability, levelOfCare, typeOfConsultation, resource, appointmentPriority } =
    toJS(content24Store.exitAttributes);
  const asterisk = <span className={styles.asterisk}>*</span>;

  const availableExitAttributes =
    partnersStore.partnerCustomizations.get('CODE24_AVAILABLE_EXIT_ATTRIBUTES') || [];
  const isLevelOfCareEnabled = availableExitAttributes.includes(EXIT_ATTRIBUTES.LEVEL_OF_CARE);
  const isUrgencyEnabled = availableExitAttributes.includes(EXIT_ATTRIBUTES.URGENCY);
  const isResourceEnabled = availableExitAttributes.includes(EXIT_ATTRIBUTES.RESOURCE);
  const isCapabilityEnabled = availableExitAttributes.includes(EXIT_ATTRIBUTES.CAPABILITY);
  const isTypeOfConsultationEnabled = availableExitAttributes.includes(
    EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION
  );
  const isUrlEnabled =
    availableExitAttributes.includes(EXIT_ATTRIBUTES.URL) &&
    get(values, EXIT_ATTRIBUTES.URGENCY) === 'wait';
  const isAppointmentPriorityEnabled =
    availableExitAttributes.includes(EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY) &&
    get(values, 'type') === CODE24_MODEL_TYPES.EXIT;
  const typeOfConsultationValue = get(values, EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION);

  const exitAttributeFilterSelectOption = useCallback(
    (value: string, option: DefaultOptionType | InputOption<string, string> | null | undefined) =>
      filterSelectOption(value, option, 0),
    []
  );

  useEffect(() => {
    selfcareStore.fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Typography.Title level={4}>
        <FormattedMessage id="condition-edit.exit-attribute-header" />
      </Typography.Title>
      <Typography.Paragraph>
        <FormattedMessage id="general.errors.at-least-one-field-required" />
      </Typography.Paragraph>
      <Row gutter={16}>
        {isUrgencyEnabled && (
          <Col span={4}>
            <Form.Item
              name={EXIT_ATTRIBUTES.URGENCY}
              label={<FormattedMessage id="condition-edit.urgency-label" />}
            >
              <AntdSelect
                disabled={isDisabled}
                showSearch
                filterOption={exitAttributeFilterSelectOption}
                allowClear
                // Clearing the Select content with allowClear sets the value to undefined if mode is not multiple.
                // That's not handled well by Formik - if the value is initially undefined,
                // then it's set and then cleared to be undefined again, Formik treats it
                // as an update (form becomes dirty). Have to use empty string as default then.
                onChange={value => setFieldValue(EXIT_ATTRIBUTES.URGENCY, !value ? '' : value)}
                value={values[EXIT_ATTRIBUTES.URGENCY]}
              >
                {urgency
                  .sort((a, b) => sortWithLocale(a, b, `display.${intl.locale}`))
                  .map(({ id, display, source }) => (
                    <AntdSelect.Option key={id} value={id}>
                      {(display as Record<LANGS, string>)[intl.locale as LANGS] || id}
                      {source !== EXIT_CATEGORY_SOURCE.SYSTEM && asterisk}
                    </AntdSelect.Option>
                  ))}
              </AntdSelect>
            </Form.Item>
          </Col>
        )}
        {isAppointmentPriorityEnabled && (
          <Col span={4}>
            <Form.Item
              name={EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY}
              label={<FormattedMessage id="condition-edit.appointmentPriority-label" />}
            >
              <AntdSelect
                disabled={isDisabled}
                allowClear
                // Clearing the Select content with allowClear sets the value to undefined if mode is not multiple.
                // That's not handled well by Formik - if the value is initially undefined,
                // then it's set and then cleared to be undefined again, Formik treats it
                // as an update (form becomes dirty). Have to use null as default then.
                onChange={value =>
                  setFieldValue(EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY, !value ? null : value)
                }
                // complains about SelectValue type not being used to define the value, no workaround for this yet. See comment in transformInitialData method in ExitForm for more details
                value={values[EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY] as SelectValue}
              >
                {appointmentPriority.map(({ id, display, source }) => (
                  <AntdSelect.Option key={id} value={id}>
                    {(display as Record<LANGS, string>)[intl.locale as LANGS] || id}
                    {source !== EXIT_CATEGORY_SOURCE.SYSTEM && asterisk}
                  </AntdSelect.Option>
                ))}
              </AntdSelect>
            </Form.Item>
          </Col>
        )}
        {isTypeOfConsultationEnabled && (
          <Col span={4}>
            <Form.Item
              name={EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION}
              label={<FormattedMessage id="condition-edit.typeOfConsultation-label" />}
            >
              <Select
                name={EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION}
                disabled={isDisabled}
                mode="multiple"
                filterOption={exitAttributeFilterSelectOption}
                placeholder={
                  <FormattedMessage id="condition-edit.typeOfConsultation-placeholder" />
                }
                allowClear
              >
                {typeOfConsultation
                  .filter(
                    ({ id }) =>
                      typeOfConsultationValue?.includes(id as string) ||
                      !DISABLED_EXIT_ATTRIBUTES.includes(id)
                  )
                  .sort((a, b) => sortWithLocale(a, b, `display.${intl.locale}`))
                  .map(({ id, display, source }) => (
                    <Select.Option key={id} value={id}>
                      {(display as Record<LANGS, string>)[intl.locale as LANGS] || id}
                      {source !== EXIT_CATEGORY_SOURCE.SYSTEM && asterisk}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {isUrlEnabled && (
          <Col span={4}>
            <Form.Item
              name={EXIT_ATTRIBUTES.URL}
              label={<FormattedMessage id="condition-edit.selfcare-advice-label" />}
            >
              <AntdSelect
                disabled={isDisabled}
                showSearch
                onSearch={selfcareStore.handleSearchChange}
                optionFilterProp="label"
                allowClear
                // Clearing the Select content with allowClear sets the value to undefined of mode is not multiple.
                // That's not handled well by Formik - if the value is initially undefined,
                // then it's set and then cleared to be undefined again, Formik treats it
                // as an update (form becomes dirty). Have to use empty string as default then.
                options={selfcareStore.selfcareAdvicesAsSelectOptions}
                onChange={value => setFieldValue(EXIT_ATTRIBUTES.URL, !value ? '' : value)}
                value={values[EXIT_ATTRIBUTES.URL]}
              />
            </Form.Item>
          </Col>
        )}
        {isLevelOfCareEnabled && (
          <Col span={4}>
            <Form.Item
              name={EXIT_ATTRIBUTES.LEVEL_OF_CARE}
              label={<FormattedMessage id="condition-edit.levelOfCare-label" />}
            >
              <AntdSelect
                disabled={isDisabled}
                showSearch
                allowClear
                // Clearing the Select content with allowClear sets the value to undefined if mode is not multiple.
                // That's not handled well by Formik - if the value is initially undefined,
                // then it's set and then cleared to be undefined again, Formik treats it
                // as an update (form becomes dirty). Have to use empty string as default then.
                filterOption={exitAttributeFilterSelectOption}
                onChange={value =>
                  setFieldValue(EXIT_ATTRIBUTES.LEVEL_OF_CARE, !value ? '' : value)
                }
                value={values[EXIT_ATTRIBUTES.LEVEL_OF_CARE]}
              >
                {levelOfCare
                  .sort((a, b) => sortWithLocale(a, b, `display.${intl.locale}`))
                  .map(({ id, display, source }) => (
                    <Select.Option key={id} value={id}>
                      {(display as Record<LANGS, string>)[intl.locale as LANGS] || id}
                      {source !== EXIT_CATEGORY_SOURCE.SYSTEM && asterisk}
                    </Select.Option>
                  ))}
              </AntdSelect>
            </Form.Item>
          </Col>
        )}
        {isResourceEnabled && (
          <Col span={4}>
            <Form.Item
              name={EXIT_ATTRIBUTES.RESOURCE}
              label={<FormattedMessage id="condition-edit.resource-label" />}
            >
              <AntdSelect
                disabled={isDisabled}
                showSearch
                allowClear
                // Clearing the Select content with allowClear sets the value to undefined if mode is not multiple.
                // That's not handled well by Formik - if the value is initially undefined,
                // then it's set and then cleared to be undefined again, Formik treats it
                // as an update (form becomes dirty). Have to use empty string as default then.
                filterOption={exitAttributeFilterSelectOption}
                onChange={value => setFieldValue(EXIT_ATTRIBUTES.RESOURCE, !value ? '' : value)}
                value={values[EXIT_ATTRIBUTES.RESOURCE]}
              >
                {resource
                  .sort((a, b) => sortWithLocale(a, b, `display.${intl.locale}`))
                  .map(({ id, display, source }) => (
                    <AntdSelect.Option key={id} value={id}>
                      {(display as Record<LANGS, string>)[intl.locale as LANGS] || id}
                      {source !== EXIT_CATEGORY_SOURCE.SYSTEM && asterisk}
                    </AntdSelect.Option>
                  ))}
              </AntdSelect>
            </Form.Item>
          </Col>
        )}
        {isCapabilityEnabled && (
          <Col span={4}>
            <Form.Item
              name={EXIT_ATTRIBUTES.CAPABILITY}
              label={<FormattedMessage id="condition-edit.capability-label" />}
            >
              <Select
                name={EXIT_ATTRIBUTES.CAPABILITY}
                disabled={isDisabled}
                mode="multiple"
                filterOption={exitAttributeFilterSelectOption}
                allowClear
              >
                {capability
                  .sort((a, b) => sortWithLocale(a, b, `display.${intl.locale}`))
                  .map(({ id, display, source }) => (
                    <Select.Option key={id} value={id}>
                      {(display as Record<LANGS, string>)[intl.locale as LANGS] || id}
                      {source !== EXIT_CATEGORY_SOURCE.SYSTEM && asterisk}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      {content24Store.hasPartnerSpecificExitAttributes && (
        <aside className={styles.footnote}>
          <span>
            * <FormattedMessage id="general.mandatory-field" />
          </span>
          <span>
            {asterisk} <FormattedMessage id="partners.partner-specific-value" />
          </span>
        </aside>
      )}
    </Fragment>
  );
};

export default ExitAttributeFormFields;
