import { gold } from '@ant-design/colors';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Typography, Modal } from 'antd';
import type { Action, Location } from 'history';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, Prompt } from 'react-router-dom';
import { styled } from 'styled-components';

import { useBoolean } from 'hooks/useBoolean';

const Styled = {
  ModalContent: styled.div`
    display: flex;
    padding: 8px;
  `,
  IconContainer: styled.div`
    margin-right: 16px;
  `,
  ExclamationCircleOutlined: styled(ExclamationCircleOutlined)`
    font-size: 22px;
    color: ${gold[5]};
  `,
};

export const UnsavedChangesNavigationBlocker = ({
  checkHasChanges,
}: {
  checkHasChanges: () => boolean;
}) => {
  const history = useHistory();
  const modalVisible = useBoolean(false);
  const [nextLocation, updateNextLocation] = useState<Location | null>(null);
  const [action, updateAction] = useState<Action | null>(null);
  const confirmedNavigation = useBoolean(false);

  const modalContainerRef = useRef<HTMLDivElement>(null);

  const shouldBePrompted = useBoolean(false);
  useEffect(
    function preventBrowserTabClose() {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (checkHasChanges()) {
          event.preventDefault();
          shouldBePrompted.setTrue();
        } else {
          shouldBePrompted.setFalse();
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    },
    [checkHasChanges, shouldBePrompted]
  );

  const showModal = useCallback(
    (location: Location, action: Action) => {
      modalVisible.setTrue();
      updateNextLocation(location);
      updateAction(action);
    },
    [modalVisible]
  );

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location, action: Action) => {
      if (!confirmedNavigation.value && checkHasChanges()) {
        showModal(nextLocation, action);
        return false;
      }
      return true;
    },
    [checkHasChanges, confirmedNavigation.value, showModal]
  );

  const handleConfirmNavigationClick = useCallback(() => {
    modalVisible.setFalse();
    if (nextLocation) {
      confirmedNavigation.setTrue();
    }
  }, [confirmedNavigation, modalVisible, nextLocation]);

  useEffect(
    function navigateIfConfirmed() {
      if (confirmedNavigation && nextLocation && action) {
        // nb: we ignore POP action type on purpose,
        // as it's triggered on both back/forward browser navigation which is confusing
        const method = action === 'REPLACE' ? history.replace : history.push;
        method(nextLocation?.pathname);
        confirmedNavigation.setFalse();
      }
    },
    // nb: Modal keeps being rerendered if just `nextLocation` is added
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, history, nextLocation?.pathname]
  );

  return (
    <>
      <div ref={modalContainerRef} />

      <Prompt message={handleBlockedNavigation} />

      <Modal
        getContainer={() => modalContainerRef.current || document.body}
        cancelText={<FormattedMessage id="articles.cancel-editing.abort" />}
        closeIcon={null}
        okText={<FormattedMessage id="articles.cancel-editing.confirm" />}
        open={modalVisible.value}
        onCancel={modalVisible.setFalse}
        onOk={handleConfirmNavigationClick}
      >
        <Styled.ModalContent>
          <Styled.IconContainer>
            <Styled.ExclamationCircleOutlined />
          </Styled.IconContainer>
          <div>
            <Typography.Title level={5}>
              <FormattedMessage id="articles.cancel-editing.title" />
            </Typography.Title>
            <FormattedMessage id="articles.cancel-editing.description" />
          </div>
        </Styled.ModalContent>
      </Modal>
    </>
  );
};
