import { CheckOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Table, Button, Typography, Col, Row } from 'antd';
import type { InputRef } from 'antd';
import { Formik, FormikValues } from 'formik';
import { Form, Input } from 'formik-antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType, createRef } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { TRANSLATION_SOURCE } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';
import RootStore from 'stores/RootStore';

import { TextContent } from './api/textContentApi';
import EditTextContent from './components/EditTextContent';
import TextContentStore from './stores/TextContentStore';
import styles from './TextContents.module.css';

interface Props extends WrappedComponentProps, RouteComponentProps<{ originId: string }> {}

@observer
class TextContents extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  textContentStore: TextContentStore;

  inputRef = createRef<InputRef>();

  constructor(props: Props, context: RootStore) {
    super(props, context);

    this.textContentStore = new TextContentStore(context, props.match.params.originId);
  }

  get columns() {
    const {
      userPermissionsStore,
      originStore: { currentOrigin },
    } = this.context;
    const language = currentOrigin?.defaultLanguage || this.props.intl.locale;

    return [
      {
        title: <FormattedMessage id="origin.text-content.functionality" />,
        dataIndex: 'key',
      },
      {
        title: <FormattedMessage id="general.updated" />,
        dataIndex: 'source',
        width: '10%',
        render: (_: string, { source }: TextContent) => (
          <Fragment>
            {Object.values(source).some(value => value !== TRANSLATION_SOURCE.SYSTEM) && (
              <CheckOutlined />
            )}
          </Fragment>
        ),
      },
      {
        title: <FormattedMessage id={`general.language-${language}`} />,
        dataIndex: ['translations', language],
      },
      {
        title: <FormattedMessage id="general.actions" />,
        width: 100,
        render: (textContent: TextContent) => (
          <Fragment>
            <Button
              type="link"
              icon={
                userPermissionsStore.canEditCurrentPartner ||
                userPermissionsStore.canEditOrigin(this.props.match.params.originId) ? (
                  <EditOutlined />
                ) : (
                  <EyeOutlined />
                )
              }
              onClick={() => this.textContentStore.handleEdit(textContent.key)}
            />
          </Fragment>
        ),
      },
    ];
  }

  handleSearch = async (values: FormikValues) => {
    await this.textContentStore.handleSearch(values.searchPhrase);

    this.inputRef.current && this.inputRef.current.focus();
  };

  render() {
    const { activeTextContent, isLoading, handleCancel, handleSubmit, textContent } =
      this.textContentStore;
    const { intl } = this.props;

    return (
      <Fragment>
        <Typography.Title level={2}>
          <FormattedMessage id="origin.text-content.header" />
        </Typography.Title>
        <Row justify="space-between" align="middle" gutter={16}>
          <Col span={12}>
            <FormattedMessage id="origin.text-content.subheader" />
          </Col>
          <Col span={12}>
            <Formik
              initialValues={{ searchPhrase: '' }}
              onSubmit={this.handleSearch}
              render={() => (
                <Form className={styles.searchBox}>
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'origin.text-content.placeholder',
                    })}
                    name="searchPhrase"
                    data-testid="search-input"
                    disabled={isLoading}
                    ref={this.inputRef}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    data-testid="search-btn"
                    loading={isLoading}
                  >
                    <FormattedMessage id="origin.text-content.search" />
                  </Button>
                </Form>
              )}
            />
          </Col>
        </Row>
        <Table
          columns={this.columns}
          loading={isLoading}
          dataSource={toJS(textContent)}
          scroll={{ y: 350 }}
          rowKey="key"
          className={styles.table}
        />
        <EditTextContent
          initialValues={activeTextContent}
          isSaving={isLoading}
          isDisabled={
            !this.context.userPermissionsStore.canEditCurrentPartner &&
            !this.context.userPermissionsStore.canEditOrigin(this.props.match.params.originId)
          }
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Fragment>
    );
  }
}

export default injectIntl(TextContents);
