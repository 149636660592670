import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PractitionersList from 'modules/PractitionersList';

import RolesEdit from './components/RolesEdit';

const Roles = () => (
  <Switch>
    <Route exact path="/roles" component={PractitionersList} />
    <Route path="/roles/add" component={RolesEdit} />
    <Route path="/roles/:id/edit" component={RolesEdit} />
  </Switch>
);

export default Roles;
