import flattenDeep from 'lodash/flattenDeep';
import { action, computed, IObservableArray, observable, ObservableMap, runInAction } from 'mobx';

import { CareUnitBase } from 'api/careUnitsApi';
import { fetchConfiguration } from 'api/configApi';
import {
  ChildOrigin,
  deletePartnerUserRole,
  fetchPartners,
  fetchPartnerUsers,
  Partner,
  PartnerCareProvider,
  PartnerUserRole,
  updatePartnerUserRole,
} from 'api/partnersApi';
import { CURRENT_ENV_TYPE, ENV } from 'constants/env';
import { sortWithLocale } from 'utils/textUtils';
import { getPartnerId } from 'utils/tokenUtils';

import StateManager from './abstractStores/StateManager';
import ImageStore from './ImageStore';
import RootStore from './RootStore';

/**
 * @deprecated Use the Jotai atom inside src/state directory instead
 */
export default class PartnersStore extends StateManager {
  imageStore: ImageStore;
  @observable.shallow partners: IObservableArray<Partner> = observable.array([], { deep: false });
  @observable.shallow careProviders: IObservableArray<PartnerCareProvider> = observable.array([], {
    deep: false,
  });
  @observable.shallow rootOrigins: IObservableArray<ChildOrigin> = observable.array([], {
    deep: false,
  });
  @observable currentPartner?: Partner;
  @observable partnerRoles: IObservableArray<PartnerUserRole> = observable.array([]);
  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable partnerCustomizations: ObservableMap<string, any> = observable.map();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable partnerConfig: ObservableMap<string, any> = observable.map();

  @observable.shallow countryCallingCodes: IObservableArray<string> = observable.array([], {
    deep: false,
  });

  @observable defaultLanguage = 'sv';

  constructor(private rootStore: RootStore) {
    super();
    this.imageStore = new ImageStore();
  }

  fetchPartners = async () => {
    this.setLoading();

    try {
      const { data } = await fetchPartners();

      runInAction(() => {
        this.partners.replace(data);
      });

      this.setLoaded();
      return data;
    } catch (e) {
      this.manageException(e);
      return undefined;
    }
  };

  fetchPartnerConfiguration = async () => {
    this.setLoading();
    try {
      const { data } = await fetchConfiguration();
      runInAction(() => {
        const configuration = new Map();
        for (let i = 0; data && i < data.length; i++) {
          const { key, value } = data[i];
          configuration.set(key, value);
        }

        this.partnerConfig.replace(configuration);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  fetchCountryCallingCodes = async () => {
    this.setLoading();

    try {
      const { data } = await fetchConfiguration();
      const callingCodes = data?.find(item => item.key === 'COUNTRY_CALLING_CODES')?.value || [];

      runInAction(() => {
        this.countryCallingCodes.replace(callingCodes as string[]);
        this.setLoaded();
      });
    } catch (e) {
      this.manageException(e);
    }
  };

  setCurrentPartner = async () => {
    const partnerId = getPartnerId();

    if (!partnerId) {
      this.rootStore.flashMessageService.translatedError('partners.errors.partner-not-found');
      return;
    }

    if (!this.partners.length) {
      await this.fetchPartners();
    }

    const partner = this.partners.find(p => p.id === partnerId);

    if (!partner) {
      this.rootStore.flashMessageService.translatedError('partners.errors.partner-not-found');
      return;
    }

    try {
      const [{ data: partnerCustomizations }] = await Promise.all([fetchConfiguration()]);

      runInAction(() => {
        this.currentPartner = partner;
        this.partnerCustomizations = this.partnerCustomizations.replace(
          partnerCustomizations.map(({ key, value }) => [key, value])
        );

        this.defaultLanguage =
          (partnerCustomizations.find(({ key }) => key === 'DEFAULT_LANGUAGE')?.value as string) ||
          'sv';

        const careProviders = partner.careProviders?.length ? partner.careProviders : [];
        const rootOrigins = partner.origins?.length
          ? partner.origins.map(origin => ({
              ...origin,
              origins:
                origin.origins && origin.origins.length
                  ? this.addParentsToOrigins(origin.origins, origin.id)
                  : origin.origins,
            }))
          : [];

        this.careProviders.replace(careProviders);
        this.rootOrigins.replace(rootOrigins);
        this.imageStore.initialize(partner.id);
      });
    } catch {
      // partner Id should be set anyway if endpoints are failing.
      runInAction(() => {
        this.currentPartner = partner;
      });
    }
  };

  @computed
  get partnerId() {
    return getPartnerId() || '';
  }

  private addParentsToOrigins = (origins: ChildOrigin[], parentId: string): ChildOrigin[] =>
    origins.map(o => ({
      ...o,
      origins:
        o.origins && o.origins.length ? this.addParentsToOrigins(o.origins, o.id) : o.origins,
      parentId,
    }));

  @computed
  get partnersAsSelectOptions() {
    return this.partners
      .map(partner => ({
        value: partner.id,
        label: partner.id,
      }))
      .sort((a, b) => sortWithLocale(a, b, 'label'));
  }

  @computed
  get isReadOnlyModeEnabled() {
    return CURRENT_ENV_TYPE === ENV.DEMO || this.rootStore.partnerStatusStore.isMergeRequestPending;
  }

  @computed
  get allOrigins() {
    return this.flattenOrigins(this.rootOrigins.slice());
  }

  flattenOrigins = (origins: ChildOrigin[]) => {
    const flat: ChildOrigin[] = [];

    origins.forEach(origin => {
      flat.push(origin);
      if (origin.origins && origin.origins.length) {
        flat.push(...this.flattenOrigins(origin.origins));
      }
    });

    return flat;
  };

  isRootOrigin(originId: string) {
    return this.rootOrigins.some(({ id }) => originId === id);
  }

  fetchPartnerUsersRoles = async () => {
    this.setLoading();

    try {
      const { data } = await fetchPartnerUsers(this.rootStore.partnersStore.partnerId);

      const partnerUsers = data.map(
        ({ practitionerId = '', practitionerGivenName, practitionerMiddleAndSurname, role }) => ({
          practitionerId,
          practitionerName: `${practitionerGivenName} ${practitionerMiddleAndSurname}`,
          role,
        })
      );

      runInAction(() => {
        this.partnerRoles.replace(partnerUsers);
      });

      this.setLoaded();
    } catch (error) {
      this.manageException(error);
    }
  };

  @computed
  get currentPartnerCareUnits(): CareUnitBase[] {
    if (this.careProviders?.length) {
      const allCareUnits = this.careProviders.map(careProvider => careProvider.careUnits || []);

      return flattenDeep(allCareUnits);
    }
    return [];
  }
}
