import axios from 'axios';

import HttpInterceptor from 'config/HttpInterceptor';
import { DefaultFlashMessageService } from 'services/DefaultFlashMessageService';
import EventTrackingService from 'services/EventTrackingService';
import RootStore from 'stores/RootStore';

import { jotaiStore } from './jotaiStore';

export const defaultFlashMessageService = new DefaultFlashMessageService();
export const rootStore = new RootStore(defaultFlashMessageService, jotaiStore);

export const defaultInterceptorsBuilder = new HttpInterceptor(rootStore);
defaultInterceptorsBuilder.registerInterceptors(axios);

export const eventTrackingService = new EventTrackingService(rootStore);

export const enum EVENT_NAMES {
  MANAGE_ERROR = 'manageError',
}
