import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm } from 'antd';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styled } from 'styled-components';
import * as Yup from 'yup';

import { Image } from 'components/Image';
import { PopoverTooltip } from 'components/PopoverTooltip';
import {
  getEntityPlacementDataByKey,
  getImageIdFromFileName,
} from 'components/RichTextEditor/utils';
import RootStoreContext from 'context/RootStoreContext';

const Styled = {
  FormItem: styled(Form.Item)`
    margin-bottom: 16px;
  `,
  Image: styled(Image)`
    margin-bottom: 16px;
  `,
  AltInputLabel: styled.div`
    display: flex;
    gap: 4px;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Button: styled(Button)`
    margin-right: 7px;
  `,
};

interface AddImageModalProps {
  onCancel: () => void;
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
  imageEntityKey?: string;
  mode?: 'edit' | 'insert';
}

type ImageFormData = {
  src: string;
  alt: string;
};

export function ImageModal(props: AddImageModalProps) {
  const isModalVisible = props.imageEntityKey !== undefined;

  return (
    <Modal
      open={isModalVisible}
      destroyOnClose
      title={
        <FormattedMessage
          id={
            props.mode === 'insert'
              ? 'rich-text-editor.image-modal.insert-title'
              : 'rich-text-editor.image-modal.edit-title'
          }
        />
      }
      footer={null}
      onCancel={props.onCancel}
    >
      <ModalContent {...(props as AddImageModalContentProps)} />
    </Modal>
  );
}

interface AddImageModalContentProps extends AddImageModalProps {
  imageEntityKey: string;
}

function ModalContent({
  editorState,
  imageEntityKey,
  mode,
  onChange,
  onCancel,
}: AddImageModalContentProps) {
  const intl = useIntl();
  const {
    partnersStore: { imageStore },
  } = useContext(RootStoreContext);
  const currentContentState = editorState.getCurrentContent();
  const entity = currentContentState.getEntity(imageEntityKey);
  // newly created image has no correspondent entity yet
  const entityData = entity ? entity.getData() : {};

  const initialValues = { src: entityData.src || '', alt: entityData.alt || '' };
  const validationSchema = Yup.object().shape({
    alt: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'rich-text-editor.image-modal.alt.required-error',
        })
      ),
    src: Yup.string().required(
      intl.formatMessage({
        id: 'rich-text-editor.image-modal.src.required-error',
      })
    ),
  });

  const handleDelete = () => {
    const entityPlacementData = getEntityPlacementDataByKey(imageEntityKey, editorState);

    if (!entityPlacementData) {
      // just exit, as there is no correspondent block with entity in editor state yet (i.e. entity was newly created)
      onCancel();
    } else {
      const newSelection = SelectionState.createEmpty(entityPlacementData.blockKey);
      const updatedSelection = newSelection.merge({
        anchorKey: entityPlacementData.blockKey,
        focusKey: entityPlacementData.blockKey,
        anchorOffset: entityPlacementData.anchor,
        focusOffset: entityPlacementData.focus,
      });
      const currentContentStateWithoutEntity = Modifier.applyEntity(
        currentContentState,
        updatedSelection,
        null
      );
      const newContentState = Modifier.replaceText(
        currentContentStateWithoutEntity,
        updatedSelection,
        ''
      );
      const newEditorState = EditorState.set(editorState, { currentContent: newContentState });
      onChange(newEditorState);
    }
  };

  const handleSubmit = ({ src, alt }: ImageFormData) => {
    const entityPlacementData = getEntityPlacementDataByKey(imageEntityKey, editorState);

    // We're updating existing content
    if (entityPlacementData) {
      // Generally, it should work with something like:
      // const newContentState = currentContentState.replaceEntityData(imageEntityKey, { src, alt });
      // ...
      // Unfortunately, it does not refresh the editor image content immediately, only after text focus
      // is placed next to the image.
      // Details: https://github.com/facebookarchive/draft-js/issues/1702
      // Because of that, we take the hard way: first remove entity and its corresponding text, then add
      // it again with modified params.
      const newSelection = SelectionState.createEmpty(entityPlacementData.blockKey);
      const updatedSelection = newSelection.merge({
        anchorKey: entityPlacementData.blockKey,
        focusKey: entityPlacementData.blockKey,
        anchorOffset: entityPlacementData.anchor,
        focusOffset: entityPlacementData.focus,
      });
      const currentContentStateWithoutEntity = Modifier.applyEntity(
        currentContentState,
        updatedSelection,
        null
      );
      const currentContentStateWithoutText = Modifier.replaceText(
        currentContentStateWithoutEntity,
        updatedSelection,
        ''
      );
      const newContentStateWithNewEntity = currentContentStateWithoutText.createEntity(
        'IMAGE',
        'MUTABLE',
        {
          src,
          alt,
        }
      );
      const entityKey = newContentStateWithNewEntity.getLastCreatedEntityKey();
      const newContentStateWithNewText = Modifier.insertText(
        newContentStateWithNewEntity,
        newSelection,
        '📷',
        undefined,
        entityKey
      );
      const newEditorState = EditorState.set(editorState, {
        currentContent: newContentStateWithNewText,
      });
      onChange(newEditorState);
      // We're creating new content
    } else {
      const currentSelection = editorState.getSelection();
      const currentContentStateWithEntity = currentContentState.createEntity('IMAGE', 'MUTABLE', {
        src,
        alt,
      });
      const entityKey = currentContentStateWithEntity.getLastCreatedEntityKey();
      const newContentState = Modifier.insertText(
        currentContentStateWithEntity,
        currentSelection,
        '📷',
        undefined,
        entityKey
      );
      const newEditorState = EditorState.set(editorState, { currentContent: newContentState });

      onChange(newEditorState);
    }
  };

  useEffect(() => {
    imageStore.fetchImages();
  }, [imageStore]);

  return (
    <Observer>
      {() => {
        const imageOptions = imageStore.images.map(image => {
          const src = getImageIdFromFileName(image.fileName);
          return {
            value: src,
            label: image.metadata.title[intl.locale] || image.fileName,
          };
        });

        return (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isValid, dirty, values, submitForm }) => (
              <Form layout="vertical">
                <Styled.FormItem
                  label={<FormattedMessage id="images.image" />}
                  name="src"
                  required
                  style={{ display: mode === 'insert' ? 'block' : 'none' }}
                >
                  <Select name="src" showSearch optionFilterProp="label" options={imageOptions} />
                </Styled.FormItem>

                {values.src && <Styled.Image src={values.src} alt={values.alt} />}

                <Styled.FormItem
                  label={
                    <Styled.AltInputLabel>
                      <div>
                        <FormattedMessage id="images.alt-text" />
                      </div>
                      <PopoverTooltip>
                        <FormattedMessage id="rich-text-editor.image-modal.alt.info" />
                      </PopoverTooltip>
                    </Styled.AltInputLabel>
                  }
                  name="alt"
                  required
                >
                  <Input name="alt" />
                </Styled.FormItem>

                <Styled.ButtonsContainer>
                  <div>
                    {mode === 'edit' && (
                      <Popconfirm
                        title={
                          <div style={{ maxWidth: 240 }}>
                            <FormattedMessage id="rich-text-editor.image-modal.sure-to-remove" />
                          </div>
                        }
                        okText={
                          <FormattedMessage id="rich-text-editor.image-modal.confirm-remove" />
                        }
                        cancelText={
                          <FormattedMessage id="rich-text-editor.image-modal.cancel-remove" />
                        }
                        onConfirm={handleDelete}
                      >
                        <Button icon={<DeleteOutlined />}>
                          <FormattedMessage id="rich-text-editor.image-modal.remove" />
                        </Button>
                      </Popconfirm>
                    )}
                  </div>

                  <div>
                    {dirty ? (
                      <Popconfirm
                        title={<FormattedMessage id="general.sure-to-cancel" />}
                        cancelText={<FormattedMessage id="general.cancel" />}
                        onConfirm={onCancel}
                      >
                        <Styled.Button>
                          <FormattedMessage id="general.cancel" />
                        </Styled.Button>
                      </Popconfirm>
                    ) : (
                      <Styled.Button onClick={onCancel}>
                        <FormattedMessage id="general.cancel" />
                      </Styled.Button>
                    )}
                    <Button
                      type="primary"
                      disabled={!isValid}
                      onClick={submitForm}
                      icon={mode === 'insert' ? <PlusOutlined /> : <SaveOutlined />}
                    >
                      <FormattedMessage
                        id={
                          mode === 'insert' ? 'rich-text-editor.image-modal.insert' : 'general.save'
                        }
                      />
                    </Button>
                  </div>
                </Styled.ButtonsContainer>
              </Form>
            )}
          </Formik>
        );
      }}
    </Observer>
  );
}
