import React, { useContext, useEffect } from 'react';
import { useParams, Switch, Route, RouteComponentProps } from 'react-router-dom';

import { eventTrackingService } from 'config/appConfig';
import RootStoreContext from 'context/RootStoreContext';

import ConditionDetails from './ConditionDetails';
import ConditionVisualization from '../ConditionVisualization';

type ConditionContentProps = RouteComponentProps<{ id: string }>;

export const ConditionContent = ({ match }: ConditionContentProps) => {
  const params = useParams<{ id: string }>();
  const { conditionStore, conditionVisualizationStore } = useContext(RootStoreContext);
  useEffect(() => {
    if (!params.id) {
      return;
    }
    // Condition data from conditionStore is needed in both views, ConditionDetails and ConditionVisualization
    conditionStore.initialize(params.id);
    eventTrackingService.sendEvent('conditionOpened', { conditionId: params.id });

    return () => {
      conditionStore.clearCondition();
      conditionVisualizationStore.clearVisualization();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path={match.path} exact component={ConditionDetails} />
      <Route path={`${match.path}/visualization`} component={ConditionVisualization} />
    </Switch>
  );
};
