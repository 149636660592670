import { useAtom, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import React, { useCallback } from 'react';

const isNavigationCollapsedAtom = atomWithStorage('isNavigationCollapsed', false);

export const useCollapseNavigation = () => {
  const setIsNavigationCollapsed = useSetAtom(isNavigationCollapsedAtom);
  return useCallback(() => setIsNavigationCollapsed(true), [setIsNavigationCollapsed]);
};

export const useToggleCollapseNavigation = () => {
  const setIsNavigationCollapsed = useSetAtom(isNavigationCollapsedAtom);
  return useCallback(() => setIsNavigationCollapsed(prev => !prev), [setIsNavigationCollapsed]);
};

export const useNavigationCollapsedState = () => {
  const [isCollapsed, setCollapsed] = useAtom(isNavigationCollapsedAtom);

  const toggleCollapse = useCallback(() => setCollapsed(prev => !prev), [setCollapsed]);

  return { isCollapsed, toggleCollapse, setCollapsed };
};

export const withNavigationCollapseControl = <
  P extends {
    collapseNavigation: () => void;
    toggleCollapseNavigation: () => void;
  }
>(
  Component: React.ComponentType<P>
) => {
  const WrappedComponent = (props: Omit<P, 'collapseNavigation' | 'toggleCollapseNavigation'>) => {
    const collapseNavigation = useCollapseNavigation();
    const toggleCollapseNavigation = useToggleCollapseNavigation();

    return (
      // @ts-ignore - TS is afraid about props being a different subset of P, but it doesn't matter
      // as long as the required props are present, the component will work
      <Component
        {...props}
        collapseNavigation={collapseNavigation}
        toggleCollapseNavigation={toggleCollapseNavigation}
      />
    );
  };
  WrappedComponent.displayName = Component.displayName;

  return WrappedComponent;
};

export const withNavigationCollapsedState = <
  P extends {
    isCollapsed: boolean;
    toggleCollapse: () => void;
    setCollapsed: (collapsed: boolean) => void;
  }
>(
  Component: React.ComponentType<P>
) => {
  const WrappedComponent = (props: Omit<P, 'isCollapsed' | 'toggleCollapse' | 'setCollapsed'>) => {
    const { isCollapsed, toggleCollapse, setCollapsed } = useNavigationCollapsedState();

    return (
      // @ts-ignore - TS is afraid about props being a different subset of P, but it doesn't matter
      // as long as the required props are present, the component will work
      <Component
        {...props}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        setCollapsed={setCollapsed}
      />
    );
  };
  WrappedComponent.displayName = Component.displayName;

  return WrappedComponent;
};
