import {
  DownOutlined,
  UpOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons';
import { ProvidedZoom } from '@vx/zoom/lib/types';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';

import styles from './DiagramNavigation.module.css';
import { NodeDependency } from '../../stores/ConditionVisualizationStore';
import { NodeType } from '../../stores/DiagramStore';
import { parseExitDependencies } from '../../utils';

/**
 * @notExported
 */
interface DiagramNavigationProps {
  zoom: ProvidedZoom;
  goToTheBottom: () => void;
  goToTheTop: () => void;
  nodes: NodeType[];
  selectedNode: NodeType | null;
  margin: number;
}

const DiagramNavigation: FunctionComponent<DiagramNavigationProps> = ({
  zoom,
  goToTheBottom,
  goToTheTop,
  selectedNode,
  margin,
}) => {
  const { conditionVisualizationStore } = useContext(RootStoreContext);

  const { focusedExit, setFocusedElementId, focusedElementId, nodeNavigation } =
    conditionVisualizationStore;

  const allDependencies = focusedExit?.dependencies?.slice() || [];
  const index = allDependencies?.findIndex(e => e.questionId === focusedElementId);
  const nodeDependencies: NodeDependency[] = parseExitDependencies(
    selectedNode?.data?.dependencies
  );
  const nodeIndex = nodeDependencies?.findIndex(e => e.questionId === focusedElementId);
  const toolTipDelay = 0.8;

  const goToNextExitDependency = () => {
    if (!allDependencies || index === undefined) return;
    const nextId = index + 1 > allDependencies.length - 1 ? 0 : index + 1;
    setFocusedElementId(allDependencies[nextId].questionId);
  };

  const goToNextNodeDependency = () => {
    if (nodeDependencies?.length > 0) {
      const nextIndex =
        nodeIndex < 0 ? 0 : nodeIndex + 1 > nodeDependencies.length - 1 ? 0 : nodeIndex + 1;
      conditionVisualizationStore.setFocusedElementId(nodeDependencies[nextIndex].questionId);
    } else return;
  };

  const goToPreviousExitDependency = () => {
    const index = allDependencies?.findIndex(e => e.questionId === focusedElementId);
    if (!allDependencies || index === undefined) return;
    const nextId = index === 0 ? allDependencies.length - 1 : index - 1;
    setFocusedElementId(allDependencies[nextId].questionId);
  };

  const goToPreviousNodeDependency = () => {
    const nodeDependencies: NodeDependency[] = parseExitDependencies(
      selectedNode?.data?.dependencies
    );
    if (nodeDependencies?.length > 1) {
      const nextIndex =
        nodeIndex < 0 ? 0 : nodeIndex === 0 ? nodeDependencies.length - 1 : nodeIndex - 1;
      conditionVisualizationStore.setFocusedElementId(nodeDependencies[nextIndex]?.questionId);
    } else return;
  };

  const handleNextDependencyClick = () => {
    nodeNavigation ? goToNextNodeDependency() : goToNextExitDependency();
  };

  const handlePreviousDependencyClick = () => {
    nodeNavigation ? goToPreviousNodeDependency() : goToPreviousExitDependency();
  };

  const shouldShowNavButtons = () => {
    if (nodeNavigation) {
      return nodeDependencies?.length > 0 && nodeIndex !== undefined;
    } else {
      if (!focusedElementId) return false;
      return allDependencies.length > 1 && index !== undefined;
    }
  };

  const getText = () => {
    if (nodeNavigation) {
      if (nodeDependencies?.length === 0) return '';
      return (
        <div className={styles.navBox}>
          <div>
            <FormattedMessage id="condition.visualization.dependencies" />:{' '}
            {nodeIndex !== undefined ? nodeIndex + 1 : ''}/{nodeDependencies?.length}
          </div>
          <div className={styles.navBoxName}>{selectedNode?.data.questionId}</div>
        </div>
      );
    } else {
      if (!focusedElementId || allDependencies?.length === 0) return '';
      return (
        <div className={styles.navBox}>
          <div>
            <FormattedMessage id="condition.visualization.dependencies" />:{' '}
            {index !== undefined ? index + 1 : ''}/{allDependencies?.length}
          </div>
          <div className={styles.navBoxName}>{focusedElementId}</div>
        </div>
      );
    }
  };

  return (
    <Observer>
      {() => (
        <>
          <div
            className={styles.navigation}
            style={{
              marginLeft: `${margin}px`,
            }}
          >
            <Tooltip
              mouseEnterDelay={toolTipDelay}
              placement="bottom"
              title={<FormattedMessage id="condition.visualization.go-to-the-top" />}
            >
              <Button size="small" className={styles.btnNav} onClick={goToTheTop}>
                <VerticalAlignTopOutlined />
              </Button>
            </Tooltip>
            <Tooltip
              mouseEnterDelay={toolTipDelay}
              placement="bottom"
              title={<FormattedMessage id="condition.visualization.go-to-the-bottom" />}
            >
              <Button size="small" className={styles.btnNav} onClick={goToTheBottom}>
                <VerticalAlignBottomOutlined />
              </Button>
            </Tooltip>
            {shouldShowNavButtons() && (
              <>
                <Tooltip
                  mouseEnterDelay={toolTipDelay}
                  placement="bottom"
                  title={<FormattedMessage id="condition.visualization.go-to-next-dependency" />}
                >
                  <Button
                    size="small"
                    className={styles.btnNav}
                    onClick={handleNextDependencyClick}
                  >
                    <DownOutlined />
                  </Button>
                </Tooltip>
                <Tooltip
                  mouseEnterDelay={toolTipDelay}
                  placement="bottom"
                  title={
                    <FormattedMessage id="condition.visualization.go-to-previous-dependency" />
                  }
                >
                  <Button
                    size="small"
                    className={styles.btnNav}
                    onClick={handlePreviousDependencyClick}
                  >
                    <UpOutlined />
                  </Button>
                </Tooltip>
              </>
            )}
            {getText()}
          </div>

          <div className={styles.controls}>
            <Button
              size="small"
              className={styles.btnZoom}
              onClick={() => zoom.scale({ scaleX: 1.2, scaleY: 1.2 })}
            >
              +
            </Button>
            <Button
              size="small"
              className={classNames(styles.btnZoom, styles.btnBottom)}
              onClick={() => zoom.scale({ scaleX: 0.8, scaleY: 0.8 })}
            >
              -
            </Button>
            <Button size="small" onClick={zoom.reset}>
              <FormattedMessage id="general.reset" />
            </Button>
          </div>
        </>
      )}
    </Observer>
  );
};

export default DiagramNavigation;
