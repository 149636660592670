import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Table, Button, Typography, Popconfirm } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import PlusFloatingButton from 'components/PlusFloatingButton';
import { ORIGIN_RULES_CATEGORIES } from 'constants/origins';
import RootStoreContext from 'context/RootStoreContext';

import { OriginRuleCondition, OriginRuleOutcome, OriginRule } from './api/rulesApi';
import EditRule from './components/EditRule';
import styles from './Rules.module.css';

type Props = RouteComponentProps<{ ruleName: ORIGIN_RULES_CATEGORIES }>;

@observer
class Rules extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  columns = [
    {
      title: <FormattedMessage id="origin.rules" />,
      dataIndex: 'description',
    },
    {
      title: <FormattedMessage id="origin.rules.condition" />,
      dataIndex: 'conditional',
      render: (conditions: OriginRuleCondition[]) =>
        !!conditions.length && (
          <Fragment>{`//${conditions.map(({ field }) => field).join(' + ')}//`}</Fragment>
        ),
    },
    {
      title: <FormattedMessage id="origin.rules.outcome" />,
      dataIndex: 'outcome',
      render: (outcomes: OriginRuleOutcome[]) =>
        !!outcomes.length && (
          <Fragment>{`//${outcomes.map(({ field }) => field).join(' + ')}//`}</Fragment>
        ),
    },
    {
      title: <FormattedMessage id="general.actions" />,
      width: 100,
      render: (rule: OriginRule) => {
        const {
          originStore: { rulesStore },
          userPermissionsStore,
        } = this.context;
        return (
          <Fragment>
            <Button
              type="link"
              icon={userPermissionsStore.isSuperAdmin ? <EditOutlined /> : <EyeOutlined />}
              onClick={() => {
                rulesStore.handleEdit(rule);
              }}
            />
            <Popconfirm
              title={<FormattedMessage id="general.sure-to-delete" />}
              cancelText={<FormattedMessage id="general.cancel" />}
              onConfirm={() => rulesStore.handleDelete(rule)}
              disabled={!userPermissionsStore.isSuperAdmin}
            >
              <Button
                type="link"
                icon={<DeleteOutlined />}
                disabled={!userPermissionsStore.isSuperAdmin}
              />
            </Popconfirm>
          </Fragment>
        );
      },
    },
  ];

  render() {
    const {
      match: {
        params: { ruleName },
      },
    } = this.props;
    const {
      originStore: {
        rulesStore: { isLoading, rules, activeRule, handleCancel, handleUpdate, handleAdd },
      },
      userPermissionsStore: { isSuperAdmin },
    } = this.context;
    const rulesSource = toJS(rules.get(ruleName));

    return (
      <Fragment>
        <Typography.Title level={3}>
          <FormattedMessage id="origin.rules" />
        </Typography.Title>
        <Table
          columns={this.columns}
          loading={isLoading}
          dataSource={rulesSource}
          rowKey="id"
          pagination={false}
          className={styles.table}
        />
        <EditRule
          initialValues={activeRule}
          isSaving={isLoading}
          isDisabled={!isSuperAdmin}
          onCancel={handleCancel}
          onSubmit={handleUpdate}
        />
        {isSuperAdmin && <PlusFloatingButton onClick={handleAdd} />}
      </Fragment>
    );
  }
}

export default Rules;
