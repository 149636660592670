import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Logo } from 'assets/svg/manage24.svg';
import { logError } from 'modules/Analytics/utils';

import styles from './ErrorBoundary.module.css';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<React.PropsWithChildren<void>, ErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown) {
    logError(error, { type: 'error-boundary' });
  }

  reloadAndGoHome = () => {
    window.location.href = '/';
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <div className={styles.header}>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.info}>
            <h1>
              <FormattedMessage id="fallback.oops" />
            </h1>
            <h4>
              <FormattedMessage id="fallback.header" />
            </h4>
            <h4>
              <FormattedMessage id="fallback.sorry" />
            </h4>
            <h5>
              <FormattedMessage id="fallback.report.problem" />
            </h5>
            <div>
              <Button className={styles.button} onClick={this.reloadAndGoHome}>
                <FormattedMessage id="fallback.home.button" />
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
