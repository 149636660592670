import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { createAxiosInstance } from 'config/axiosInstance';
import { getLanguage } from 'context/lang.utils';
import RootStore from 'stores/RootStore';

import { partnersAtoms } from '../../state/partners';

export const usePrivateFederationBaseProps = (rootStore: RootStore) => {
  const history = useHistory();

  const availableLanguages = useAtomValue(partnersAtoms.availableLanguages);
  const defaultLanguage = useAtomValue(partnersAtoms.defaultLanguage);

  return useMemo(
    () => ({
      userId: rootStore.userDataStore.id,
      partnerId: rootStore.partnersStore.partnerId,
      languageCode: getLanguage(),
      createAxiosInstance,
      history: history,
      languages: {
        default: defaultLanguage,
        list: availableLanguages,
      },
    }),
    [
      availableLanguages,
      defaultLanguage,
      history,
      rootStore.partnersStore.partnerId,
      rootStore.userDataStore.id,
    ]
  );
};
