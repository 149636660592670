import { Col, Divider, Row } from 'antd';
import { Formik } from 'formik';
import { Input, Form, Checkbox, Select, Switch } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { LANGS } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';
import { HEALTH_DATA_CODE_TYPE } from 'modules/Content24/Condition/api/partnerCode24api';
import { CODE24_MODEL_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { ExportData } from 'modules/Content24/Condition/models/Code24Model';
import {
  getDataFromFinalFormValues,
  getInitialFormValues,
} from 'modules/Content24/Condition/utils/forms';
import {
  validateStatementConditionWithDebounce,
  validateStatementVariableWithDebounce,
  ExpressionError,
} from 'modules/Content24/Condition/utils/validationUtils';
import { sortWithLocale } from 'utils/textUtils';

/**
 * @notExported
 */
interface ExportDataFormProps {
  onCancel: () => void;
  onSubmit: (data: ExportData) => void;
  activeLanguage: LANGS;
  data?: ExportData;
  isDisabled?: boolean;
}

const ExportDataForm: FunctionComponent<ExportDataFormProps> = ({
  data,
  activeLanguage,
  onCancel,
  onSubmit,
  isDisabled,
}) => {
  const { conditionStore, content24Store } = useContext(RootStoreContext);
  const defaultValues: ExportData = {
    id: '',
    type: CODE24_MODEL_TYPES.EXPORT_DATA,
    condition: '',
    destination: 'healthdata',
    destinationId: '',
    variable: '',
    required: false,
  };
  const initialValues = getInitialFormValues<ExportData, ExportData>(defaultValues, data);

  const intl = useIntl();
  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });

  const validationSchema = Yup.object().shape({
    variable: Yup.string()
      .test(
        'isValidVariable',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementVariableWithDebounce
      )
      .required(requiredErrorMessage),
    destination: Yup.string().required(requiredErrorMessage),
    destinationId: Yup.string().required(requiredErrorMessage),
    condition: Yup.string().test(
      'isValidCondition',
      ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
        translationKey && characters && intl.formatMessage({ id: translationKey }, { characters }),
      validateStatementConditionWithDebounce
    ),
  });

  const handleSubmit = useCallback(
    (dataToSubmit: ExportData) => {
      onSubmit(getDataFromFinalFormValues<ExportData>(dataToSubmit));
    },
    [onSubmit]
  );

  useEffect(() => {
    content24Store.fetchHealthDataCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer>
      {() => {
        const isLoadingHealthDataCodes = content24Store.isLoadingHealthDataCodes;
        const healthDataCodesOptions = content24Store.healthDataCodes
          .map(({ id, description }) => ({
            value: id,
            label: description[activeLanguage] || id,
          }))
          .sort((a, b) => sortWithLocale(a, b, 'label'));
        const healthDataCodeTypeMap = content24Store.healthDataCodeTypeMap;
        const isSaving = conditionStore.isLoading();
        const isFormDisabled = conditionStore.isLoading() || isDisabled;

        return (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, values, setFieldValue }) => {
              const healthDataCodeType = healthDataCodeTypeMap.get(values.destinationId);

              return (
                <Form layout="vertical">
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name="condition"
                        label={<FormattedMessage id="condition-edit.condition-label" />}
                      >
                        <Input.TextArea
                          name="condition"
                          disabled={isFormDisabled}
                          rows={1}
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="variable"
                        required
                        label={
                          <FormattedMessage
                            id={
                              healthDataCodeType
                                ? `condition-edit.health-data-code.${healthDataCodeType}-label`
                                : 'condition-edit.variable-label'
                            }
                          />
                        }
                      >
                        {healthDataCodeType === HEALTH_DATA_CODE_TYPE.BOOLEAN ? (
                          <Switch
                            name="variable"
                            checked={values.variable === 'true'}
                            onChange={value => setFieldValue('variable', value ? 'true' : 'false')}
                          />
                        ) : (
                          <Input name="variable" disabled={isFormDisabled} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="destination"
                        required
                        label={<FormattedMessage id="condition-edit.destination-label" />}
                      >
                        <Input name="destination" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="destinationId"
                        required
                        label={<FormattedMessage id="condition-edit.destination-id-label" />}
                      >
                        <Select
                          name="destinationId"
                          disabled={isFormDisabled}
                          loading={isLoadingHealthDataCodes}
                          options={healthDataCodesOptions}
                          onSelect={() => setFieldValue('variable', undefined)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="required"
                        label={<FormattedMessage id="condition-edit.mandatory-label" />}
                      >
                        <Checkbox name="required" disabled={isFormDisabled} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <FormActionButtons
                    isSaving={isSaving}
                    isDisabled={isDisabled}
                    isValid={isValid && dirty}
                    onCancel={onCancel}
                    showCancelConfirm={dirty}
                    cancelDeclineText={
                      <FormattedMessage id="condition-edit.statement-cancel-confirm" />
                    }
                  />
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Observer>
  );
};

export default ExportDataForm;
