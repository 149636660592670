import { parse } from 'query-string';
import { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';

export const useLoginRedirects = () => {
  const { authStore, flashMessageService, partnersStore } = useContext(RootStoreContext);
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  useEffect(() => {
    if (authStore.isAuthenticated()) {
      if (!partnersStore.partnerId) {
        location.pathname !== '/login/select-partner' && history.push('/login/select-partner');
      } else {
        history.push('/');
      }
    }

    if (location.search) {
      const query = parse(location.search);

      if (query.messageKey) {
        flashMessageService.error(intl.formatMessage({ id: query.messageKey }));
      }
    }
  });
};
