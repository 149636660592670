import { Modal, Typography, List, Divider, Descriptions } from 'antd';
import format from 'date-fns/format';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { MergeRequest } from 'modules/PartnerStatus/api/partnerStatusApi';
import { markdownToInlineHtml } from 'utils/textUtils';

interface Props {
  data: MergeRequest | null;
  onCancel: () => void;
}

const MergeRequestDetailsModal: FunctionComponent<Props> = ({ data, onCancel }) => {
  if (data === null) {
    return null;
  }

  return (
    <Modal
      open
      destroyOnClose
      title={<FormattedMessage id="partner-status.merge-request-details-modal-title" />}
      footer={null}
      onCancel={onCancel}
    >
      <Descriptions column={1}>
        <Descriptions.Item label={<FormattedMessage id="partner-status.merge-request-id" />}>
          {data.id}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="partner-status.merge-request-date" />}>
          {format(new Date(data.when), 'MMMM d, yyyy hh:mm a')}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="partner-status.merge-request-status" />}>
          {data.status}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <div>
        <Typography.Title level={4}>
          <FormattedMessage id="general.description" />
        </Typography.Title>
        <p>{data.description || ''}</p>
      </div>
      <Divider />
      <div>
        <Typography.Title level={4}>
          <FormattedMessage id="partner-status.changes" />
        </Typography.Title>
        <List
          dataSource={data.changes}
          renderItem={change => (
            <List.Item>
              <List.Item.Meta
                title={
                  <div dangerouslySetInnerHTML={{ __html: markdownToInlineHtml(change.message) }} />
                }
                description={`${format(new Date(change.when), 'MMMM d, yyyy hh:mm a')} | ${
                  change.author
                }`}
              />
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default MergeRequestDetailsModal;
