import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';

import { useLoginErrorCode } from './useLoginErrorCode';

const SSOAuthCollect = () => {
  const history = useHistory();
  const { authStore } = useContext(RootStoreContext);
  useLoginErrorCode();

  useEffect(() => {
    const handleCollect = async () => {
      try {
        await authStore.collectSSOLogin();
        /* eslint-disable no-empty */
      } catch {
      } finally {
        history.push('/login/select-partner');
      }
    };
    handleCollect();
    // eslint-disable-next-line
  }, []);
  return <Spin size="large" indicator={<LoadingOutlined spin />} />;
};

export default SSOAuthCollect;
