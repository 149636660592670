import { CloseOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Popconfirm, PopconfirmProps, Space, Steps, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { useRootStore } from 'context/RootStoreContext';

import { CreateAnnouncementForm } from './Form';
import { AnnouncementType } from './types';
import { useCreateAnnouncementForm } from './useCreateAnnouncementForm';
import { useCreateAnnouncement } from '../hooks';
import { CreateAnnouncementDTO } from '../types';

const ContentWrapper = styled.div`
  margin: 24px 0 48px;
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled(Button).attrs({ icon: <CloseOutlined />, type: 'text' })`
  position: absolute;
  right: 12px;
  top: 12px;
`;

interface Props {
  careProviderId: string;
  careUnitId?: string;
  handleCloseModal: () => void;
  open: boolean;
  type: AnnouncementType;
}

export const CreateAnnouncementModal = ({
  careProviderId,
  careUnitId,
  handleCloseModal,
  open,
  type,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const formik = useCreateAnnouncementForm();

  const handleClose = useCallback(() => {
    formik.resetForm();
    setCurrentStep(0);
    handleCloseModal();
    // ignore formik dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseModal]);

  const items = useMemo(
    () => [
      {
        title: <FormattedMessage id="Fill out content" />,
        content: <CreateAnnouncementForm formik={formik} />,
      },
      {
        title: <FormattedMessage id="Preview and activate" />,
        content: (
          <>
            <Typography.Paragraph>
              <FormattedMessage id="Preview" />:
            </Typography.Paragraph>
            <Alert description={formik.values.text} message={formik.values.title} type="info" />
          </>
        ),
      },
    ],
    [formik]
  );

  const { userDataStore } = useRootStore();
  const { mutate: createNewAnnouncementMutation } = useCreateAnnouncement();
  const onOk = useCallback(() => {
    if (currentStep === 0) {
      setCurrentStep(1);
    } else if (formik.isValid) {
      const createAnnouncementDTO: CreateAnnouncementDTO = {
        ...formik.values,
        authorFullName: `${userDataStore.givenName} ${userDataStore.middleAndSurname}`,
        careProviderId,
        careUnitId,
      };

      createNewAnnouncementMutation(createAnnouncementDTO);
      handleClose();
    }
  }, [
    currentStep,
    formik.isValid,
    formik.values,
    userDataStore.givenName,
    userDataStore.middleAndSurname,
    careProviderId,
    careUnitId,
    createNewAnnouncementMutation,
    handleClose,
  ]);

  const onGoBack = useCallback(() => {
    if (currentStep !== 0) {
      setCurrentStep(0);
    }
  }, [currentStep]);

  const popconfirmProps: PopconfirmProps = useMemo(
    () => ({
      cancelText: <FormattedMessage id="Keep editing" />,
      description: <FormattedMessage id="All changes you made will be lost." />,
      disabled: !formik.dirty,
      okButtonProps: { danger: true, style: { background: '#dc2e2e' }, type: 'primary' },
      okText: <FormattedMessage id="Yes, close" />,
      onConfirm: handleClose,
      title: <FormattedMessage id="Close without saving?" />,
    }),
    [formik.dirty, handleClose]
  );

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ControlsWrapper>
          <Button disabled={currentStep === 0} onClick={onGoBack} style={{ marginRight: 'auto' }}>
            <FormattedMessage id="Go back and edit" />
          </Button>

          <Space align="end">
            <Popconfirm {...popconfirmProps}>
              <Button onClick={!formik.dirty ? handleClose : undefined}>
                <FormattedMessage id="general.cancel" />
              </Button>
            </Popconfirm>
            <Button
              disabled={currentStep === 0 && (!formik.isValid || !formik.dirty)}
              type="primary"
              onClick={onOk}
            >
              {currentStep === 0 ? (
                <FormattedMessage id="Next" />
              ) : (
                <FormattedMessage id="Save and activate" />
              )}
            </Button>
          </Space>
        </ControlsWrapper>
      }
      maskClosable={false}
      onCancel={handleClose}
      open={open}
      title={
        type === AnnouncementType.CareProvider ? (
          <FormattedMessage id="Create announcement for care provider" />
        ) : (
          <FormattedMessage id="Create announcement for care unit" />
        )
      }
    >
      <Popconfirm {...popconfirmProps}>
        <CloseButton onClick={!formik.dirty ? handleClose : undefined} />
      </Popconfirm>
      <Steps current={currentStep} items={items} />
      <ContentWrapper>{items[currentStep].content}</ContentWrapper>
    </Modal>
  );
};
