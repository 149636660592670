import { observable, action, IObservableArray, runInAction } from 'mobx';

import RootStore from 'stores/RootStore';

import {
  MergeRequest,
  fetchMergeRequests,
  UpdateMergeRequestDTO,
  createMergeRequest,
  fetchMergeRequestById,
  cancelMergeRequest,
  updateMergeRequest,
} from '../api/partnerStatusApi';

export default class MergeRequestStore {
  logHistory: IObservableArray<MergeRequest> = observable([]);
  @observable
  currentMergeRequest: MergeRequest | null = null;
  @observable
  isLoading = false;

  constructor(private rootStore: RootStore) {}

  @action
  fetchMergeRequestsHistory = async () => {
    try {
      // set loader only if there's no previously loaded data
      if (!this.logHistory.length) {
        this.isLoading = true;
      }

      const { data } = await fetchMergeRequests(this.rootStore.partnersStore.partnerId);

      runInAction(() => {
        this.logHistory.replace(data);
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  fetchMergeRequest = async (id: number) => {
    try {
      this.isLoading = true;

      const { data } = await fetchMergeRequestById(this.rootStore.partnersStore.partnerId, id);

      runInAction(() => {
        this.currentMergeRequest = data;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  createMergeRequest = async (mergeRequestData: UpdateMergeRequestDTO) => {
    try {
      this.isLoading = true;

      const { data } = await createMergeRequest(
        this.rootStore.partnersStore.partnerId,
        mergeRequestData
      );

      runInAction(() => {
        this.currentMergeRequest = data;
      });

      this.rootStore.flashMessageService.translatedSuccess(
        'partner-status.create-merge-request-confirm'
      );
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  updateCurrentMergeRequest = async (mergeRequestData: UpdateMergeRequestDTO) => {
    if (!this.currentMergeRequest) {
      return;
    }

    try {
      this.isLoading = true;

      const { data } = await updateMergeRequest(
        this.rootStore.partnersStore.partnerId,
        this.currentMergeRequest.id,
        mergeRequestData
      );

      runInAction(() => {
        this.currentMergeRequest = data;
      });

      this.rootStore.flashMessageService.translatedSuccess(
        'partner-status.update-merge-request-confirm'
      );
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  cancelCurrentMergeRequest = async () => {
    if (!this.currentMergeRequest) {
      return;
    }

    try {
      this.isLoading = true;

      await cancelMergeRequest(this.rootStore.partnersStore.partnerId, this.currentMergeRequest.id);

      runInAction(() => {
        this.currentMergeRequest = null;
      });

      this.rootStore.flashMessageService.translatedSuccess(
        'partner-status.cancel-merge-request-confirm'
      );
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}
