import { Tabs } from 'antd';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';

import ClinicPersonalRoles from '../ClinicPersonalRoles';
import ManagePersonalRoles from '../ManagePersonalRoles';

const PersonalRoles = () => {
  const { userPermissionsStore, userOriginPermissionListStore } = useContext(RootStoreContext);
  const isAllowedToSeeManageTab = userOriginPermissionListStore.isAllowedToSeeManageRoleTab;
  const tabs = [
    {
      key: '1',
      label: (
        <span data-testid="clinic-tab">
          <FormattedMessage id="roles.clinic24" />
        </span>
      ),
      children: <ClinicPersonalRoles />,
    },
  ];

  if (userPermissionsStore.isAuthorizedToEditPartner || isAllowedToSeeManageTab) {
    tabs.push({
      key: '2',
      label: (
        <span data-testid="manage-tab">
          <FormattedMessage id="roles.manage24" />
        </span>
      ),
      children: <ManagePersonalRoles />,
    });
  }

  return <Tabs data-testid="rolesTabs" items={tabs} />;
};

export default PersonalRoles;
