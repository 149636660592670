import { ContentState } from 'draft-js';
import React from 'react';

import { Image as ImageComponent } from 'components/Image';

interface ImageProps {
  contentState: ContentState;
  entityKey: string;
  blockKey: string;
  children: React.ReactNode;
  decoratedText: '📷';
  dir: null;
  end: number;
  offsetKey: string;
  start: number;
}

export function getImageComponent(onClick: (entityKey: string) => void) {
  return function Image({ contentState, entityKey }: ImageProps) {
    const entity = contentState.getEntity(entityKey);
    const data = entity.getData();
    const src = data.src;
    const alt = data.alt;

    const handleClick = () => {
      onClick(entityKey);
    };

    if (src && alt) {
      return (
        <button onClick={handleClick} type="button">
          <ImageComponent src={src} alt={alt} />
        </button>
      );
    }

    return null;
  };
}
