import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Table, Input, Typography, Tooltip, Button } from 'antd';
import { SortOrder, ColumnsType } from 'antd/lib/table/interface';
import classNames from 'classnames';
import { format } from 'date-fns';
import noop from 'lodash/noop';
import { observable, action, computed, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { parse } from 'query-string';
import React, { Component, Fragment, ContextType, ChangeEvent } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import PlusFloatingButton from 'components/PlusFloatingButton';
import { DEFAULT_DATE_FORMAT, TIME_FORMAT_CLEAN } from 'constants/dateFormat';
import RootStoreContext from 'context/RootStoreContext';
import { capitalizeFirst, sortWithLocale } from 'utils/textUtils';

import styles from './ConditionsList.module.css';
import StatusTag from '../components/StatusTag';
import { NewPartnerCondition } from '../Condition/api/partnerCode24api';
import AddPartnerCondition from '../Condition/components/AddPartnerCondition';
import {
  CODE24_CATEGORIES,
  TEMPLATED_CONDITION_CATEGORIES,
} from '../Condition/constants/code24types';
import { ExtendedCondition } from '../stores/ConditionsListStore';
import { filterConditionsWithCategoryGroup } from '../utils';

/**
 * @notExported
 */
interface ConditionsListProps extends RouteComponentProps, WrappedComponentProps {}

@observer
class ConditionsList extends Component<ConditionsListProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable private searchQuery = '';
  private unlisten = noop;

  @computed
  get columns() {
    const { locale } = this.props.intl;
    const baseColumns: ColumnsType<ExtendedCondition> = [
      {
        title: <FormattedMessage id="general.title" />,
        dataIndex: ['description', locale],
        render: (_: string, condition: ExtendedCondition) => (
          <Link to={`/content24/questionnaires/${condition.conditionId}`}>
            {capitalizeFirst(condition.description[locale] || condition.conditionId)}
          </Link>
        ),
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: ExtendedCondition, b: ExtendedCondition) =>
          sortWithLocale(
            a.description[locale] || a.conditionId,
            b.description[locale] || b.conditionId
          ),
      },
      {
        title: <FormattedMessage id="general.id" />,
        dataIndex: 'conditionId',
      },
      {
        title: <FormattedMessage id="condition-list.condition-group" />,
        dataIndex: ['localizedGroup', 'label', locale],
        width: '30%',
      },
      {
        title: <FormattedMessage id="general.status" />,
        dataIndex: 'status',
        width: 120,
        render: (_: string, record: ExtendedCondition) => {
          if (record.isLocalCopy) {
            return <StatusTag status="localCopy" />;
          }

          return null;
        },
      },
    ];

    if (this.category !== CODE24_CATEGORIES.LIBRARIES) {
      baseColumns.splice(3, 0, {
        title: <FormattedMessage id="general.hidden" />,
        dataIndex: 'isHidden',
        width: 120,
        render: (_: string, record: ExtendedCondition) => {
          return record.isHidden === true ? (
            <span>
              <CheckCircleFilled className={classNames(styles.booleanIcon, styles.trueIcon)} />
              <FormattedMessage id="general.true" />
            </span>
          ) : (
            <span>
              <CloseCircleFilled className={classNames(styles.booleanIcon, styles.falseIcon)} />
              <FormattedMessage id="general.false" />
            </span>
          );
        },
      });
    }

    if (this.isAnyConditionInvalid) {
      baseColumns.unshift({
        width: 30,
        align: 'center',
        render: (condition: ExtendedCondition) =>
          !condition.isValid ? (
            <Tooltip placement="right" title={<FormattedMessage id="condition.validation-error" />}>
              <ExclamationCircleFilled className={styles.validationErrorIcon} />
            </Tooltip>
          ) : null,
      });
    }

    return baseColumns;
  }

  private get breadcrumbs() {
    return [
      {
        iconName: 'pic-left',
        text: <FormattedMessage id="main-navigation.content24" />,
      },
      {
        text: <FormattedMessage id="condition-list.header" />,
      },
      {
        text: this.title,
      },
    ];
  }

  private get title() {
    return this.props.intl.formatMessage({
      id: `condition-list.category-${this.category}`,
    });
  }

  private get category() {
    return parse(this.props.location.search).category as CODE24_CATEGORIES;
  }

  @computed
  private get conditionsFilteredWithCategoryGroup() {
    const { conditionsListStore, content24Store } = this.context;
    const categoryGroups = content24Store.categoryGroups.get(this.category) || [];
    return filterConditionsWithCategoryGroup(conditionsListStore.conditions, categoryGroups);
  }

  @computed
  private get conditionsFilteredWithSearch() {
    const { locale } = this.props.intl;

    if (!this.searchQuery) {
      return this.conditionsFilteredWithCategoryGroup;
    }

    const searchQuery = this.searchQuery.toLowerCase();

    return this.conditionsFilteredWithCategoryGroup.filter(({ description, conditionId }) =>
      (description[locale] || conditionId).toLowerCase().includes(searchQuery)
    );
  }

  @computed
  private get isAnyConditionInvalid() {
    const { conditionsListStore } = this.context;
    const errorsList = conditionsListStore.conditionsValidationStatus.conditionsWithErrors;

    return (
      !!errorsList.length &&
      this.conditionsFilteredWithCategoryGroup.some(({ conditionId }) =>
        errorsList.includes(conditionId)
      )
    );
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      runInAction(() => {
        this.searchQuery = '';
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  @action
  private handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.searchQuery = event.target.value;
  };

  handleCreateCondition = (condition: NewPartnerCondition) => {
    const { handleCreateCondition, handleCreateTemplatedCondition } =
      this.context.conditionsListStore;

    if (TEMPLATED_CONDITION_CATEGORIES.includes(this.category) && !condition.copyOf) {
      return handleCreateTemplatedCondition(condition, this.category);
    }

    return handleCreateCondition(condition);
  };

  render() {
    const { intl } = this.props;
    const { content24Store, conditionsListStore } = this.context;
    const {
      newCondition,
      isLoading,
      conditionsValidationStatus,
      handleAddCondition,
      handleCancelAddCondition,
      handleValidateConditions,
    } = conditionsListStore;

    return (
      <Fragment>
        <PageHeader
          content={
            <div className={styles.headerTop}>
              <Typography.Title level={2} className={styles.title}>
                <span>{this.title}</span>
                {this.isAnyConditionInvalid && (
                  <Tooltip
                    placement="right"
                    title={<FormattedMessage id="condition-list.validation-error" />}
                    className={styles.validationErrorTooltip}
                  >
                    <ExclamationCircleFilled className={styles.validationErrorIcon} />
                  </Tooltip>
                )}
              </Typography.Title>
              <div className={styles.headerActions}>
                {conditionsValidationStatus.validatedAt && (
                  <div className={styles.headerAction}>
                    <FormattedMessage
                      id="condition-list.last-time-validated"
                      values={{
                        lastTimeValidated: format(
                          new Date(conditionsValidationStatus.validatedAt),
                          `${DEFAULT_DATE_FORMAT} ${TIME_FORMAT_CLEAN}`
                        ),
                      }}
                    />
                  </div>
                )}
                {conditionsValidationStatus.isNewValidationInProgress && (
                  <div className={styles.headerAction}>
                    ({<FormattedMessage id="condition-list.validation-in-progress" />})
                  </div>
                )}
                <Button
                  shape="round"
                  type="primary"
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={handleValidateConditions}
                  className={styles.headerAction}
                >
                  <FormattedMessage id="condition-list.validate" />
                </Button>
              </div>
            </div>
          }
          breadcrumbs={this.breadcrumbs}
          headerActions={
            <div className={styles.headerActions}>
              <Input.Search
                placeholder={intl.formatMessage({
                  id: 'condition-list.search-placeholder',
                })}
                data-testid="search-input"
                onChange={this.handleSearchChange}
                value={this.searchQuery}
                disabled={conditionsListStore.isLoading}
                className={styles.searchInput}
              />
            </div>
          }
        />
        <Table
          columns={this.columns}
          loading={isLoading}
          dataSource={this.conditionsFilteredWithSearch.slice()}
          className={styles.table}
          pagination={false}
          rowKey="conditionId"
        />
        <AddPartnerCondition
          initialValues={newCondition}
          isSaving={isLoading}
          onSubmit={this.handleCreateCondition}
          onCancel={handleCancelAddCondition}
          isDisabled={!content24Store.canAddContent24}
        />
        {content24Store.canAddContent24 && (
          <PlusFloatingButton onClick={handleAddCondition} testId="content24-add-condition" />
        )}
      </Fragment>
    );
  }
}

export default injectIntl(ConditionsList);
