import { Divider, Typography, Row, Col } from 'antd';
import { Formik, FieldArray, FormikHelpers, FormikProps } from 'formik';
import { Input, Form, Select, Switch, Checkbox } from 'formik-antd';
import React, { FunctionComponent, useContext, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormActionButtons from 'components/FormActionButtons';
import { LANGS } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';
import {
  QUESTION_TYPES,
  CODE24_MODEL_TYPES,
} from 'modules/Content24/Condition/constants/code24types';
import { Question } from 'modules/Content24/Condition/models/Code24Model';
import {
  getDataFromFinalFormValues,
  getInitialFormValues,
} from 'modules/Content24/Condition/utils/forms';
import { validatePatient4Regex } from 'modules/Content24/Condition/utils/validationUtils';
import { batchAddUUID } from 'utils/uuidUtils';

import NumberOrRangeQuestionType from './components/NumberOrRangeQuestionType';
import QuestionContentList from './components/QuestionContentList';
import YesNoQuestionType from './components/YesNoQuestionType';
import { deleteUnnecessaryProperties } from './utils/deleteUnnecessaryProperties';
import { handleErrors } from './utils/handleErrors';
import { getValidationSchema } from './utils/validationSchema';
/**
 * @notExported
 */
interface QuestionFormProps {
  onCancel: () => void;
  onSubmit: (data: Question) => void;
  activeLanguage: LANGS;
  data?: Question;
  isDisabled?: boolean;
}

export interface QuestionFormData extends Omit<Question, 'points' | 'min' | 'max'> {
  points: number | null;
  min: number | null;
  max: number | null;
}

function transformInitialData(lang: LANGS, data?: Question) {
  const defaultValues: QuestionFormData = {
    id: '',
    questionId: '',
    questionType: QUESTION_TYPES.SINGLE,
    skippable: false,
    category: '',
    buildTimeIf: '',
    condition: '',
    patient: { [lang]: '' },
    staff: { [lang]: '' },
    staffNegative: { [lang]: '' },
    points: null,
    min: null,
    max: null,
    step: 1,
    unit: { [lang]: '' },
    minLabel: { [lang]: '' },
    maxLabel: { [lang]: '' },
    textCondition: '',
    linkTitle: { [lang]: '' },
    linkInformation: { [lang]: '' },
    showNegative: false,
    type: CODE24_MODEL_TYPES.QUESTION,
    content: [],
  };
  const initialData = getInitialFormValues<Question, QuestionFormData>(defaultValues, data);

  if (initialData.content) {
    initialData.content = batchAddUUID(initialData.content);
  }

  // convert patient4 value to patient, if any, as we handle it as one field
  if (initialData.patient4) {
    initialData.patient = initialData.patient4;
  }

  return initialData;
}

const QuestionForm: FunctionComponent<QuestionFormProps> = ({
  data,
  onCancel,
  onSubmit,
  isDisabled,
  activeLanguage,
}) => {
  const intl = useIntl();
  const { conditionStore, content24Store, flashMessageService } = useContext(RootStoreContext);

  const initialValues: QuestionFormData = useMemo(() => {
    return transformInitialData(activeLanguage, data);
  }, [data, activeLanguage]);

  const questionTypeSelectOptions = content24Store.questionTypeSelectOptions;
  const isDisabledQuestionType = !questionTypeSelectOptions.some(
    ({ value }) => value === initialValues.questionType
  );
  const isFormDisabled = conditionStore.isLoading() || isDisabled || isDisabledQuestionType;

  const handleSubmit = useCallback(
    async (formData: QuestionFormData, formikActions: FormikHelpers<QuestionFormData>) => {
      const { hasErrors } = await handleErrors({
        formData,
        formikActions,
        intl,
        flashMessageService,
      });
      if (hasErrors) return;
      const dataToSubmit = getDataFromFinalFormValues<Question>(formData);
      deleteUnnecessaryProperties(dataToSubmit);
      onSubmit(dataToSubmit);
    },
    [flashMessageService, intl, onSubmit]
  );

  const handleQuestionTypeChange = useCallback(
    (value: QUESTION_TYPES, formikProps: FormikProps<QuestionFormData>) => {
      if (value === QUESTION_TYPES.RANGE && !formikProps.values.step) {
        formikProps.setFieldValue('step', '1');
      }
      // Freetext and File questions are skippable by default, so this should be shown in the UI
      // See https://platform24.atlassian.net/browse/AX-22645 for details
      if ([QUESTION_TYPES.FREETEXT, QUESTION_TYPES.FILE].includes(value)) {
        formikProps.setFieldValue('skippable', true);
      }
    },
    []
  );

  const validationSchema = getValidationSchema({ activeLanguage, intl });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const { values, isValid, dirty } = formikProps;
        const isSingleOrMultipleQuestionType = [
          QUESTION_TYPES.MULTIPLE,
          QUESTION_TYPES.SINGLE,
        ].includes(values.questionType);
        const isFreetextOrFileQuestionType = [
          QUESTION_TYPES.FREETEXT,
          QUESTION_TYPES.FILE,
        ].includes(values.questionType);
        const isNumberQuestionType = values.questionType === QUESTION_TYPES.NUMBER;
        const isRangeQuestionType = values.questionType === QUESTION_TYPES.RANGE;
        const isYesNoQuestionType = values.questionType === QUESTION_TYPES.YESNO;
        const isTextConditionRequired =
          values.patient && validatePatient4Regex(values.patient[activeLanguage]);
        const questionCategoriesSelectOptions =
          content24Store.getFilteredQuestionCategoriesSelectOptions(
            values.questionType,
            values.category
          );

        return (
          <Fragment>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={4}>
                  <Form.Item
                    name="questionId"
                    required
                    hasFeedback
                    label={<FormattedMessage id="condition-edit.id-label" />}
                  >
                    <Input
                      name="questionId"
                      disabled={!!initialValues.questionId || isFormDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="questionType"
                    required
                    hasFeedback
                    label={<FormattedMessage id="condition-edit.question-type-label" />}
                  >
                    <Select
                      name="questionType"
                      disabled={isFormDisabled}
                      showSearch
                      optionFilterProp="label"
                      onSelect={(value: QUESTION_TYPES) =>
                        handleQuestionTypeChange(value, formikProps)
                      }
                      options={questionTypeSelectOptions.map(({ value, label }) => ({
                        value,
                        label: intl.formatMessage({ id: label }),
                      }))}
                    />
                  </Form.Item>
                </Col>
                {isFreetextOrFileQuestionType && (
                  <Col span={4}>
                    <Form.Item
                      name="skippable"
                      required
                      labelCol={{ span: 24 }}
                      label={<FormattedMessage id="condition-edit.question-skippable-label" />}
                    >
                      <Checkbox
                        name="skippable"
                        disabled={isFormDisabled || isDisabledQuestionType}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={4}>
                  <Form.Item
                    name="category"
                    label={<FormattedMessage id="condition-edit.question-category-label" />}
                  >
                    <Select
                      name="category"
                      disabled={isFormDisabled}
                      showSearch
                      optionFilterProp="label"
                      allowClear
                      options={questionCategoriesSelectOptions.map(({ value, label }) => ({
                        value,
                        label: intl.formatMessage({ id: label }),
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={isFreetextOrFileQuestionType ? 8 : 4}>
                  <Form.Item
                    name="buildTimeIf"
                    label={<FormattedMessage id="condition-edit.build-time-if-label" />}
                  >
                    <Input name="buildTimeIf" disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="condition"
                    label={<FormattedMessage id="condition-edit.condition-label" />}
                  >
                    <Input.TextArea name="condition" disabled={isFormDisabled} rows={1} autoSize />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`patient.${activeLanguage}`}
                    required
                    hasFeedback
                    labelCol={{ span: 24 }}
                    label={<FormattedMessage id="condition-edit.question-patient-label" />}
                  >
                    <Input.TextArea
                      name={`patient.${activeLanguage}`}
                      disabled={isFormDisabled}
                      rows={1}
                      autoSize
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`staff.${activeLanguage}`}
                    label={<FormattedMessage id="condition-edit.staff-label" />}
                  >
                    <Input.TextArea
                      name={`staff.${activeLanguage}`}
                      disabled={isFormDisabled}
                      rows={1}
                      autoSize
                    />
                  </Form.Item>
                </Col>
                {isYesNoQuestionType && (
                  <YesNoQuestionType
                    activeLanguage={activeLanguage}
                    isFormDisabled={isFormDisabled}
                  />
                )}
                {(isNumberQuestionType || isRangeQuestionType) && (
                  <NumberOrRangeQuestionType
                    activeLanguage={activeLanguage}
                    isFormDisabled={isFormDisabled}
                    isRangeQuestionType={isRangeQuestionType}
                    formikProps={formikProps}
                    isDisabled={isDisabled || isDisabledQuestionType}
                    isLoading={conditionStore.isLoading()}
                  />
                )}
                <Col span={8}>
                  <Form.Item
                    name="textCondition"
                    required={isTextConditionRequired}
                    hasFeedback
                    label={<FormattedMessage id="condition-edit.textCondition-label" />}
                  >
                    <Input name="textCondition" disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`linkTitle.${activeLanguage}`}
                    label={<FormattedMessage id="condition-edit.link-title-label" />}
                  >
                    <Input name={`linkTitle.${activeLanguage}`} disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`linkInformation.${activeLanguage}`}
                    label={<FormattedMessage id="condition-edit.link-information-label" />}
                  >
                    <Input.TextArea
                      name={`linkInformation.${activeLanguage}`}
                      disabled={isFormDisabled}
                      rows={1}
                      autoSize
                    />
                  </Form.Item>
                </Col>
                {isSingleOrMultipleQuestionType && (
                  <Col span={8}>
                    <Form.Item
                      name="showNegative"
                      label={<FormattedMessage id="condition-edit.show-negative-label" />}
                    >
                      <Switch name="showNegative" disabled={isFormDisabled} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {isSingleOrMultipleQuestionType && (
                <Fragment>
                  <Typography.Title level={4}>
                    * <FormattedMessage id="condition-edit.responses-heading" />
                  </Typography.Title>
                  <FieldArray name="content">
                    {arrayHelpers => {
                      return (
                        <QuestionContentList
                          formikArrayHelpers={arrayHelpers}
                          isSaving={conditionStore.isLoading()}
                          isDisabled={isDisabled || isDisabledQuestionType}
                          activeLanguage={activeLanguage}
                        />
                      );
                    }}
                  </FieldArray>
                </Fragment>
              )}
              <Divider />
              <FormActionButtons
                isSaving={conditionStore.isLoading()}
                isDisabled={isDisabled || isDisabledQuestionType}
                isValid={isValid && dirty}
                onCancel={onCancel}
                showCancelConfirm={dirty}
                cancelDeclineText={
                  <FormattedMessage id="condition-edit.statement-cancel-confirm" />
                }
              />
            </Form>
          </Fragment>
        );
      }}
    </Formik>
  );
};

export default QuestionForm;
