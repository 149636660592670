import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useState } from 'react';
import { NormalComponents } from 'react-markdown/lib/complex-types';

import styles from './MarkdownSection.module.css';

export const MarkdownSection: NormalComponents['section'] = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const headerChild = React.isValidElement(children[0]) ? children[0] : <header />;
  const contentChildren = children.slice(1);
  return (
    <Collapse
      className={styles.collapse}
      activeKey={isActive ? 'section' : undefined}
      onChange={() => setIsActive(!isActive)}
      expandIcon={() => (
        <div className={styles.iconContainer}>
          {isActive ? (
            <MinusCircleTwoTone className={styles.icon} />
          ) : (
            <PlusCircleTwoTone className={styles.icon} />
          )}
        </div>
      )}
      expandIconPosition="end"
      items={[
        {
          key: 'section',
          children: contentChildren,
          collapsible: 'header',
          className: styles.collapsePanel,
          label: (
            <div className={styles.header} data-testid="section-header-text">
              {headerChild.props.children}
            </div>
          ),
        },
      ]}
    />
  );
};
