import { Col, Divider, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Select, Input } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { NO_LEADING_TRAILING_SPACE_REGEX } from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';
import { CODE24_MODEL_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { Include } from 'modules/Content24/Condition/models/Code24Model';
import {
  getDataFromFinalFormValues,
  getInitialFormValues,
} from 'modules/Content24/Condition/utils/forms';
import {
  ExpressionError,
  validateStatementConditionWithDebounce,
  validateStatementBuildTimeIfWithDebounce,
} from 'modules/Content24/Condition/utils/validationUtils';
import {
  getConditionsFilteredByGroupAsSelectOptions,
  getQuestionsFromConditionAsSelectOptions,
} from 'modules/Content24/utils';
import { sortWithLocale } from 'utils/textUtils';

/**
 * @notExported
 */
interface IncludeFormProps {
  onCancel: () => void;
  onSubmit: (data: Include) => void;
  activeLanguage: string;
  data?: Include;
  isDisabled?: boolean;
}

const IncludeForm: FunctionComponent<IncludeFormProps> = ({
  data,
  onCancel,
  onSubmit,
  activeLanguage,
  isDisabled,
}) => {
  const intl = useIntl();
  const { conditionStore, conditionsListStore } = useContext(RootStoreContext);
  const defaultValues: Include = {
    id: '',
    type: CODE24_MODEL_TYPES.INCLUDE,
    condition: '',
    target: '',
    buildTimeIf: '',
    question: '*',
  };
  const initialValues = getInitialFormValues<Include, Include>(defaultValues, data);

  const handleSubmit = useCallback(
    (dataToSubmit: Include) => {
      const transformedData = getDataFromFinalFormValues<Include>(dataToSubmit);

      // If question is an asterisk (all questions option), remove the question,
      // as API does not expect it to be there.
      if (transformedData.question === '*') {
        delete transformedData.question;
      }

      onSubmit(transformedData);
    },
    [onSubmit]
  );

  const validationSchema = Yup.object().shape({
    target: Yup.string().required(
      intl.formatMessage({
        id: 'general.errors.required',
      })
    ),
    question: Yup.string(),
    condition: Yup.string()
      .test(
        'isValidCondition',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementConditionWithDebounce
      )
      .nullable(),
    buildTimeIf: Yup.string()
      .test(
        'noLeadingTrailingSpace',
        intl.formatMessage({ id: 'general.errors.leading_trailing_space_not_allowed' }),
        function (this: Yup.TestContext, value: string) {
          return !NO_LEADING_TRAILING_SPACE_REGEX.test(value);
        }
      )
      .test(
        'isValidBuildTimeIf',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementBuildTimeIfWithDebounce
      )
      .nullable(),
  });

  useEffect(() => {
    if (initialValues.target) {
      conditionStore.handleActiveLibraryConditionChange(initialValues.target);
    }
  }, [conditionStore, initialValues.target]);

  useEffect(
    () => () => {
      conditionStore.clearActiveLibraryCondition();
    },
    // Should run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, setFieldValue, dirty }) => (
        <Observer>
          {() => {
            const {
              isLoadingActiveLibraryCondition,
              activeLibraryCondition,
              handleActiveLibraryConditionChange,
            } = conditionStore;
            const libraryConditionsSelectOptions = getConditionsFilteredByGroupAsSelectOptions(
              conditionsListStore.conditions,
              'libraries'
            );
            const libraryConditionQuestionsSelectOptions = activeLibraryCondition
              ? getQuestionsFromConditionAsSelectOptions(activeLibraryCondition)
              : [];

            return (
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="target"
                      required
                      label={<FormattedMessage id="condition-edit.include-label" />}
                    >
                      <Select
                        name="target"
                        disabled={isDisabled}
                        onChange={value => {
                          handleActiveLibraryConditionChange(value);
                          setFieldValue('question', '');
                        }}
                        loading={isLoadingActiveLibraryCondition}
                        options={libraryConditionsSelectOptions
                          .map(({ label, value }) => ({
                            value,
                            label: label[activeLanguage] || value,
                          }))
                          .sort((a, b) => sortWithLocale(a, b, 'label', activeLanguage))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="question"
                      label={<FormattedMessage id="condition-edit.question-label" />}
                    >
                      <Select
                        name="question"
                        disabled={
                          isDisabled ||
                          isLoadingActiveLibraryCondition ||
                          !libraryConditionQuestionsSelectOptions.length
                        }
                        optionFilterProp="label"
                        loading={isLoadingActiveLibraryCondition}
                        options={[
                          { value: '*', label: intl.formatMessage({ id: 'general.select-all' }) },
                        ].concat(libraryConditionQuestionsSelectOptions)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="condition"
                      label={<FormattedMessage id="condition-edit.condition-label" />}
                    >
                      <Input.TextArea name="condition" disabled={isDisabled} rows={1} autoSize />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="buildTimeIf"
                      label={<FormattedMessage id="condition-edit.build-time-if-label" />}
                    >
                      <Input name="buildTimeIf" disabled={isDisabled} />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <FormActionButtons
                  isSaving={conditionStore.isLoading()}
                  isDisabled={isDisabled}
                  isValid={isValid && dirty}
                  onCancel={onCancel}
                  showCancelConfirm={dirty}
                  cancelDeclineText={
                    <FormattedMessage id="condition-edit.statement-cancel-confirm" />
                  }
                />
              </Form>
            );
          }}
        </Observer>
      )}
    </Formik>
  );
};

export default IncludeForm;
