import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import PartnerStatus from 'modules/PartnerStatus';

import styles from './Rules.module.css';
import { RulesPackage } from '../components/RulesPackage';
import { RulesPackagesList } from '../components/RulesPackagesList';

const Rules = () => {
  return (
    <Fragment>
      <PartnerStatus />
      <section className={styles.container}>
        <Switch>
          <Route exact path="/rules" component={RulesPackagesList} />
          <Route path="/rules/:id" component={RulesPackage} />
        </Switch>
      </section>
    </Fragment>
  );
};

export default Rules;
