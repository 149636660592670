import { observable, action, computed, runInAction, IObservableArray } from 'mobx';

import RootStore from 'stores/RootStore';
import { sortWithLocale } from 'utils/textUtils';

import { Phrase, getPhrases, deletePhrase, updatePhrase, createPhrase } from '../api/phrasesApi';

export default class PhrasesStore {
  phrases: IObservableArray<Phrase> = observable.array([]);
  @observable.shallow activePhrase?: Phrase;
  @observable isLoading = false;

  constructor(
    private rootStore: RootStore,
    private careProviderId: string,
    private careUnitId: string
  ) {}

  @action
  fetchPhrases = async () => {
    try {
      this.isLoading = true;
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      const { data } = await getPhrases(partnerId, this.careProviderId, this.careUnitId);

      runInAction(() => {
        this.phrases.replace(data);
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @computed
  get categories() {
    return this.phrases
      .reduce((accumulator: string[], current: Phrase) => {
        if (current.category && !accumulator.includes(current.category)) {
          accumulator.push(current.category);
        }

        return accumulator;
      }, [])
      .sort((a, b) => sortWithLocale(a, b));
  }

  @action
  handleAdd = () => {
    this.activePhrase = {
      id: '',
      careUnitId: this.careUnitId,
      category: '',
      header: '',
      text: '',
    };
  };

  @action
  handleEdit = async (phrase: Phrase) => {
    this.activePhrase = phrase;
  };

  @action
  handleDelete = async (phrase: Phrase) => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      await deletePhrase(phrase.id, partnerId, this.careProviderId, this.careUnitId);
      runInAction(() => {
        this.phrases.replace(this.phrases.filter(({ id }) => id !== phrase.id));
      });
      this.rootStore.flashMessageService.translatedSuccess('phrases.phrases-deleted-message');
    } catch {
      this.rootStore.flashMessageService.translatedError('general.error');
    }
  };

  @action
  handleCancel = () => {
    this.activePhrase = undefined;
  };

  @action
  handleUpdate = async (phrase: Phrase) => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      const { data } = await updatePhrase(phrase, partnerId, this.careProviderId, this.careUnitId);
      runInAction(() => {
        this.activePhrase = undefined;
        this.phrases.replace(this.phrases.map(item => (item.id === phrase.id ? data : item)));
      });
      this.rootStore.flashMessageService.translatedSuccess('phrases.phrases-updated-message');
    } catch {
      this.rootStore.flashMessageService.translatedError('general.error');
    }
  };

  @action
  handleCreate = async (phrase: Phrase) => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    try {
      const { data } = await createPhrase(phrase, partnerId, this.careProviderId, this.careUnitId);
      runInAction(() => {
        this.activePhrase = undefined;
        this.phrases.push(data);
      });
      this.rootStore.flashMessageService.translatedSuccess('phrases.new-phrase-created-message');
    } catch {
      this.rootStore.flashMessageService.translatedError('general.error');
    }
  };

  handleSubmit = (phrase: Phrase) => {
    if (phrase.id) {
      this.handleUpdate(phrase);
    } else {
      this.handleCreate(phrase);
    }
  };
}
