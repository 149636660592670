import { DeleteOutlined } from '@ant-design/icons';
import { Typography, Popconfirm, Button, Col, Row } from 'antd';
import { FieldArray, useFormikContext } from 'formik';
import { Form, Select } from 'formik-antd';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';
import {
  isList,
  isMap,
  isSet,
  Outcome,
  RuleDraft,
  RULES_OUTCOME_OPERATOR,
} from 'modules/Rules/types';
import { convertObjectLikeDataToArrayLike, getConstraints } from 'modules/Rules/utils/utils';
import { createSelectOptions } from 'utils/selectUtils';

import { OutcomeValue } from './OutcomeValue';
import styles from './RuleForm.module.css';

type OutcomesListProps = {
  outcomes?: Outcome[];
  isDisabled: boolean;
};

export const OutcomesList = ({ outcomes = [], isDisabled = false }: OutcomesListProps) => {
  const { formatMessage } = useIntl();
  const {
    rulesPackageStore: { rulesAttributes },
    flashMessageService,
  } = useContext(RootStoreContext);
  const { setFieldValue, setFieldTouched, setValues, setErrors, values, errors } =
    useFormikContext<RuleDraft>();

  const fieldSelectOptions = createSelectOptions(Object.keys(rulesAttributes).sort());
  const isDeleteButtonDisabled = isDisabled || outcomes.length <= 1;

  const fieldOnChangeHandler = (fieldId: string, idx: number) => {
    // outcome has 3 REQUIRED sections/parts which we render in 3
    // form fields: `field`, `operator` and `value`.
    // when user changes `field` field value we have to reset and
    // rerender following two form fields which are `operator` and `value`.
    // Because all 3 parts of outcome are required when user changes `field` value
    // we would like to:
    // -- render/rerender form fields for `operator` and `value` parts of outcome
    // -- prefill them with some `default values` if those exists in outcome meta

    // in case user crates new outcome OR clear vlaue of `field` form field
    // we clear all other, dependent on `field` value, form elements
    if (fieldId === null || fieldId === undefined) {
      // outcomeFieldId === null --> creation of new outcome
      // outcomeFieldId === undefined --> filed select has been cleared
      setFieldValue(`outcomes[${idx}].value`, null, false);
      setFieldValue(`outcomes[${idx}].operator`, null, false);
      // this should trigger displaying of validation error on a `filed`
      setFieldTouched(`outcomes[${idx}].field`, true, true);
      return;
    }

    // also `filed` value dictates us how `value` and `operator` form fields does look like,
    // so here is logic of adding necessary form fields and populating them with values
    const selectedFieldMeta = rulesAttributes[fieldId];

    const isMapTypeOutcome = isMap(selectedFieldMeta);
    const isListTypeOutcome = isList(selectedFieldMeta);
    const isSetTypeOutcome = isSet(selectedFieldMeta);
    const { allowedValues } = getConstraints(selectedFieldMeta);
    const defaultValue = rulesAttributes[fieldId].defaultValue ?? null;
    let valueToSet;

    if (isMapTypeOutcome) {
      if (defaultValue === null) {
        // if there is no default value to set we check if there any allowed values
        // and if there is allowed values array we pick first value to pre-set `bu default` for key
        // worth to mention `allowedValues` for map is one dimentional array and it limits
        // only KEYS for map, not values
        valueToSet = [{ key: allowedValues?.[0] ?? null, value: null }];
      } else {
        const convertedDefaultValue = convertObjectLikeDataToArrayLike(defaultValue);
        valueToSet = [convertedDefaultValue[0]];
      }
    } else if (isListTypeOutcome || isSetTypeOutcome) {
      valueToSet = defaultValue ? defaultValue : [];
    } else {
      valueToSet = defaultValue ? defaultValue : allowedValues?.[0] ?? null;
    }

    // setting values
    const { outcomes } = values;
    const updatedOutcome = {
      field: fieldId,
      value: valueToSet,
      operator: RULES_OUTCOME_OPERATOR.SET,
    };
    outcomes.splice(idx, 1, updatedOutcome);

    // revalidate form ONLY if there was `default` value in order to activate
    // saveButton
    setValues({ ...values, outcomes }, Boolean(defaultValue));

    // resetting errors. we have to do that since couple formik + formik-antd
    // is a little bit laggy and have one extra rerender, during which
    // to already newely rendered form control (e.g. STRING type) previous
    // stale errors state would be passed (e.g. from MAP type)
    // like `errors: {outcomes: [{key: 'errorString', value: 'errorString'}]}` ==> MAP type error shape
    // which lead to form crash since it expect another shape of error state
    // `errors: {outcomes: [{ value: 'errorString'}]}` ==> simple STRING type error shape
    const { outcomes: existingOutcomesErrors = [] } = errors;
    if (Array.isArray(existingOutcomesErrors) && existingOutcomesErrors[idx]) {
      // @ts-expect-error Formik expects errors only (not undefined) as an items of errors array here
      // but we must set undefined in order to `clear` error at some particular index of errors array
      existingOutcomesErrors.splice(idx, 1, undefined);
    }
    const errorsToSet = { ...errors, outcomes: existingOutcomesErrors };

    setErrors(errorsToSet);
  };

  return (
    <FieldArray
      name="outcomes"
      render={arrayHelpers => {
        return (
          <>
            <Typography.Title level={3}>{formatMessage({ id: 'rules.outcome' })}</Typography.Title>

            {outcomes.map((outcome: Outcome, idx) => {
              const rowTitle = `${formatMessage({
                id: 'rules.outcome',
              })} ${idx + 1}`;

              const outcomeMeta =
                // field === null --> creation of new outcome
                // field === undefined --> filed select has been cleared
                outcome.field === null || outcome.field === undefined
                  ? null
                  : rulesAttributes[outcome.field];

              // if by some reason outcomeMeta cant be found (e.g. bad configuration)
              // we show error
              if (outcome.field && outcomeMeta === undefined) {
                flashMessageService.translatedError(
                  'rules.errors.attribute-for-filed-missed',
                  { toastId: `error-${outcome.field}` },
                  { fieldId: outcome.field }
                );
                return null;
              }

              const operatorsSelectData =
                outcomeMeta === null ? [] : createSelectOptions(outcomeMeta.type.allowedOperators);

              return (
                <div key={rowTitle} className={styles.outcomeContainer}>
                  <div className={styles.header}>
                    <Typography.Title level={4} className={styles.outcomeTitle}>
                      {rowTitle}
                    </Typography.Title>
                    {!isDeleteButtonDisabled && (
                      <Popconfirm
                        disabled={isDeleteButtonDisabled}
                        title={formatMessage({ id: 'general.sure-to-delete' })}
                        cancelText={formatMessage({ id: 'general.cancel' })}
                        onConfirm={() => arrayHelpers.remove(idx)}
                      >
                        <Button
                          type="link"
                          icon={<DeleteOutlined />}
                          className={styles.deleteButton}
                          disabled={isDeleteButtonDisabled}
                        >{`${formatMessage({ id: 'general.remove' })} ${rowTitle}`}</Button>
                      </Popconfirm>
                    )}
                  </div>
                  <Row key={`${outcome.field}-${idx}`} gutter={[16, 0]} className={styles.formItem}>
                    <Col span={12}>
                      <Form.Item
                        className={styles.formItem}
                        label={formatMessage({ id: 'rules.field' })}
                        name={`outcomes[${idx}].field`}
                        required
                      >
                        <Select
                          showSearch
                          placeholder={formatMessage({ id: 'general.search-placeholder' })}
                          name={`outcomes[${idx}].field`}
                          disabled={isDisabled}
                          options={fieldSelectOptions}
                          onChange={fieldId => fieldOnChangeHandler(fieldId, idx)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className={styles.formItem}
                        label={formatMessage({ id: 'rules.operator' })}
                        name={`outcomes[${idx}].operator`}
                        required
                      >
                        <Select
                          name={`outcomes[${idx}].operator`}
                          disabled={isDisabled}
                          options={operatorsSelectData}
                        />
                      </Form.Item>
                    </Col>
                    {outcome.operator && outcome.field && (
                      <Col span={24}>
                        <OutcomeValue
                          outcome={outcome}
                          isDisabled={isDisabled}
                          idx={idx}
                          outcomeMeta={outcomeMeta}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              );
            })}
            <Button
              shape="round"
              disabled={isDisabled}
              type="default"
              onClick={() => {
                arrayHelpers.push({
                  field: null,
                  operator: '',
                  value: '',
                });
              }}
            >
              {`+ ${formatMessage({ id: 'general.add' })} ${formatMessage({
                id: 'rules.outcome',
              }).toLocaleLowerCase()}`}
            </Button>
          </>
        );
      }}
    />
  );
};
