import { Text } from '@vx/text';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { ShapeProps } from './shapeModel';
import styles from './shapes.module.css';

/**
 * @notExported
 */
interface HexagonProps extends ShapeProps {
  type?: 'goto' | 'breakingExit' | 'exit';
}

const Hexagon: FunctionComponent<HexagonProps> = ({
  width = 100,
  height = 30,
  type = 'goto',
  title,
  text,
  onClick,
  isDimmed,
  className,
  ...restProps
}) => {
  const [pointsString, setPointsString] = useState('');

  const padding = 2;
  const totalWidth = width + padding;
  const totalHeight = height + padding;
  const textPadding = totalHeight / 2;
  const textWidth = width - textPadding * 2;

  const opacity = isDimmed ? 0.2 : 1;

  useEffect(() => {
    const halfPadding = padding / 2;
    const halfHeight = totalHeight / 2;
    const points = [
      // left triangle
      { x: halfHeight, y: halfPadding },
      { x: 0 + halfPadding, y: halfHeight },
      { x: halfHeight, y: totalHeight - halfPadding },
      // right triangle
      { x: totalWidth - halfHeight, y: totalHeight - halfPadding },
      { x: totalWidth - halfPadding, y: halfHeight },
      { x: totalWidth - halfHeight, y: halfPadding },
    ];

    const pointsString: string = points.reduce(
      (accumulator: string, current: { x: number; y: number }, index) => {
        return `${accumulator} ${current.x} ${current.y}${index !== points.length - 1 ? ',' : ''}`;
      },
      ''
    );

    setPointsString(pointsString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <svg
      width={totalWidth}
      height={totalHeight}
      onClick={onClick}
      className={className}
      {...restProps}
    >
      <polygon className={styles[type]} points={pointsString} strokeWidth={1} opacity={opacity} />
      {title && (
        <Text
          width={textWidth}
          x={width / 2}
          y={15}
          verticalAnchor="start"
          textAnchor="middle"
          opacity={opacity}
          className={styles.title}
        >
          {title}
        </Text>
      )}
      {text && (
        <Text
          className={styles.text}
          width={textWidth}
          x={width / 2}
          y={title ? 40 : 15}
          verticalAnchor="start"
          textAnchor={'middle'}
          opacity={opacity}
          lineHeight="18px"
        >
          {text}
        </Text>
      )}
    </svg>
  );
};

export default Hexagon;
