import { LoadingOutlined } from '@ant-design/icons';
import { Typography, List, Spin, Alert } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import PageWithSectionsContainer from 'components/PageWithSectionsContainer';
import PageWithSectionsContent from 'components/PageWithSectionsContent';
import RootStoreContext from 'context/RootStoreContext';

import styles from './AccountDeletion.module.css';
import { fetchPatientData, PatientData } from '../api/fetchPatientData';

export const AccountDeletion = () => {
  const { partnersStore } = useContext(RootStoreContext);

  const { partnerId } = partnersStore;

  const [patient, setPatient] = useState<PatientData>({
    givenName: '',
    surname: '',
    username: '',
  });

  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState<Error | null>(null);

  const { patientId } = useParams<{ patientId: string }>();

  const intl = useIntl();

  useEffect(() => {
    const loadPatient = async () => {
      setIsLoading(true);
      setError(null);

      try {
        if (!partnerId || !patientId) {
          return;
        }
        const { data } = await fetchPatientData(partnerId, patientId);

        setPatient(data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPatient();
  }, [partnerId, patientId]);

  if (error) {
    return (
      <PageWithSectionsContainer>
        {error?.message || <FormattedMessage id="general.error" />}
      </PageWithSectionsContainer>
    );
  }

  if (isLoading) {
    return <Spin size="large" className={styles.spinner} indicator={<LoadingOutlined spin />} />;
  }

  const { givenName, surname, username } = patient;

  return (
    <PageWithSectionsContainer>
      <PageWithSectionsContent>
        <Typography.Title level={4}>
          <FormattedMessage id="account-deletion.title" />
        </Typography.Title>
        <List
          split={false}
          className={styles.list}
          header={<FormattedMessage id="account-deletion.user-delete" />}
          dataSource={[`${givenName} ${surname}`, username, partnerId]}
          renderItem={item => <List.Item className={styles.listItem}>{item}</List.Item>}
        />
        <Typography.Paragraph>
          <FormattedMessage
            id="account-deletion.ios-notice"
            values={{
              policy: (
                <a
                  href="https://developer.apple.com/news/?id=12m75xbj"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="account-deletion.apple-policy" />
                </a>
              ),
            }}
          />
        </Typography.Paragraph>
        <Typography.Paragraph>
          <FormattedMessage id="account-deletion.reopen-account" />
        </Typography.Paragraph>
        <Typography.Paragraph>
          <FormattedMessage
            tagName="div"
            id="account-deletion.email-notice"
            values={{
              platform24Support: (
                <a
                  href={`mailto:support@platform24.com?subject=${intl.formatMessage({
                    id: 'account-deletion.title',
                  })}&body=Hello,%0D%0A%0D%0A${intl.formatMessage({
                    id: 'account-deletion.confirmation',
                  })}%0D%0AUserID: ${patientId} %0D%0A%0D%0A${intl.formatMessage({
                    id: 'account-deletion.thank-you',
                  })}%0D%0A${partnerId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@platform24.com
                </a>
              ),
            }}
          />
        </Typography.Paragraph>
        <Typography.Paragraph copyable className={styles.confirmation}>
          <FormattedMessage id="account-deletion.confirmation" />
          <br />
          UserID: {patientId}
        </Typography.Paragraph>
        <Alert
          message={intl.formatMessage({ id: 'account-deletion.user-data-notice' })}
          type="warning"
          banner
          showIcon
          className={styles.notification}
        />
        <FormattedMessage id="account-deletion.thank-you" />
        <Typography.Paragraph>Platform24</Typography.Paragraph>
      </PageWithSectionsContent>
    </PageWithSectionsContainer>
  );
};
