import { Modal, Divider, Alert } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { useEffect, FunctionComponent, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import RootStoreContext from 'context/RootStoreContext';
import { CurrentPartnerStatus } from 'modules/PartnerStatus/stores/PartnerStatusViewStore';

interface Props {
  isSaving: boolean;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  isVisible: boolean;
  initialValues: CurrentPartnerStatus | null;
}

const SendForApprovalModal: FunctionComponent<Props> = ({
  isVisible,
  initialValues,
  onSubmit,
  onCancel,
  isSaving,
}) => {
  const intl = useIntl();
  const context = useContext(RootStoreContext);
  const validationSchema = Yup.object().shape({
    description: Yup.string().required(() => <FormattedMessage id="general.errors.required" />),
  });
  useEffect(() => {
    if (isVisible) {
      context.conditionsListStore.refreshValidationStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <Modal
      open={isVisible}
      destroyOnClose
      title={<FormattedMessage id="partner-status.merge-request-approval-modal-title" />}
      footer={null}
      closable={false}
    >
      <Observer>
        {() => {
          const { conditionsListStore } = context;
          const content24ValidationErrors =
            context.conditionsListStore.conditionsValidationStatus.conditionsWithErrors;
          const hasContent24ValidationErrors = !!content24ValidationErrors.length;
          const content24ValidationList = content24ValidationErrors.join(', ');

          if (!initialValues) {
            return null;
          }
          return (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isValid, submitForm, dirty }) => (
                <Form>
                  <Form.Item
                    name="description"
                    label={<FormattedMessage id="general.description" />}
                  >
                    <Input.TextArea name="description" rows={4} />
                  </Form.Item>
                  <p>
                    <FormattedMessage id="partner-status.merge-request-approval-modal-text" />
                  </p>
                  {hasContent24ValidationErrors && (
                    <Alert
                      message={intl.formatMessage(
                        { id: 'partner-status.content24-errors' },
                        { conditions: content24ValidationList }
                      )}
                      type="warning"
                      showIcon
                    />
                  )}
                  <Divider />
                  <FormActionButtons
                    isSaving={isSaving || conditionsListStore.isLoadingValidationStatus}
                    isValid={isValid && dirty}
                    onCancel={onCancel}
                    showSubmitConfirm={hasContent24ValidationErrors}
                    submitQuestionText={intl.formatMessage({
                      id: 'partner-status.content24-errors-confirm',
                    })}
                    onSubmit={submitForm}
                  />
                </Form>
              )}
            </Formik>
          );
        }}
      </Observer>
    </Modal>
  );
};

export default SendForApprovalModal;
