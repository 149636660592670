import axios, { AxiosError } from 'axios';
import omit from 'lodash/omit';
import { observable, IObservableArray, computed, action, when, runInAction } from 'mobx';
import { ChangeEvent } from 'react';

import { DEFAULT_LOCALE } from 'constants/dateFormat';
import { LANGS } from 'constants/enums';
import { getConditionGroupsFilteredByCategoryAsSelectOptions } from 'modules/Content24/utils';
import RootStore from 'stores/RootStore';
import { InputOption } from 'types/types';
import { capitalizeFirst } from 'utils/textUtils';

import { NewPartnerCondition } from '../api/partnerCode24api';
import {
  CODE24_CATEGORIES,
  CODE24_MODEL_TYPES,
  EDITABLE_CONDITION_SECTION_MODEL_TYPES,
  DISABLED_CONDITIONS,
} from '../constants/code24types';
import { Statement, SearchTerm, Section, Metadata } from '../models/Code24Model';

export type ConditionListStatement = Statement & {
  errorMessages?: string[];
};

export interface ConditionListSection extends Omit<Section, 'content'> {
  children: ConditionListStatement[];
}

export type ConditionListItem = ConditionListSection | ConditionListStatement;

export default class ConditionViewStore {
  @observable searchTerm = '';
  @observable expandedRowKeys: IObservableArray<string> = observable.array([]);
  @observable activeLanguage: LANGS = DEFAULT_LOCALE;
  @observable isUpdatingHiddenConditions = false;
  @observable newSection?: Section;
  @observable newStatementSection?: ConditionListSection;
  @observable activeStatement?: ConditionListStatement | SearchTerm;

  constructor(private rootStore: RootStore, private locale: LANGS) {
    when(
      () => !!this.rootStore.content24Store.availableLanguages.length,
      () => {
        this.activeLanguage = this.rootStore.content24Store.availableLanguages[0];
      }
    );
  }

  @computed
  get isDisabledCondition() {
    return (
      DISABLED_CONDITIONS.includes(this.conditionMetadata.conditionId) ||
      !this.rootStore.conditionStore.originCondition
    );
  }

  @computed
  get isDefaultCondition() {
    return this.rootStore.conditionStore.originCondition === null;
  }

  @computed
  get conditionMetadata() {
    return this.rootStore.conditionStore.condition.metadata;
  }

  @computed
  get conditionDefaultValues() {
    return this.rootStore.conditionStore.condition.defaultValues;
  }

  @computed
  get searchTermsList() {
    const {
      conditionStore: { condition, conditionValidationStatus },
    } = this.rootStore;

    if (!condition.metadata.conditionId) {
      return [];
    }

    const searchTermsSection: ConditionListSection[] = [
      {
        type: CODE24_MODEL_TYPES.SEARCH_TERMS,
        id: 'search-terms',
        children: [...(condition.searchTerms || [])].map(item =>
          item.id in conditionValidationStatus
            ? { ...item, errorMessages: conditionValidationStatus[item.id] }
            : item
        ),
        index: 0,
      },
    ];

    searchTermsSection[0].children.sort(a => ('shouldDisplay' in a && a.shouldDisplay ? -1 : 1));

    if (!this.searchTerm) {
      return searchTermsSection;
    }

    return searchTermsSection
      .map(topBlock => ({
        ...topBlock,
        // topBlock always has children prop
        children: topBlock.children.filter(
          statement =>
            statement.id?.toLowerCase().includes(this.searchTerm) ||
            ('term' in statement &&
              statement.term &&
              statement.term[this.locale]?.toLowerCase().includes(this.searchTerm))
        ),
      }))
      .filter(({ children }) => children.length);
  }

  @computed
  get conditionItemsList() {
    if (!this.searchTerm) {
      return this.conditionItems;
    }

    return this.conditionItems
      .map(topBlock => ({
        ...topBlock,
        // topBlock always has children prop
        children: topBlock.children.filter(
          statement =>
            statement.id?.toLowerCase().includes(this.searchTerm) ||
            ('patient' in statement &&
              statement.patient &&
              statement.patient[this.locale]?.toLowerCase().includes(this.searchTerm)) ||
            ('condition' in statement &&
              statement.condition?.toLowerCase().includes(this.searchTerm)) ||
            ('target' in statement && statement.target?.toLowerCase().includes(this.searchTerm)) ||
            ('question' in statement &&
              statement.question &&
              statement.question[this.locale]?.toLowerCase().includes(this.searchTerm))
        ),
      }))
      .filter(({ children }) => children.length);
  }

  @computed
  get conditionItems(): ConditionListSection[] {
    const {
      conditionStore: { condition, conditionValidationStatus },
    } = this.rootStore;

    if (!condition.metadata.conditionId) {
      return [];
    }

    return condition.sections
      ? condition.sections.map(section => ({
          ...omit(section, 'content'),
          children: section.content
            .slice()
            .map(item =>
              item.id in conditionValidationStatus
                ? { ...item, errorMessages: conditionValidationStatus[item.id] }
                : item
            ),
        }))
      : [];
  }

  @computed
  get title() {
    const { condition } = this.rootStore.conditionStore;

    if (!condition.metadata.conditionId) {
      return '';
    }

    return capitalizeFirst(
      (condition.metadata.title && condition.metadata.title[this.locale]) ||
        condition.metadata.conditionId
    );
  }

  @computed
  get conditionIsHidden() {
    const {
      conditionStore: { condition },
      content24Store: { hiddenConditions },
    } = this.rootStore;

    return hiddenConditions.includes(condition.metadata.conditionId);
  }

  @computed
  get conditionHasErrors() {
    return !!Object.keys(this.rootStore.conditionStore.conditionValidationStatus).length;
  }

  @computed
  get isCheckingForErrors() {
    return this.rootStore.conditionStore.isLoadingValidationStatus;
  }

  @computed({ keepAlive: true })
  get availableNewSections(): Section[] {
    const {
      conditionStore: {
        conditionCategory,
        condition: { sections },
      },
    } = this.rootStore;

    if (
      // condition category can be empty before all data is fetched
      !conditionCategory ||
      // In Assesment and Diagnosis category condition sections are only added
      // when creating ones.
      conditionCategory === CODE24_CATEGORIES.ASSESSMENT ||
      conditionCategory === CODE24_CATEGORIES.DIAGNOSIS
    ) {
      return [];
    }

    let sectionsTypes = EDITABLE_CONDITION_SECTION_MODEL_TYPES;

    if (conditionCategory === CODE24_CATEGORIES.TRIAGE) {
      sectionsTypes = sectionsTypes.filter(
        type => ![CODE24_MODEL_TYPES.PRE_POST_TRIAGE, CODE24_MODEL_TYPES.HEALTH_TEST].includes(type)
      );
    }

    const nonExistingSectionsTypes = sectionsTypes.filter(
      type => !sections.some(section => section.type === type)
    );

    return nonExistingSectionsTypes.map(type => ({
      id: type.toString(),
      type,
      index: 0,
      content: [],
    }));
  }

  @computed
  get newStatementTypeOptions(): InputOption<CODE24_MODEL_TYPES>[] {
    const {
      conditionStore: { conditionCategory, availableSectionStatements },
    } = this.rootStore;
    const parentSection = this.newStatementSection;
    const partnerAvailableStatements =
      this.rootStore.partnersStore.partnerCustomizations.get('CODE24_AVAILABLE_STATEMENTS') || [];

    if (
      !parentSection ||
      !partnerAvailableStatements.length ||
      !conditionCategory ||
      !availableSectionStatements
    ) {
      return [];
    }

    if (
      parentSection.type === CODE24_MODEL_TYPES.SEARCH_TERMS &&
      partnerAvailableStatements.includes(CODE24_MODEL_TYPES.SEARCH_TERM)
    ) {
      return [
        {
          value: CODE24_MODEL_TYPES.SEARCH_TERM,
          label: 'condition.type-SEARCH_TERM',
        },
      ];
    }

    const statementTypeOptions = availableSectionStatements[parentSection.type]
      .filter((value: CODE24_MODEL_TYPES) => {
        if (!partnerAvailableStatements.includes(value)) {
          return false;
        }

        // Intro statement should not be available:
        // 1) if there's already another intro present in section
        // 2) if the condition category is Library
        if (value === CODE24_MODEL_TYPES.INTRO) {
          return (
            conditionCategory !== CODE24_CATEGORIES.LIBRARIES &&
            !parentSection.children.some(({ type }) => type === CODE24_MODEL_TYPES.INTRO)
          );
        }

        if ([CODE24_MODEL_TYPES.GOTO, CODE24_MODEL_TYPES.GUIDANCE].includes(value)) {
          return ![CODE24_CATEGORIES.DIAGNOSIS].includes(conditionCategory);
        }

        return true;
      })
      .map((value: CODE24_MODEL_TYPES) => ({
        value,
        label: `condition.type-${value}`,
      }));

    return statementTypeOptions;
  }

  @computed
  get isLoading() {
    const { conditionStore, content24Store } = this.rootStore;

    return conditionStore.isLoading() || content24Store.isLoading();
  }

  @computed
  get conditionCategory() {
    return this.rootStore.content24Store.groupCategories.get(this.conditionMetadata.level1id);
  }

  @computed
  get conditionGroupsSelectOptions() {
    const { content24Store } = this.rootStore;
    return this.conditionCategory
      ? getConditionGroupsFilteredByCategoryAsSelectOptions(
          content24Store.groups,
          this.conditionCategory,
          this.locale
        )
      : [];
  }

  @computed
  get isSearchTermsDisplayed() {
    const { code24MedconVersion } = this.rootStore.content24Store;

    return (
      // Search terms should not be displayed in Library type condition
      this.conditionCategory !== CODE24_CATEGORIES.LIBRARIES &&
      // Search terms should not be displyed if medical content version is not set for partner and condition is of Assessment type or it has only Post Triage section
      (!!code24MedconVersion ||
        (this.conditionCategory !== CODE24_CATEGORIES.ASSESSMENT &&
          this.conditionItemsList[0]?.type !== CODE24_MODEL_TYPES.POST_TRIAGE))
    );
  }

  @computed
  get isDiagnosisCodesDisplayed() {
    const { isDiagnosisCodesShown } = this.rootStore.content24Store;
    return isDiagnosisCodesShown && this.conditionDefaultValues?.codes;
  }

  @action
  handleHiddenConditionSwitchChange = async (checked: boolean) => {
    const {
      conditionStore: { condition },
      content24Store,
    } = this.rootStore;

    if (condition.metadata.conditionId) {
      this.isUpdatingHiddenConditions = true;

      await content24Store.updateHiddenConditionsCustomization(
        checked,
        condition.metadata.conditionId
      );

      runInAction(() => {
        this.isUpdatingHiddenConditions = false;
      });
    }
  };

  @action
  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.searchTerm = event.target.value.toLowerCase();
    this.expandedRowKeys.replace(
      this.conditionItems.reduce(
        (accumulator: string[], currentVal) => [...accumulator, currentVal.id],
        []
      )
    );
  };

  handleExpand = (expanded: boolean, listItem: ConditionListItem) => {
    this.setExpandedRowKeys(expanded, listItem.id);
  };

  @action
  setExpandedRowKeys = (expanded: boolean, uuid: string) => {
    if (expanded) {
      this.expandedRowKeys.push(uuid);
    } else {
      this.expandedRowKeys.replace(this.expandedRowKeys.filter(elem => elem !== uuid));
    }
  };

  @action
  handleNewStatementSection = (newStatementSection?: ConditionListSection) => {
    this.newStatementSection = newStatementSection;
  };

  @action
  handleClearNewStatementSection = () => {
    this.newStatementSection = undefined;
  };

  handleAddStatement = async (statement: ConditionListStatement) => {
    if (!this.newStatementSection) {
      return;
    }

    try {
      await this.rootStore.conditionStore.handleAddStatement(statement, {
        ...this.newStatementSection,
        content: this.newStatementSection.children,
      } as Section);
      this.rootStore.conditionsListStore.updateAllConditions();

      this.handleClearNewStatementSection();
    } catch (error) {
      this.handleStatementValidationError(error);
    }
  };

  handleDeleteStatement = async (statement: ConditionListStatement) => {
    try {
      await this.rootStore.conditionStore.handleDeleteStatement(statement);
    } catch (error) {
      this.handleStatementValidationError(error);
    }
  };

  handleUpdateActiveStatement = async (statement: ConditionListStatement) => {
    try {
      await this.rootStore.conditionStore.handleUpdateStatement(statement);
      this.rootStore.conditionsListStore.updateAllConditions();

      this.handleClearActiveStatement();
    } catch (error) {
      this.handleStatementValidationError(error);
    }
  };

  // NB. Table index changed... count starts from 1 not 0 because data is nested
  handleMoveStatement = (fromSectionIndex: number, toSectionIndex: number, parentId: string) => {
    const { conditionStore } = this.rootStore;
    const items = this.conditionItemsList.slice();
    const section = items.find(({ id }) => id === parentId);

    if (!section) {
      return;
    }

    const statements = section.children;
    const statementToMove = statements[fromSectionIndex - 1];
    const after =
      toSectionIndex === 1
        ? section.id
        : statements[fromSectionIndex < toSectionIndex ? toSectionIndex - 1 : toSectionIndex - 2]
            .id;

    try {
      conditionStore.handleMoveStatement(statementToMove, after);
      this.rootStore.conditionsListStore.updateAllConditions();
    } catch (error) {
      this.handleStatementValidationError(error);
    }
  };

  handleUpdateMetadata = async (metadata: Metadata) => {
    try {
      await this.rootStore.conditionStore.handleUpdateStatement(metadata);
      this.rootStore.conditionsListStore.updateAllConditions();
    } catch (error) {
      this.rootStore.flashMessageService.translatedError('general.error');
    }
  };

  @action
  handleActiveStatement = (listItem: ConditionListStatement) => {
    this.activeStatement = listItem;
  };

  @action
  handleClearActiveStatement = () => {
    this.activeStatement = undefined;
  };

  @action
  handleNewSection = () => {
    this.newSection = this.availableNewSections[0];
  };

  @action
  handleClearNewSection = () => {
    this.newSection = undefined;
  };

  @action
  handleAddNewSection = async (section: Section) => {
    try {
      await this.rootStore.conditionStore.handleAddSection(section);
      this.rootStore.conditionsListStore.updateAllConditions();

      this.handleClearNewSection();
    } catch (error) {
      this.rootStore.flashMessageService.translatedError('general.error');
    }
  };

  @action
  handleLanguageChange = (language: LANGS) => {
    this.activeLanguage = language;
  };

  handleConditionConversion = async () => {
    const { id, conditionId, level1id } = this.conditionMetadata;

    const newConditionDefinition: NewPartnerCondition = {
      id,
      conditionId,
      group: level1id,
      copyOf: conditionId,
    };

    return this.rootStore.conditionStore.handleConditionConversion(newConditionDefinition);
  };

  handleStatementValidationError = (error: AxiosError | Error | unknown) => {
    // Display validation errors, if there are none, display general error notification.
    if (
      axios.isAxiosError(error) &&
      Array.isArray(error.response?.data) &&
      error.response?.data.length
    ) {
      this.rootStore.flashMessageService.error(
        error.response.data.map(({ message }: { message: string }) => message).join('. ')
      );
    } else {
      this.rootStore.flashMessageService.translatedError('general.error');
    }
  };
}
