import { Typography } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React, { useCallback, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { FormControlButtons } from './FormControlButtons';
import { FormRegularContent } from './FormRegularContent';
import { InheritanceStateInfo } from './InheritanceStateInfo';
import BoxContent from '../../../../components/BoxContent';
import { useBoolean } from '../../../../hooks/useBoolean';
import { OpeningHoursContext } from '../openingHours.context';
import { useOpeningHoursOnSubmit, useOpeningHoursQuery } from '../openingHours.hooks';
import { MODE, OnSubmitType, OpeningHoursRegular } from '../openingHours.types';

export const FormRegular = () => {
  const { id, mode } = useContext(OpeningHoursContext);
  const { data } = useOpeningHoursQuery(mode, id);
  const onSubmit = useOpeningHoursOnSubmit(mode, id);

  const {
    value: isEditingEnabled,
    setFalse: setIsEditingFalse,
    setValue: setIsEditing,
  } = useBoolean(false);

  const initialValues = useMemo(() => {
    const values =
      isEditingEnabled && data!.candidate?.detailedRegularHoursWrapper
        ? data!.candidate.detailedRegularHoursWrapper
        : data!.release.detailedRegularHoursWrapper;
    const regularHoursInheritedByParent = isEditingEnabled
      ? false
      : values.regularHoursInheritedByParent;
    return {
      ...values,
      regularHoursInheritedByParent,
    };
  }, [isEditingEnabled, data]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        detailedRegularHours: Yup.array().when('regularHoursInheritedByParent', {
          is: false,
          then: Yup.array().of(
            Yup.object().shape({
              closedAllDay: Yup.boolean().required(),
              day: Yup.string().required(),
              openTime: Yup.string().nullable().when('closedAllDay', {
                is: false,
                then: Yup.string().required(),
              }),
              closeTime: Yup.string().nullable().when('closedAllDay', {
                is: false,
                then: Yup.string().required(),
              }),
            })
          ),
        }),
        regularHoursInheritedByParent: Yup.boolean().required(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues]
  );

  const onSubmitCallback = useCallback<OnSubmitType>(
    async (values, formikHelpers) => {
      await onSubmit(values, formikHelpers);
      setIsEditingFalse();
    },
    [setIsEditingFalse, onSubmit]
  );

  return (
    <Formik<OpeningHoursRegular>
      initialValues={initialValues}
      onSubmit={onSubmitCallback}
      enableReinitialize
      validationSchema={validationSchema}
      onReset={(values, formikHelpers) => {
        if (isEditingEnabled) {
          formikHelpers.setFieldValue('regularHoursInheritedByParent', false);
        }
      }}
    >
      <Form data-testid="form-regular">
        <BoxContent
          title={
            <Typography.Text strong>
              <FormattedMessage id="opening-hours.regular.title" />
            </Typography.Text>
          }
          topRightContent={
            <FormControlButtons
              name="regularHoursInheritedByParent"
              isEditingEnabled={isEditingEnabled}
              onIsEditingToggleRequest={setIsEditing}
              onInheritFromParent={mode === MODE.CARE_UNIT ? setIsEditingFalse : undefined}
            />
          }
          topContent={<InheritanceStateInfo mode={mode} isSpecialHours={false} />}
        >
          <FormRegularContent disabled={!isEditingEnabled} />
        </BoxContent>
      </Form>
    </Formik>
  );
};
