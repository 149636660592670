import { notification } from 'antd';
import { useAtomValue, useSetAtom, atom } from 'jotai';
import { useEffect } from 'react';

type NotificationApi = ReturnType<(typeof notification)['useNotification']>[0];
const notificationAtom = atom<NotificationApi | null>(null);

export const useNotificationApi = () => {
  const notificationApi = useAtomValue(notificationAtom);
  if (!notificationApi) {
    throw new Error('Notification API not initialized');
  }

  return notificationApi;
};

export const NotificationApiRoot = () => {
  const [api, notificationContextHolder] = notification.useNotification();

  const setNotificationApi = useSetAtom(notificationAtom);

  useEffect(() => {
    setNotificationApi(api);

    return () => {
      setNotificationApi(null);
    };
  }, [api, setNotificationApi]);

  return notificationContextHolder;
};
