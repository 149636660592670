import { logJSError, logWarning, sendEventV2 } from '@platform24/analytics';

const APP_NAME = 'admin-ui';

type ErrorProps = Omit<Parameters<typeof logJSError>[1], 'app'>;

export const logError = (error: unknown, props: ErrorProps) =>
  logJSError(error, { app: APP_NAME, ...props });

type LogProps = Omit<Parameters<typeof logWarning>[0], 'app'>;

export const logShellWarning = (props: LogProps) => logWarning({ app: APP_NAME, ...props });

type EventProps = Omit<Parameters<typeof sendEventV2>[0], 'app'>;

export const sendV2Event = async (props: EventProps) => {
  try {
    await sendEventV2({ app: APP_NAME, ...props });
  } catch (e) {
    console.error(e);
  }
};
