import {
  MedicineBoxOutlined,
  EditOutlined,
  CaretDownFilled,
  CaretRightFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Table, Input } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment } from 'react';
import Highlighter from 'react-highlight-words';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PartnerCareProvider } from 'api/partnersApi';
import PageHeader from 'components/PageHeader';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { BASIC_DETAILS_FORM_FIELDS as FIELDS } from 'constants/origins';
import RootStoreContext from 'context/RootStoreContext';
import PartnerStatus from 'modules/PartnerStatus';
import RootStore from 'stores/RootStore';
import { sortWithLocale } from 'utils/textUtils';

import styles from './CareProvidersList.module.css';
import CareProvidersListStore from './stores/CareProvidersListStore';
import type { CareProvidersListItem } from './stores/types';

interface Props extends RouteComponentProps<{ careProviderId: string }>, WrappedComponentProps {}

@observer
class CareProvidersList extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  careProvidersListStore: CareProvidersListStore;

  columns = [
    {
      title: <FormattedMessage id="basic-details-form.name-label" />,
      dataIndex: FIELDS.NAME,
      defaultSortOrder: 'ascend' as SortOrder,
      sorter: (a: PartnerCareProvider, b: PartnerCareProvider) => {
        return sortWithLocale(a, b, 'name');
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (text: string) => (
        <Fragment>
          <MedicineBoxOutlined className={styles.homeIcon} />
          {this.renderText(text)}
        </Fragment>
      ),
    },
    {
      title: <FormattedMessage id="general.id" />,
      dataIndex: FIELDS.ID,
      sorter: (a: PartnerCareProvider, b: PartnerCareProvider) => (a.id < b.id ? -1 : 1),
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      width: 500,
      render: (text: string, record: CareProvidersListItem) => {
        const id = record.externalOrgId || record.id;
        return this.renderText(id);
      },
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      width: 100,
      render: (_: string, record: CareProvidersListItem) => {
        return (
          <Fragment>
            {/* Currently editing is only enabled on care unit level / children */}
            {!record.children && record.canView && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() =>
                  this.props.history.push(
                    `/care-providers/${record.careProviderId}/care-units/${record.id}/basic-details`
                  )
                }
              />
            )}

            {/* Currently editing on top level... care provider level. */}
            {record.children &&
              (this.context.userPermissionsStore.canEditCurrentPartner || record.canView) && (
                <>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.props.history.push(`/care-providers/${record.id}/announcements`);
                    }}
                  />
                  <Button
                    type="link"
                    icon={<PlusCircleOutlined />}
                    onClick={() =>
                      this.props.history.push(`/care-providers/${record.id}/care-units/add`)
                    }
                  />
                </>
              )}
          </Fragment>
        );
      },
    },
  ];

  constructor(props: Props, context: RootStore) {
    super(props);
    this.careProvidersListStore = context.careProvidersListStore;
  }

  handleExpand = (expanded: boolean, record: PartnerCareProvider) => {
    this.careProvidersListStore.handleExpand(expanded, record.id);
  };

  renderText = (text: string) =>
    this.careProvidersListStore.searchTerm ? (
      <Highlighter
        highlightClassName={styles.highlightedText}
        searchWords={[this.careProvidersListStore.searchTerm]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    );

  render() {
    const { partnersStore, careUnitsStore } = this.context;
    return (
      <Fragment>
        <PartnerStatus />
        <div className={styles.container}>
          <PageHeader
            content="care-providers-and-care-units"
            breadcrumbs={[
              {
                icon: <MedicineBoxOutlined />,
                text: <FormattedMessage id="care-providers-and-care-units" />,
                link: '/care-providers',
              },
            ]}
            headerActions={
              <Input.Search
                placeholder={this.props.intl.formatMessage({ id: 'general.search' })}
                onChange={this.careProvidersListStore.handleSearchChange}
                className={styles.search}
              />
            }
          />
          <Table<CareProvidersListItem>
            columns={this.columns}
            dataSource={this.careProvidersListStore.careProviderListWithPermissionsAttached}
            pagination={false}
            rowKey={FIELDS.ID}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
                record.children?.length ? (
                  <Button
                    type="link"
                    icon={expanded ? <CaretDownFilled /> : <CaretRightFilled />}
                    onClick={e => onExpand(record, e)}
                  />
                ) : (
                  <span className={styles.spacer}></span>
                ),
              expandedRowKeys: this.careProvidersListStore.expandedRowKeys.toJS(),
              indentSize: 28,
              onExpand: this.handleExpand,
            }}
            data-testid="care-providers-list"
            className={styles.table}
            loading={partnersStore.isLoading() || careUnitsStore.isLoading()}
          />
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(CareProvidersList);
