import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm, Typography } from 'antd';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { useRootStore } from 'context/RootStoreContext';

import { AnnouncementType } from './createAnnouncementModal/types';
import { useDeleteAnnouncement } from './hooks';
import { AnnouncementDTO } from './types';
import { useCareUnitLabel } from './useCareUnitLabel';

const HeaderWrapper = styled.div`
  display: flex;
  font-weight: normal;
  justify-content: space-between;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const DangerButton = styled(Button).attrs({ type: 'primary' })`
  background: #dc2e2e;
`;

const cardStyles = {
  body: {
    backgroundColor: '#F3F9FA',
  },
  header: {
    backgroundColor: '#F3F9FA',
  },
};

interface Props {
  announcement: AnnouncementDTO;
  /**
   * If we can't delete the announcement, we link to the page where the user can do it. Otherwise show a delete button
   */
  linkToActionablePage?: string;
  type: AnnouncementType;
}

export const AnnouncementCard = ({ announcement, linkToActionablePage, type }: Props) => {
  const { mutate: deleteAnnouncementMutation, isLoading } = useDeleteAnnouncement();
  const handleDelete = useCallback(() => {
    deleteAnnouncementMutation(announcement.id);
  }, [announcement.id, deleteAnnouncementMutation]);

  const { careUnitsStore } = useRootStore();

  const careUnitName = useCareUnitLabel(announcement.careUnitId);
  const careUnitOrCareProviderName = announcement.careUnitId
    ? careUnitName
    : careUnitsStore.currentCareProvider?.name;

  return (
    <Card
      styles={cardStyles}
      title={
        <HeaderWrapper>
          <span>
            <FormattedMessage id="Created" />:{' '}
            <span style={{ fontWeight: 'bold' }}>
              {format(announcement.createdAt, 'yyyy-MM-dd, HH:mm')}
            </span>
          </span>
          <span>
            <FormattedMessage id="Visible to" />:{' '}
            <span style={{ fontWeight: 'bold' }}>{careUnitOrCareProviderName}</span>
          </span>
        </HeaderWrapper>
      }
    >
      <Typography.Title level={4}>{announcement.title}</Typography.Title>

      <Typography.Paragraph>{announcement.text}</Typography.Paragraph>

      <Typography.Paragraph type="secondary">
        <FormattedMessage id="Created by" />: {announcement.authorFullName}
      </Typography.Paragraph>

      <ButtonsWrapper>
        {linkToActionablePage ? (
          <Link style={{ textDecoration: 'none' }} to={linkToActionablePage}>
            {type === AnnouncementType.CareUnit ? (
              <FormattedMessage id="Go to care unit" />
            ) : (
              <FormattedMessage id="Go to care provider" />
            )}
          </Link>
        ) : (
          <Popconfirm
            cancelText={<FormattedMessage id="No, keep it" />}
            description={
              <FormattedMessage id="This will permanently remove it and it won't be visible to anyone anymore." />
            }
            okButtonProps={{ danger: true, style: { background: '#dc2e2e' }, type: 'primary' }}
            okText={<FormattedMessage id="Yes, delete" />}
            onConfirm={handleDelete}
            title={<FormattedMessage id="Delete announcement?" />}
          >
            <DangerButton loading={isLoading}>
              <DeleteOutlined aria-hidden /> <FormattedMessage id="general.delete" />
            </DangerButton>
          </Popconfirm>
        )}
      </ButtonsWrapper>
    </Card>
  );
};
