import invert from 'lodash/invert';
import max from 'lodash/max';
import min from 'lodash/min';
import { observable, action, runInAction, ObservableMap, toJS } from 'mobx';

import {
  ORIGIN_RULES_CATEGORIES,
  VIEWABLE_ORIGIN_RULES_CATEGORIES,
  ORIGIN_RULES_CATEGORY_PRIORITY,
} from 'constants/origins';
import RootStore from 'stores/RootStore';

import { OriginRule, fetchOriginRules, updateRule, deleteRule } from '../api/rulesApi';

export default class RulesStore {
  rules: ObservableMap<ORIGIN_RULES_CATEGORIES, OriginRule[]> = observable.map();
  @observable
  isLoading = false;
  // If it's not shallow, it will interfere with Formik's FieldArray handlers.
  @observable.shallow activeRule?: OriginRule;

  constructor(private rootStore: RootStore) {}

  getRuleKeyByPriority(priority: number) {
    const prioritiesSchema = invert(ORIGIN_RULES_CATEGORY_PRIORITY);
    const priorities = Object.keys(prioritiesSchema).map(value => parseInt(value, 10));
    const maxValue = max(priorities);
    const minValue = min(priorities);

    if (minValue === undefined || maxValue === undefined) {
      throw new Error('Mismatched rules priorities');
    }

    const result = prioritiesSchema[
      Math.floor(Math.max(Math.min(priority, maxValue), minValue) / 1000) * 1000
    ] as ORIGIN_RULES_CATEGORIES;

    return VIEWABLE_ORIGIN_RULES_CATEGORIES.includes(result)
      ? result
      : ORIGIN_RULES_CATEGORIES.OTHER;
  }

  @action
  fetchRules = async (originId: string) => {
    const {
      partnersStore: { partnerId },
      flashMessageService,
    } = this.rootStore;

    try {
      this.isLoading = true;
      const { data } = await fetchOriginRules(partnerId, originId);

      runInAction(() => {
        this.rules.replace(
          data.reduce((accumulator: Map<string, OriginRule[]>, current: OriginRule) => {
            const key = this.getRuleKeyByPriority(current.priority);

            const rules = accumulator.get(key) || [];

            accumulator.set(key, rules.concat([current]));

            return accumulator;
          }, new Map())
        );
      });
    } catch (error: any) {
      // Axios errors are handled by http interceptor
      if (!error.request) {
        flashMessageService.error(error.message);
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  handleEdit = (rule: OriginRule) => {
    this.activeRule = rule;
  };

  @action
  handleCancel = () => {
    this.activeRule = undefined;
  };

  @action
  handleAdd = () => {
    this.activeRule = {
      id: '',
      description: undefined,
      priority: 0,
      conditional: [],
      outcome: [],
    };
  };

  @action
  handleUpdate = async (rule: OriginRule) => {
    const {
      partnersStore: { partnerId },
      originStore: { currentOrigin },
      flashMessageService,
    } = this.rootStore;

    if (!currentOrigin?.id) {
      throw new Error('Cannot update rule for unknown origin');
    }

    this.isLoading = true;

    try {
      const { data } = await updateRule(partnerId, currentOrigin.id, rule);

      runInAction(() => {
        const ruleKey = this.getRuleKeyByPriority(rule.priority);
        // Try to find original rule that should be updated first
        let originalRuleKey = ruleKey;
        let originalRulesByKey = toJS(this.rules.get(originalRuleKey));
        let originalRuleIndex = originalRulesByKey?.findIndex(({ id }) => id === rule.id);

        // First, handle edge case when the rule priority is changing due to the update.
        // In this case, we have to look for original rule under different key, as
        // keys (category) are based on rule priority.
        if (originalRuleIndex === undefined || originalRuleIndex < 0) {
          for (const [key, value] of this.rules.entries()) {
            const index = value.findIndex(({ id }) => id === rule.id);

            if (index >= 0) {
              originalRuleKey = key;
              originalRulesByKey = toJS(this.rules.get(originalRuleKey));
              originalRuleIndex = index;
              break;
            }
          }

          if (originalRuleIndex !== undefined && originalRuleIndex >= 0) {
            // This simply can't happen here, as originalRuleIndex is based by originalRulesByKey
            if (!originalRulesByKey) {
              throw new Error('No rules to update');
            }

            originalRulesByKey.splice(originalRuleIndex, 1);

            if (originalRulesByKey.length > 0) {
              this.rules.set(originalRuleKey, originalRulesByKey);
            } else {
              this.rules.delete(originalRuleKey);
            }

            const rulesByKey = toJS(this.rules.get(ruleKey)) || [];
            rulesByKey.push(data);
            rulesByKey.sort((a, b) => a.priority - b.priority);
            this.rules.set(ruleKey, rulesByKey);

            this.activeRule = undefined;
            return;
          }
        }

        // If we still don't have original rule index after that, it means we're dealing with
        // a brand new rule.
        if (originalRuleIndex === undefined || originalRuleIndex < 0) {
          const rulesByKey = toJS(this.rules.get(ruleKey)) || [];
          rulesByKey.push(data);
          rulesByKey.sort((a, b) => a.priority - b.priority);
          this.rules.set(ruleKey, rulesByKey);
          this.activeRule = undefined;
          return;
        }

        // It has to be here by now, as the lack of it means "there's nothing to update"
        // and would have been dealt by the new rule condition above.
        if (!originalRulesByKey) {
          throw new Error('No rules to update');
        }

        // Lastly, deal with a simple update
        originalRulesByKey.splice(originalRuleIndex, 1, data);
        this.rules.set(originalRuleKey, originalRulesByKey);
        this.activeRule = undefined;
      });

      flashMessageService.translatedSuccess('origin.rules.rule-updated-message');
    } catch (error: any) {
      // Axios errors are handled by http interceptor
      if (!error.request) {
        flashMessageService.error(error.message);
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  handleDelete = async (rule: OriginRule) => {
    const {
      partnersStore: { partnerId },
      originStore: { currentOrigin },
      flashMessageService,
    } = this.rootStore;
    const ruleKey = this.getRuleKeyByPriority(rule.priority);
    const rulesByKey = toJS(this.rules.get(ruleKey));

    if (!currentOrigin?.id) {
      throw new Error('Cannot delete rule for unknown origin');
    }

    if (!rulesByKey) {
      throw new Error('No rule to delete');
    }

    this.isLoading = true;

    try {
      await deleteRule(partnerId, currentOrigin.id, rule.id);

      runInAction(() => {
        const ruleIndex = rulesByKey.findIndex(({ id }) => id === rule.id);
        rulesByKey.splice(ruleIndex, 1);

        if (rulesByKey.length) {
          this.rules.set(ruleKey, rulesByKey);
        } else {
          this.rules.delete(ruleKey);
        }
      });

      flashMessageService.translatedSuccess('origin.rules.rule-updated-message');
    } catch (error: any) {
      // Axios errors are handled by http interceptor
      if (!error.request) {
        flashMessageService.error(error.message);
        throw error;
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}
