import { ApartmentOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Button, Badge, Alert } from 'antd';
import { format } from 'date-fns';
import React, { Fragment, FunctionComponent, useContext } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';

import styles from './PartnerStatusBar.module.css';
import PartnerStatusTag from '../PartnerStatusTag';

interface Props extends WrappedComponentProps {
  onLogHistoryClick: () => void;
  onChangesClick: () => void;
}

const PartnerStatusBar: FunctionComponent<Props> = ({
  intl,
  onChangesClick,
  onLogHistoryClick,
}) => {
  const {
    partnerStatusStore: { partnerGitStatus, isMergeRequestPending, isGitStatusLoaded },
  } = useContext(RootStoreContext);

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.lastUpdate}>
          {partnerGitStatus.latestCommitAuthor && partnerGitStatus.latestCommitWhen && (
            <Fragment>
              <span>
                <FormattedMessage
                  id="partner-status.update-message"
                  values={{
                    author: partnerGitStatus.latestCommitAuthor,
                    date: format(
                      new Date(partnerGitStatus.latestCommitWhen),
                      'MMMM d, yyyy hh:mm a'
                    ),
                  }}
                />
              </span>
              <PartnerStatusTag status={partnerGitStatus.status} />
            </Fragment>
          )}
        </div>

        <div>
          <Button
            type="link"
            icon={<ClockCircleOutlined />}
            className={styles.button}
            onClick={onLogHistoryClick}
          >
            {/* needed for Antd to show some separation between the icon and the text inside the button */}
            <span>
              <FormattedMessage id="partner-status.log-history" />
            </span>
          </Button>
          <Button
            type="link"
            icon={<ApartmentOutlined />}
            className={styles.button}
            onClick={onChangesClick}
          >
            {/* needed for Antd to show some separation between the icon and the text inside the button */}
            <span>
              <FormattedMessage id="partner-status.changes" />
            </span>
          </Button>
          <Badge count={partnerGitStatus.changesCount} />
        </div>
      </div>
      {isMergeRequestPending && isGitStatusLoaded && (
        <Alert
          message={intl.formatMessage({ id: 'partner-status.merge-request-pending-warning' })}
          type="warning"
          className={styles.notification}
          showIcon
        />
      )}
    </Fragment>
  );
};

export default injectIntl(PartnerStatusBar);
