import { FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { IntlShape } from 'react-intl';

import {
  validateStatementContentAndReturnErrors,
  ExpressionErrors,
} from 'modules/Content24/Condition/utils/validationUtils';
import { FlashMessageService, FlashMessageOptions } from 'types/types';

import { QuestionFormData } from '../QuestionForm';

/**
 * @notExported
 */
interface handleErrorsProps {
  formData: QuestionFormData;
  formikActions: FormikHelpers<QuestionFormData>;
  intl: IntlShape;
  flashMessageService: FlashMessageService<string, FlashMessageOptions>;
}

// async validation of question content
export const handleErrors = async ({
  formData,
  formikActions,
  intl,
  flashMessageService,
}: handleErrorsProps) => {
  const errors: ExpressionErrors = formData.content
    ? await validateStatementContentAndReturnErrors(formData.content)
    : {};

  const hasErrors = !isEmpty(errors);
  if (hasErrors) {
    Object.keys(errors).forEach(key => {
      const { translationKey, characters } = errors[key];
      formikActions.setFieldError(key, intl.formatMessage({ id: translationKey }, { characters }));
    });
    formikActions.setSubmitting(false);

    flashMessageService.translatedError('general.errors.errors-in-form');
  }
  return { hasErrors };
};
