import { LoadingOutlined } from '@ant-design/icons';
import { routes as routeUtils } from '@platform24/admin-ui-utils';
import { Layout, Spin } from 'antd';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { stringify } from 'query-string';
import React, { ContextType, Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';
import About from 'modules/About/About';
import { AccountDeletion } from 'modules/AccountDeletion';
import { InitAnalytics } from 'modules/Analytics/InitAnalytics';
import CarePathwaysOverviewPage from 'modules/CarePathways/CarePathwaysOverviewPage';
import CarePathwayTemplatePage from 'modules/CarePathways/CarePathwayTemplatePage';
import CreateCarePathwayTemplatePage from 'modules/CarePathways/CreateCarePathwayTemplatePage';
import CareUnits from 'modules/CareUnits';
import AddCareUnit from 'modules/CareUnits/AddCareUnit';
import CareProvidersList from 'modules/CareUnits/CareProvidersList/CareProvidersList';
import Content24 from 'modules/Content24';
import Home from 'modules/Home/Home';
import MainNavigation from 'modules/MainNavigation/MainNavigation';
import Origins from 'modules/Origins';
import AddOrigin from 'modules/Origins/AddOrigin';
import OriginsList from 'modules/Origins/OriginsList';
import Practitioner from 'modules/Practitioner/Practitioner';
import PractitionersList from 'modules/PractitionersList';
import Roles from 'modules/Roles';
import Rules from 'modules/Rules';
import { getValueOfFeatureFlag, isEducationEnv } from 'utils/appUtils';

import * as guards from './routeGuards';
import withRouteGuard from './withRouteGuard';
import styles from '../App.module.css';
import { CareProvider } from '../modules/CareProvider/CareProvider';

const CareUnitsWithGuard = withRouteGuard(guards.canActivateCareUnits)(CareUnits);
const AccountDeletionWithGuard = withRouteGuard(guards.canViewAccountDeletion)(AccountDeletion);
const AddCareUnitWithGuard = withRouteGuard(guards.canAddCareUnit)(AddCareUnit);
const CareProvidersListWithGuard = withRouteGuard(guards.canActivateCareUnits)(CareProvidersList);
const CareProviderWithGuard = withRouteGuard(guards.canActivateCareProviders)(CareProvider);

const OriginsWithGuard = withRouteGuard(guards.canActivateOrigins)(Origins);
const OriginsListWithGuard = withRouteGuard(guards.canActivateOrigins)(OriginsList);
const AddOriginWithGuard = withRouteGuard(guards.canAddOrigin)(AddOrigin);

const Content24WithGuard = withRouteGuard(guards.canActivateContent24)(Content24);
const PractitionerWithGuard = withRouteGuard(guards.canActivatePractitioners)(Practitioner);
const PractitionersListWithGuard = withRouteGuard(guards.canActivatePractitioners)(
  PractitionersList
);
const RolesWithGuard = withRouteGuard(guards.canActivatePractitioners)(Roles);
const RulesWithGuard = withRouteGuard(guards.canViewRules)(Rules);
const CarePathwaysOverviewWithGuard = withRouteGuard(guards.canViewCarePathways)(
  CarePathwaysOverviewPage
);
const CarePathwayTemplateWithGuard = withRouteGuard(guards.canViewCarePathways)(
  CarePathwayTemplatePage
);
const CreateCarePathwayTemplateWithGuard = withRouteGuard(guards.canViewCarePathways)(
  CreateCarePathwayTemplatePage
);

const { Content } = Layout;

const IS_EDUCATION_ENV = isEducationEnv();
const FF_ENABLE_NEW_ROLES = getValueOfFeatureFlag('FF_ENABLE_NEW_ROLES');

const routes = [
  { path: '/', component: Home, exact: true, visible: true },
  { path: '/about', component: About, visible: true },
  {
    path: '/practitioners',
    component: PractitionersListWithGuard,
    exact: true,
    visible: !FF_ENABLE_NEW_ROLES,
  },
  { path: '/practitioners/:id', component: PractitionerWithGuard, visible: !FF_ENABLE_NEW_ROLES },
  { path: '/roles', component: RolesWithGuard, visible: FF_ENABLE_NEW_ROLES },
  {
    path: '/care-providers/:careProviderId/care-units/add',
    component: AddCareUnitWithGuard,
    visible: !IS_EDUCATION_ENV,
  },
  {
    path: '/care-providers/:careProviderId/care-units/:careUnitId',
    component: CareUnitsWithGuard,
    visible: !IS_EDUCATION_ENV,
  },
  {
    path: '/patient/delete-account/:patientId',
    component: AccountDeletionWithGuard,
    visible: !IS_EDUCATION_ENV,
  },
  {
    path: '/care-providers/:careProviderId/care-units',
    component: CareProvidersListWithGuard,
    visible: !IS_EDUCATION_ENV,
  },
  {
    path: '/care-providers/:careProviderId',
    component: CareProviderWithGuard,
    visible: !IS_EDUCATION_ENV,
  },
  { path: '/care-providers', component: CareProvidersListWithGuard, visible: !IS_EDUCATION_ENV },
  {
    path: '/origins/:parentOriginId/add',
    component: AddOriginWithGuard,
    visible: !IS_EDUCATION_ENV,
  },
  { path: '/origins/:originId', component: OriginsWithGuard, visible: !IS_EDUCATION_ENV },
  { path: '/origins', component: OriginsListWithGuard, exact: true, visible: !IS_EDUCATION_ENV },
  {
    path: routeUtils.content24.base.path,
    component: Content24WithGuard,
    visible: !IS_EDUCATION_ENV,
  },
  { path: '/rules', component: RulesWithGuard, visible: !IS_EDUCATION_ENV },
  {
    path: routeUtils.pathways.overview.fullPath,
    exact: true,
    component: CarePathwaysOverviewWithGuard,
    visible: !IS_EDUCATION_ENV,
  },
  {
    path: routeUtils.pathways.editTemplate.fullPath,
    component: CarePathwayTemplateWithGuard,
    visible: !IS_EDUCATION_ENV,
  },
  {
    path: routeUtils.pathways.createTemplate.fullPath,
    component: CreateCarePathwayTemplateWithGuard,
    visible: !IS_EDUCATION_ENV,
  },
];

// Please note: just as it's name states, this component should only be used when we're sure
// that user is already authenticated (i.e. with PrivateRoute guard).

@observer
class PrivateRoutes extends Component {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;
  @observable isReady = false;

  async componentDidMount() {
    const { userDataStore, authStore, partnersStore, userPermissionsStore } = this.context;

    if (!partnersStore.partnerId) {
      authStore.logout({
        errorMessage: stringify({ messageKey: 'partners.errors.partner-not-found' }),
      });
    } else {
      userPermissionsStore.setUserRolesFromJWT();
      await Promise.all([
        userDataStore.fetchUser(),
        userPermissionsStore.fetchMyUserPermissions(),
        userPermissionsStore.fetchMyScopedRoles(),
        partnersStore.setCurrentPartner(),
      ]);

      if (userPermissionsStore.isAdmin) {
        await userDataStore.fetchUserCareUnits();
      }

      // A bit of a hacky way to solve the issue with concurrent downloads
      // setting isLoaded property of stores (here mainly userDataStore) back and forth,
      // which in turn results in whole routing below being mounted and unmounted.
      runInAction(() => {
        this.isReady = true;
      });
    }
  }

  render() {
    if (!this.isReady) {
      return (
        <div className={styles.spinnerContainer}>
          <Spin size="large" indicator={<LoadingOutlined spin />} />
        </div>
      );
    }

    return (
      <>
        <InitAnalytics />
        <Layout>
          <MainNavigation />
          <Layout className={styles.container}>
            <Content>
              <Switch>
                {routes
                  .filter(route => route.visible)
                  // We're removing visible property
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  .map(({ visible, ...props }, index) => (
                    <Route key={`${index}route`} {...props} />
                  ))}

                <Route path="*" render={() => <Redirect to="/" />} />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default PrivateRoutes;
