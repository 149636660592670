import { observable, action, computed, runInAction } from 'mobx';

import RootStore from 'stores/RootStore';

import {
  PartnerStatusDTO,
  fetchPartnerStatus,
  PARTNER_STATUS,
  COMMIT_STATUS,
  triggerPipeline,
  fetchPartnerGitStatus,
  PartnerGitStatus,
} from '../api/partnerStatusApi';

/**
 * @deprecated Use the Jotai atom inside src/state directory instead
 */
export default class PartnerStatusStore {
  @observable
  partnerStatus: PartnerStatusDTO | null = null;
  @observable
  isLoading = false;
  @observable
  isTriggeringPipeline = false;
  // The status property is used to block user activity in some parts of the app if the merge request
  // with the recent changes is pending. That's why it is set to true by default, until the real
  // state is fetched from the API
  @observable partnerGitStatus: PartnerGitStatus = {
    status: PARTNER_STATUS.PENDING,
    changesCount: 0,
    latestCommitAuthor: null,
    latestCommitWhen: null,
  };
  @observable
  isGitStatusLoaded = false;

  constructor(private rootStore: RootStore) {}

  // This is the light version, much faster than the "full" one, fetchPartnerStatus, used to get the details of changes
  // provided to partner Git repo. This one is used only to find out:
  // 1) If there is a merge request pending (to block user activity in some parts of the app)
  // 2) How many changes to the git config were made (to display the counter on the red badge)
  // 3) Who and when commited latest change (to display the yellow alert, e.g. in the Content24 condition header)
  @action
  async fetchPartnerGitStatus() {
    try {
      const { data } = await fetchPartnerGitStatus(this.rootStore.partnersStore.partnerId);
      const isChangedOrPending = [PARTNER_STATUS.CHANGES, PARTNER_STATUS.PENDING].includes(
        data.status
      );

      runInAction(() => {
        this.partnerGitStatus = {
          changesCount: data.changesCount,
          status: data.status,
          latestCommitAuthor:
            isChangedOrPending && data.latestCommitAuthor ? data.latestCommitAuthor : null,
          latestCommitWhen:
            isChangedOrPending && data.latestCommitWhen ? data.latestCommitWhen : null,
        };
        this.isGitStatusLoaded = true;
      });
      // This should fail silently
      // eslint-disable-next-line no-empty
    } catch {}
  }

  @action
  async fetchPartnerStatus() {
    try {
      // set loader only if there's no previously loaded data
      if (!this.partnerStatus) {
        this.isLoading = true;
      }

      const { data } = await fetchPartnerStatus(this.rootStore.partnersStore.partnerId);
      runInAction(() => {
        this.partnerStatus = data;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @computed
  get isMergeRequestPending() {
    return this.partnerGitStatus.status === PARTNER_STATUS.PENDING;
  }

  @computed
  get latestSuccessfullDeploymentDate() {
    return this.partnerStatus?.changes.find(({ status }) => status === COMMIT_STATUS.SUCCESS)?.when;
  }

  @computed
  get isLatestPipelinePending() {
    const status = this.partnerStatus?.changes[0]?.status;

    return (
      status &&
      [COMMIT_STATUS.CREATED, COMMIT_STATUS.PENDING, COMMIT_STATUS.RUNNING].includes(status)
    );
  }

  @action
  triggerPipeline = async () => {
    try {
      this.isTriggeringPipeline = true;

      await triggerPipeline(this.rootStore.partnersStore.partnerId);

      this.rootStore.flashMessageService.translatedSuccess(
        'partner-status.sent-to-test-environment'
      );
    } finally {
      runInAction(() => {
        this.isTriggeringPipeline = false;
      });
    }
  };
}
