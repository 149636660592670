import { TransformMatrix } from '@vx/zoom/lib/types';
import { action, computed, observable } from 'mobx';

import {
  CODE24_MODEL_TYPES,
  QUESTION_SHORT_RESPONSES,
  QUESTION_TYPES,
} from 'modules/Content24/Condition/constants/code24types';
import RootStore from 'stores/RootStore';

import { NodeDependency } from './ConditionVisualizationStore';
import {
  BASE_NODE_X,
  GUTTER,
  MARGIN,
  MAX_CHILDREN_IN_ROW,
  NODE_HEIGHT,
  NODE_WIDTH,
  SECTION,
} from '../constants';
import { getResponseNodeId, getLinkId } from '../utils';

export interface NodeType {
  x: number;
  y: number;
  width: number;
  height: number;
  childrenRowWidth?: number;
  childrenRows?: number;
  parentNodeId?: string;
  isLastInSection?: boolean;
  hasGotos?: boolean;
  data: {
    id: string;
    isNotConditionResponse?: boolean;
    isYesConditionResponse?: boolean;
    isDependencyOfSelectedNode?: boolean;
    isDimmed?: boolean;
    isSelected?: boolean;
    [key: string]: any;
  };
}

export interface LinkType {
  source: NodeType;
  target: NodeType;
  data: {
    id: string;
    isDimmed?: boolean;
    source?: string;
    [key: string]: any;
  };
}

export interface Section {
  x: number;
  y: number;
  width: number;
  height: number;
  isExternalSection: boolean;
  data: {
    id: any;
  };
}

export interface DiagramStoreConfig {
  conditionId?: string;
  columnWidth: number;
}

export default class DiagramStore {
  @observable selectedNode: NodeType | null = null;

  constructor(private rootStore: RootStore, private config: DiagramStoreConfig) {}

  @computed
  get diagramHeight() {
    return this.coordinates.bottom + MARGIN.bottom + MARGIN.top;
  }

  @computed
  get diagramWidth() {
    const { left, right } = this.coordinates;
    const pixelsBetweenLeftAndRightEnd = left < 0 ? right - left : right + left;

    return MARGIN.left + pixelsBetweenLeftAndRightEnd + MARGIN.right;
  }

  @computed
  get coordinates() {
    let rightEnd = 0;
    let leftEnd = 0;

    this.sections.forEach(section => {
      if (section.x >= 0) {
        if (section.x + section.width > rightEnd) {
          rightEnd = section.x + section.width;
        }
      } else {
        if (section.x < leftEnd) {
          leftEnd = section.x;
        }
      }
    });

    const lastSection = this.sections[this.sections.length - 1];

    return {
      left: leftEnd,
      right: rightEnd,
      top: 0,
      bottom: lastSection.y + lastSection.height,
    };
  }

  @computed
  get questionsArray() {
    return this.buildQuestionsNodes();
  }

  @computed
  get exitsArray() {
    return this.buildExits(this.questionsArray);
  }

  @computed
  get linksArray() {
    return this.buildLinks(this.questionsArray);
  }

  @computed
  get nodes(): NodeType[] {
    let nodes = [...this.questionsArray, ...this.exitsArray];

    if (this.selectedNode) {
      nodes = this.highlightSelectedNodeDependencies(nodes);
    }

    return nodes;
  }

  @computed
  get links(): LinkType[] {
    if (this.selectedNode) {
      return this.filterLinksToSelectedDependencies(this.questionsArray, this.linksArray);
    }

    return this.linksArray;
  }

  @computed
  get sections(): Section[] {
    return this.buildSectionsArr(this.questionsArray);
  }

  @computed
  get externalSections() {
    return this.sections.filter(({ isExternalSection }) => isExternalSection);
  }

  @computed
  get transformMatrix(): TransformMatrix {
    const transformMatrix = {
      scaleX: 0.5,
      scaleY: 0.5,
      translateX: this.config.columnWidth / 2,
      translateY: MARGIN.top,
      skewX: 0,
      skewY: 0,
    };

    if (this.externalSections.length > 0) {
      // center the screen on the section that is appearing first. Condition's own section takes
      // the first half of the diagram, while external sections are stacked on the second half
      transformMatrix.translateX = this.externalSections[0].isExternalSection ? 0.75 : 0.25;
    }

    return transformMatrix;
  }

  buildQuestionsNodes(): NodeType[] {
    const {
      conditionVisualizationStore: { questionsAndGotos },
    } = this.rootStore;
    const nodesArr: NodeType[] = [];

    for (let i = 0; i < questionsAndGotos.length; i++) {
      const question = questionsAndGotos[i];
      const nextQuestion = questionsAndGotos[i + 1];
      const isFromExternalSource = question.source !== this.config.conditionId;
      const isYesNoMaybeQuestion =
        question.questionType &&
        [QUESTION_TYPES.YESNOMAYBE, QUESTION_TYPES.YESNO].includes(question.questionType);
      const hasChildren = question.children.length > 0 || isYesNoMaybeQuestion;
      const hasGotos = question.gotos && question.gotos.length > 0;
      const lastNode = nodesArr[nodesArr.length - 1];
      const totalNodeHeight = NODE_HEIGHT + GUTTER.y;
      let childWidth = 0;
      let childrenRowWidth = 0;
      let childrenInRow = 0;
      let childrenRows = 0;

      if (hasChildren) {
        const {
          children: { length: childrenLength },
        } = question;

        if (isYesNoMaybeQuestion) {
          childrenInRow = question.questionType === QUESTION_TYPES.YESNO ? 2 : 3;
          childWidth = this.config.columnWidth / childrenInRow - GUTTER.x;
          childrenRowWidth = (childWidth + GUTTER.x) * childrenInRow - GUTTER.x / 2;
          childrenRows = 1;
        } else {
          childrenInRow =
            childrenLength < MAX_CHILDREN_IN_ROW ? childrenLength : MAX_CHILDREN_IN_ROW;
          childWidth =
            childrenLength === 1 ? NODE_WIDTH : this.config.columnWidth / childrenInRow - GUTTER.x;
          childrenRowWidth =
            childrenLength === 1
              ? NODE_WIDTH
              : (childWidth + GUTTER.x) * childrenInRow - GUTTER.x / 2;
          childrenRows =
            childrenLength % childrenInRow === 0
              ? childrenLength / childrenInRow
              : Math.floor(childrenLength / childrenInRow) + 1;
        }
      }

      let baseNodeY = 0;

      if (i === 0) {
        isFromExternalSource
          ? (baseNodeY = MARGIN.top + SECTION.header.height + SECTION.bodyPadding.top)
          : (baseNodeY = MARGIN.top);
      } else {
        baseNodeY = lastNode.y + totalNodeHeight;
      }

      const questionNode = {
        x: isFromExternalSource ? BASE_NODE_X + this.config.columnWidth + MARGIN.left : BASE_NODE_X,
        y:
          i !== 0 && isFromExternalSource && question.source !== lastNode.data.source
            ? baseNodeY + SECTION.header.height + SECTION.bodyPadding.top
            : baseNodeY,
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
        data: { ...question },
        childrenRowWidth,
        childrenRows,
        hasGotos,
        isLastInSection:
          i === questionsAndGotos.length - 1 || question.source !== nextQuestion.source,
      };

      nodesArr.push(questionNode);

      if (hasChildren) {
        question.children.forEach((child, childIndex, allChildren) => {
          const totalChildWidth = childWidth + GUTTER.x;
          let childXPosition =
            allChildren.length === 1 ? 0 : (this.config.columnWidth - childrenRowWidth) / 2;
          let childYPosition = questionNode.y + totalNodeHeight;

          if (childIndex % childrenInRow === 0) {
            childYPosition = childYPosition + totalNodeHeight * (childIndex / childrenInRow);
          } else {
            childXPosition =
              childXPosition +
              totalChildWidth *
                (childIndex < childrenInRow ? childIndex : this.getChildPositionInRow(childIndex));
            childYPosition =
              childYPosition + totalNodeHeight * Math.floor(childIndex / childrenInRow);
          }

          const x = isFromExternalSource ? childXPosition + questionNode.x : childXPosition;
          const y = childYPosition;

          nodesArr.push({
            x,
            y,
            width: childWidth,
            height: NODE_HEIGHT,
            parentNodeId: question.id,
            data: { ...child },
          });
        });
      }

      if (hasGotos && question.gotos) {
        const baseYPosition = questionNode.y + SECTION.header.height + SECTION.bodyPadding.top;

        const xPosition = isFromExternalSource
          ? questionNode.x + this.config.columnWidth + MARGIN.left
          : questionNode.x - this.config.columnWidth - MARGIN.left;

        question.gotos.forEach((goto, gotoIndex) => {
          nodesArr.push({
            x: xPosition,
            y: baseYPosition + (NODE_HEIGHT + GUTTER.y) * (gotoIndex + 1),
            width: NODE_WIDTH,
            height: NODE_HEIGHT,
            data: { ...goto },
          });
        });
      }
    }

    return nodesArr;
  }

  adjustSectionDimensionsForChildren = (
    section: Section,
    currentNode: NodeType,
    parentNode?: NodeType
  ) => {
    const updatedSection = { ...section };

    // if it's the first child of a question, calculate children-based dimensions and add them to the latest section
    if (
      parentNode &&
      parentNode.data.type === CODE24_MODEL_TYPES.QUESTION &&
      parentNode.data.children[0].id === currentNode.data.id
    ) {
      const sectionTotalWidth =
        (parentNode.childrenRowWidth ? parentNode.childrenRowWidth : currentNode.width) +
        SECTION.bodyPadding.left +
        SECTION.bodyPadding.right;

      updatedSection.height += (currentNode.height + GUTTER.y) * (parentNode.childrenRows || 0);
      updatedSection.width = sectionTotalWidth;
      updatedSection.x = currentNode.x - sectionTotalWidth / 2;
    }

    return updatedSection;
  };

  buildSectionsArr = (nodesArr: NodeType[]) => {
    const sectionsArr = nodesArr.reduce<Section[]>((accumulator, node) => {
      const lastSection = accumulator[accumulator.length - 1];

      if (accumulator.length > 0 && this.isChildNode(node)) {
        const parentNode = nodesArr.find(elem => elem.data.id === node.parentNodeId);
        const adjustedLastSection = this.adjustSectionDimensionsForChildren(
          lastSection,
          node,
          parentNode
        );

        accumulator.splice(accumulator.length - 1, 1, adjustedLastSection);

        return accumulator;
      }

      if (accumulator.length > 0 && lastSection.data.id === node.data.source) {
        lastSection.height += node.height + GUTTER.y;
        return accumulator;
      }

      const isExternalSection = node.data.source !== this.config.conditionId;

      const sectionWidth =
        (node.childrenRowWidth ? node.childrenRowWidth : node.width) +
        SECTION.bodyPadding.left +
        SECTION.bodyPadding.right;
      const sectionHeight =
        node.height + SECTION.bodyPadding.top + SECTION.bodyPadding.bottom + SECTION.header.height;

      const section = {
        x: node.x - sectionWidth / 2,
        y: node.y - node.height / 2 - SECTION.header.height - SECTION.bodyPadding.top,
        width: sectionWidth,
        height: sectionHeight,
        isExternalSection,
        data: {
          id: node.data.source,
        },
      };

      return [...accumulator, section];
    }, []);

    return sectionsArr;
  };

  getNodeChildren = (nodes: NodeType[], parentNodeId: string, startIndex: number) => {
    const children: NodeType[] = [];
    let index = startIndex;
    let currentNode = nodes[index];

    while (
      index < nodes.length &&
      currentNode.parentNodeId &&
      currentNode.parentNodeId === parentNodeId
    ) {
      children.push(currentNode);
      currentNode = nodes[index + 1];
      index++;
    }

    return children;
  };

  buildLinks = (nodes: NodeType[]) =>
    nodes.reduce((accumulator: LinkType[], node: NodeType, i: number) => {
      if (node.data.type !== CODE24_MODEL_TYPES.QUESTION) {
        return accumulator;
      }

      const nextNode = nodes[i + 1];
      const hasChildren = node.childrenRowWidth && node.childrenRowWidth > 0;

      if (hasChildren) {
        const childNodes = this.getNodeChildren(nodes, node.data.id, i + 1);
        const childrenLinks = childNodes.map(child => ({
          source: node,
          target: child,
          data: { id: getLinkId(node.data.id, child.data.id), isDimmed: false },
        }));

        const newLinks = [...accumulator, ...childrenLinks];

        const nextQuestion = nodes
          .slice(i + 1)
          .find(n => n.data.type === CODE24_MODEL_TYPES.QUESTION);
        if (nextQuestion) {
          newLinks.push({
            source: node,
            target: nextQuestion,
            data: { id: getLinkId(node.data.id, nextQuestion.data.id), isDimmed: false },
          });
        }

        const nextGoto = node.hasGotos
          ? nodes.slice(i + 1).find(n => n.data.type === CODE24_MODEL_TYPES.GOTO)
          : undefined;

        if (nextGoto) {
          newLinks.push({
            source: node,
            target: nextGoto,
            data: { id: getLinkId(node.data.id, nextGoto.data.id), isDimmed: false },
          });
        }

        return newLinks;
      } else if (nextNode && nextNode.data.type === CODE24_MODEL_TYPES.QUESTION) {
        return [
          ...accumulator,
          {
            source: node,
            target: nextNode,
            data: { id: getLinkId(node.data.id, nextNode.data.id), isDimmed: false },
          },
        ];
      } else if (nextNode && nextNode.data.type === CODE24_MODEL_TYPES.GOTO) {
        const gotoLink = {
          source: node,
          target: nextNode,
          data: { id: getLinkId(node.data.id, nextNode.data.id), isDimmed: false },
        };

        const newLinks = [...accumulator, gotoLink];

        const nextQuestion = nodes
          .slice(i + 1)
          .find(n => n.data.type === CODE24_MODEL_TYPES.QUESTION);
        if (nextQuestion) {
          newLinks.push({
            source: node,
            target: nextQuestion,
            data: { id: getLinkId(node.data.id, nextQuestion.data.id), isDimmed: false },
          });
        }

        return newLinks;
      }

      return accumulator;
    }, [] as LinkType[]);

  buildExits = (nodes: NodeType[]): NodeType[] => {
    const lastNode = nodes[nodes.length - 1];
    let baseY = MARGIN.top;

    if (lastNode) {
      baseY = baseY + lastNode.y + NODE_HEIGHT * 3;
    }

    return this.rootStore.conditionVisualizationStore.exits.map((exit, i) => {
      return {
        x: i % 2 === 0 ? BASE_NODE_X : BASE_NODE_X + NODE_WIDTH + GUTTER.x,
        y: baseY + (NODE_HEIGHT + GUTTER.y) * Math.floor(i / 2),
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
        data: { ...exit },
      };
    });
  };

  @action
  setSelectedNode = (node: NodeType) => {
    this.selectedNode = node;
  };

  @action
  clearSelectedNode = () => {
    this.selectedNode = null;
  };

  filterLinksToSelectedDependencies = (nodes: NodeType[], links: LinkType[]) => {
    if (
      !this.selectedNode ||
      !this.selectedNode.data.dependencies ||
      this.selectedNode.data.dependencies.length < 1
    ) {
      return links;
    }

    const dependencyNodes: NodeType[] = this.getDependencyResponses(nodes, this.selectedNode);

    const linksBetweenDependenciesAndParents: string[] = [];

    nodes.forEach(node => {
      const isDependencyNode = dependencyNodes.some(depNode => depNode.data.id === node.data.id);
      if (isDependencyNode) {
        const linkConnectingToParent = links.find(
          l => l.data.id === getLinkId(node.parentNodeId!, node.data.id)
        );
        linkConnectingToParent &&
          linksBetweenDependenciesAndParents.push(linkConnectingToParent.data.id);
      }
    });

    let newLinks = links.map(link => ({
      ...link,
      data: {
        ...link.data,
        isDimmed: !linksBetweenDependenciesAndParents.includes(link.data.id),
      },
    }));

    if (this.selectedNode && this.selectedNode.data.type !== CODE24_MODEL_TYPES.EXIT) {
      const dependencyQuestions: NodeType[] = this.getDependencyQuestions(nodes);
      newLinks = newLinks.concat(
        [...dependencyNodes, ...dependencyQuestions].map(depNode => ({
          source: this.selectedNode!,
          target: depNode,
          data: { id: getLinkId(this.selectedNode!.data.id, depNode.data.id), isDimmed: false },
        }))
      );
    }
    return this.nodes.some(node => node.data.isDimmed) ? newLinks : links;
  };

  highlightSelectedNodeDependencies = (nodes: NodeType[]): NodeType[] => {
    if (
      !this.selectedNode ||
      !this.selectedNode.data.dependencies ||
      this.selectedNode.data.dependencies.length < 1
    ) {
      return nodes;
    }

    const dependencyResponses: NodeType[] = this.getDependencyResponses(nodes, this.selectedNode);
    if (dependencyResponses.length < 1) {
      const exitQuestionId = this.selectedNode.data.exitModel?.exitId;
      const newNodes = nodes.map(node => {
        const questionId = node.data.questionId || '';
        const isDependency = this.isDependencyQuestion(node.data.questionId);

        const isHighlighted =
          questionId === exitQuestionId ||
          questionId === this.selectedNode?.data.questionId ||
          isDependency;

        return {
          ...node,
          data: {
            ...node.data,
            isDimmed: !isHighlighted,
          },
        };
      });
      return newNodes.some(node => !node.data.isDimmed) ? newNodes : nodes;
    }

    const newNodes = nodes.map(node => {
      const nodeId = node.data.id;
      const isSelectedNode = nodeId === this.selectedNode!.data.id;
      const foundDependencyResponses = dependencyResponses.filter(
        depNode => depNode.data.id === nodeId
      );
      const isDependencyResponse = foundDependencyResponses.length > 0;
      const isNotConditionResponse =
        foundDependencyResponses.length > 0 && foundDependencyResponses.some(resp => resp.data.not);
      const isYesConditionResponse =
        foundDependencyResponses.length > 0 &&
        foundDependencyResponses.some(resp => !resp.data.not);
      const isParentOfDependencyResponse = dependencyResponses.some(
        depNode => nodeId === depNode.parentNodeId
      );
      const isHighlighted = isSelectedNode || isDependencyResponse || isParentOfDependencyResponse;

      return {
        ...node,
        data: {
          ...node.data,
          isDependencyOfSelectedNode: isDependencyResponse,
          isSelected: isSelectedNode,
          isDimmed: !isHighlighted,
          isNotConditionResponse,
          isYesConditionResponse,
        },
      };
    });

    return newNodes;
  };

  getChildPositionInRow = (index: number, childrenInRow: number = MAX_CHILDREN_IN_ROW) => {
    const rowNumber = Math.floor(index / childrenInRow);
    return index - childrenInRow * rowNumber;
  };

  getChildXPositionForLink = (currentNode: NodeType) => {
    const found = this.nodes.find(n => n.data.id === currentNode.parentNodeId);

    if (found && found.childrenRowWidth && found.data.children.length > 1) {
      return currentNode.x - found.childrenRowWidth / 2 + currentNode.width / 2;
    }

    return currentNode.x;
  };

  getDependencyResponses = (nodes: NodeType[], selectedNode: NodeType): NodeType[] => {
    return selectedNode.data.dependencies.reduce((accumulator: NodeType[], dep: NodeDependency) => {
      const foundDepResponse = nodes.find(
        node => node.data.id === getResponseNodeId(dep.questionId, dep.responseId)
      );

      return foundDepResponse
        ? [
            ...accumulator,
            {
              ...foundDepResponse,
              data: {
                ...foundDepResponse.data,
                not: dep.not,
              },
            },
          ]
        : accumulator;
    }, [] as NodeType[]);
  };

  isDependencyQuestion = (questionId: string) =>
    this.selectedNode?.data.dependencies?.some(
      (el: { questionId: any }) => el.questionId === questionId
    );

  getDependencyQuestions = (nodes: NodeType[]): NodeType[] =>
    nodes.filter(node => this.isDependencyQuestion(node.data.questionId));

  isChildNode = (node: NodeType) => {
    const questionShortResponsesArray = Object.values(QUESTION_SHORT_RESPONSES) as string[];
    const childrenNodeTypes = questionShortResponsesArray.concat(CODE24_MODEL_TYPES.RESPONSE);
    return childrenNodeTypes.includes(node.data.type);
  };
}
