import React from 'react';

export interface SelfMountedRenderProps<T> {
  props: T;
  children: any;
  Fallback: any;
  FailedFallback?: any;
  bypassError: (err: unknown) => void;
}

export interface SelfMountedInterface<T> {
  __selfMounted: true;
  render: (data: SelfMountedRenderProps<T>) => void;
  unmount: () => void;
}

export interface SelfMountedConstructor<T> {
  __selfMountedConstructor: true;
  create: (container: HTMLDivElement) => SelfMountedInterface<T>;
}

export const isSelfMounted = <T>(c: unknown): c is SelfMountedInterface<T> => {
  // we want to check value for be exactly true, so silence eslint
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
  return typeof c === 'object' && (c as SelfMountedInterface<T>)?.__selfMounted === true;
};

export const isSelfMountedConstructor = <T>(c: unknown): c is SelfMountedConstructor<T> => {
  // we want to check value for be exactly true, so silence eslint
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
  return (
    typeof c === 'object' && (c as SelfMountedConstructor<T>)?.__selfMountedConstructor === true
  );
};

export type CreateRootShape = (container: HTMLDivElement) => {
  render: (app: any) => void;
  unmount: () => void;
};

export type ComponentOrSelfMounted = React.ComponentType<unknown> | SelfMountedInterface<unknown>;
