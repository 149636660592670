import { init as initApm } from '@elastic/apm-rum';

import { getValueOfFeatureFlag } from './appUtils';
import { getToken } from './tokenUtils';

const apm = initApm({
  active: getValueOfFeatureFlag('FF_ENABLE_APM_IN_ADMIN_UI'),
  serviceName: 'admin-ui-bananas',

  apiVersion: 3,

  serverUrl: '/rest',
  serverUrlPrefix: '/elastic-apm-rum-v3',

  serviceVersion: '0.1.0',

  apmRequest: ({ xhr }) => {
    const token = getToken();

    if (!token) {
      return false;
    }

    xhr.setRequestHeader('Authorization', `Bearer ${token}`);

    return true;
  },
});

(window as any).__apm = apm;
