import { Modal, Divider, Tooltip } from 'antd';
import { Formik } from 'formik';
import { Form, Select, Checkbox } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import RootStoreContext from 'context/RootStoreContext';
import { RoleInCareUnit } from 'modules/Practitioner/stores/PractitionerRolesStore';
import { InputOption } from 'types/types';
import { filterSelectOptionV2 } from 'utils/formUtils';

import styles from './AddPractitionerRoles.module.css';
import { PractitionerRoleDefinition } from '../../PractitionerRoles';

interface Props {
  initialValues?: PractitionerRoleDefinition;
  isSaving: boolean;
  showRoleLock: boolean;
  onCancel: () => void;
  onSubmit: (data: PractitionerRoleDefinition) => void;
  careUnitsOptions: InputOption[];
  rolesOptions: InputOption[];
  notAvailableCareUnitRoles: RoleInCareUnit[];
}

interface ValueLabel {
  value: string;
  label: string;
}

type FilterFunc<T> = (inputValue: string, option?: T) => boolean;
type FilterOptionType = FilterFunc<Record<string, unknown>[][number]>;

const AddPractitionerRoles: FunctionComponent<Props> = ({
  isSaving,
  onCancel,
  initialValues,
  onSubmit,
  careUnitsOptions,
  rolesOptions,
  notAvailableCareUnitRoles,
  showRoleLock,
}) => {
  const intl = useIntl();
  const { rolesStore, practitionerRolesStore } = useContext(RootStoreContext);

  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });
  const validationSchema = Yup.object().shape({
    careUnitIds: Yup.array().of(Yup.string()).required(requiredErrorMessage),
    role: Yup.string().required(requiredErrorMessage),
  });

  if (!initialValues) {
    return null;
  }

  return (
    <Observer>
      {() => {
        const isAdministrativeRole =
          initialValues &&
          initialValues.role &&
          rolesStore.administrativeRoles.includes(initialValues?.role);
        const mappedExtendedRole = isAdministrativeRole
          ? practitionerRolesStore.extendedAdministrativeRolesMap
          : practitionerRolesStore.extendedPractitionerRolesMap;

        return (
          <Modal
            open
            destroyOnClose
            title={
              <span data-testid="add-role-title">
                {isAdministrativeRole
                  ? `
                  ${intl.formatMessage({
                    id: 'add-roles-form.add-btn',
                  })} - ${intl.formatMessage({
                      id: 'practitioner-roles-form.administrative-roles-header',
                    })}`
                  : `
                  ${intl.formatMessage({
                    id: 'add-roles-form.add-btn',
                  })} - 
                  ${intl.formatMessage({
                    id: 'practitioner-roles-form.practitioner-roles-header',
                  })}`}
              </span>
            }
            footer={null}
            closable={false}
          >
            <Formik
              initialValues={
                {
                  careUnitIds: [],
                  role: undefined,
                } as PractitionerRoleDefinition
              }
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isValid, dirty, values }) => {
                return (
                  <Form layout="vertical">
                    <Form.Item
                      name="careUnitIds"
                      label={<FormattedMessage id="general.care-unit" />}
                      required
                    >
                      <Select
                        name="careUnitIds"
                        disabled={isSaving}
                        mode="multiple"
                        data-testid="careunit-select-input"
                        optionFilterProp="children"
                        showSearch
                        filterOption={filterSelectOptionV2 as FilterOptionType}
                      >
                        {careUnitsOptions.map(({ value, label }: ValueLabel, index) => {
                          const disabled = isAdministrativeRole
                            ? mappedExtendedRole[`${values.role}:${value}`]
                            : mappedExtendedRole[value];

                          const picked = notAvailableCareUnitRoles.some(
                            ({ role, careUnitId }) => role === values.role && careUnitId === value
                          );

                          return (
                            <Select.Option
                              disabled={disabled || picked}
                              key={`${label}-${index}`}
                              value={value}
                            >
                              {disabled || picked ? (
                                <Tooltip
                                  overlayClassName={styles.tooltipFix}
                                  title={<FormattedMessage id="roles.role-already-assigned" />}
                                >
                                  <div>{label}</div>
                                </Tooltip>
                              ) : (
                                label
                              )}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="role"
                      label={<FormattedMessage id="practitioner-roles-form.role-label" />}
                      required
                    >
                      <Select
                        name="role"
                        disabled={isSaving}
                        optionFilterProp="children"
                        data-testid="role-select-input"
                        showSearch
                      >
                        {rolesOptions.map(({ value, label }: ValueLabel, index) => {
                          const picked = notAvailableCareUnitRoles.some(
                            ({ role, careUnitId }) =>
                              role === value && values.careUnitIds.includes(careUnitId)
                          );

                          const disabled = values.careUnitIds.some(careUnitId =>
                            isAdministrativeRole
                              ? mappedExtendedRole[`${value}:${careUnitId}`]
                              : mappedExtendedRole[careUnitId]
                          );

                          return (
                            <Select.Option
                              disabled={disabled || picked}
                              key={`${label}-${index}`}
                              value={value}
                            >
                              {disabled || picked ? (
                                <Tooltip
                                  overlayClassName={styles.tooltipFix}
                                  title={<FormattedMessage id="roles.role-already-assigned" />}
                                >
                                  <div>{label}</div>
                                </Tooltip>
                              ) : (
                                label
                              )}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Divider />
                    {showRoleLock && (
                      <Form.Item
                        name="lockedFromAutoManagement"
                        data-testid="lockedFromAutoManagement"
                        label={<FormattedMessage id="practitioner-roles-form.exemption-label" />}
                      >
                        <Checkbox name="lockedFromAutoManagement" />
                      </Form.Item>
                    )}
                    <FormActionButtons
                      isSaving={isSaving}
                      isValid={isValid && dirty}
                      onCancel={onCancel}
                      showCancelConfirm={dirty}
                    />
                  </Form>
                );
              }}
            </Formik>
          </Modal>
        );
      }}
    </Observer>
  );
};

export default React.memo(AddPractitionerRoles);
