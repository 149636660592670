import { PictureOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { EditorState } from 'draft-js';
import React, { useState, FunctionComponent } from 'react';

interface AddImageControlProps {
  editorState: EditorState;
  onClick: (imageEntityKey: string) => void;
}

export const AddImageControl: FunctionComponent<AddImageControlProps> = ({
  onClick,
  editorState,
}) => {
  const [imageEntityKey, setImageEntityKey] = useState<string>();

  React.useEffect(() => {
    const selection = editorState.getSelection();
    const isCollapsed = selection.isCollapsed();

    if (isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const block = currentContent.getBlockForKey(selection.getStartKey());
      const selectionStart = selection.getStartOffset();
      const entityKey = block.getEntityAt(selectionStart);

      if (entityKey) {
        const entity = currentContent.getEntity(entityKey);

        if (entity.getType() === 'IMAGE') {
          setImageEntityKey(entityKey);
          return;
        } else {
          setImageEntityKey(undefined);
          return;
        }
      }

      setImageEntityKey('');
      return;
    }

    setImageEntityKey(undefined);
  }, [editorState, setImageEntityKey]);

  const handleClick = () => {
    // Text cursor (selection) is on existing image
    if (imageEntityKey) {
      onClick(imageEntityKey);
      // We're creating a new image - please note that this won't be present in editor state
      // as we're not updating that state. In fact, we only want to create a valid entity key.
    } else {
      const currentContentState = editorState.getCurrentContent();
      const currentContentStateWithEntity = currentContentState.createEntity('IMAGE', 'MUTABLE', {
        src: '',
        alt: '',
      });
      const entityKey = currentContentStateWithEntity.getLastCreatedEntityKey();
      onClick(entityKey);
    }
    setImageEntityKey(undefined);
  };

  return (
    <Button
      onMouseDown={handleClick}
      icon={<PictureOutlined />}
      disabled={imageEntityKey === undefined}
    />
  );
};
