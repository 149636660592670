import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { PartnerImage, PARTNERS_URI } from 'api/partnersApi';
import {
  getPartnerImagesQueryConfig,
  partnersQueryKeys,
  usePartnerImages,
} from 'api/partnersApi.hooks';
import { LANGS } from 'constants/enums';
import { RICH_TEXT_CONTENT_TYPES } from 'constants/text';

import { useArticlesApi } from './articlesApi';
import { articlesQueryKeys } from './queryKeys';
import { ARTICLE_THUMBNAIL_FILE_PREFIX } from '../constants';
import { Article, ArticleDraft } from '../types/article';

export const useFullArticle = ({
  partnerId,
  articleId,
}: {
  partnerId?: string | null;
  articleId: string;
}) => {
  const articlesApi = useArticlesApi();

  return useQuery({
    queryKey: articlesQueryKeys.getFullArticle({
      partnerId,
      id: articleId,
      type: 'selfcare',
    }),
    queryFn: async () => {
      if (!articlesApi) {
        throw new Error('articlesApi is not initialized');
      }
      if (!partnerId) {
        throw new Error('Missing partnerId');
      }

      return articlesApi
        .get('/articles/v1/:type/:articleId', {
          params: {
            type: 'selfcare',
            articleId,
          },
        })
        .then(response => ({ ...response, id: articleId }));
    },
    enabled: !!partnerId && !!articlesApi,
    refetchOnMount: false,
  });
};

// This is not good, and should ideally be done by the BE.
const getNewArticleThumbnailImageName = ({
  articleId,
  partnerImageNames,
}: {
  articleId: string;
  partnerImageNames: string[];
}) => {
  const partnerImageNamesSet = new Set(partnerImageNames);
  let newImageName = `${ARTICLE_THUMBNAIL_FILE_PREFIX}${articleId}`;

  let i = 1;
  while (partnerImageNamesSet.has(newImageName)) {
    newImageName = `${ARTICLE_THUMBNAIL_FILE_PREFIX}${articleId}${i}`;
    i++;
  }

  return newImageName;
};

const getArticleThumbnailMetadata = (articleDraft: ArticleDraft) => {
  const languageMetadataMap = Object.entries(articleDraft.metadata.title).reduce(
    (acc, [lang, value]) => ({
      ...acc,
      [lang]: `'${value}' article thumbnail image`,
    }),
    {}
  );
  const imageMetadata = {
    title: languageMetadataMap,
    description: languageMetadataMap,
  };

  const languageMetadataRows = Object.entries(languageMetadataMap)
    .map(([lang, value]) => `  ${lang}: "${value}"`)
    .join('\n');
  const imageYamlMetadata =
    'title:\n' + languageMetadataRows + '\n' + 'description:\n' + languageMetadataRows + '\n';

  return {
    obj: imageMetadata,
    yaml: imageYamlMetadata,
  };
};

const uploadArticleThumbnailImage = async ({
  partnerId,
  articleDraft,
  imageName,
}: {
  partnerId: string;
  articleDraft: ArticleDraft;
  imageName: string;
}) => {
  const {
    metadata: { thumbnailBlobPath },
  } = articleDraft;
  if (!thumbnailBlobPath) {
    throw new Error('Missing thumbnailBlobPath');
  }

  const imageBlob = await fetch(thumbnailBlobPath).then(r => r.blob());
  const imageExtension = imageBlob.type.split('/')[1];
  const imageFileName = `${imageName}.${imageExtension}`;

  const imageFile = new File([imageBlob], imageFileName, {
    type: imageBlob.type,
  });
  const metadata = getArticleThumbnailMetadata(articleDraft);

  const formData = new FormData();
  formData.append('image', imageFile, imageFileName);
  formData.append('metadata', metadata.yaml);

  // We should use the new /articles/images endpoint once the parsing of
  // multipart form data is fixed in the BE.
  // https://platform24.atlassian.net/browse/AX-48353
  await axios.post(`${PARTNERS_URI}/${partnerId}/medicalImages`, formData);

  return {
    name: imageName,
    fileName: imageFileName,
    blob: imageBlob,
    metadata: metadata.obj,
  };
};

const useUploadArticleThumbnailImage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      partnerId,
      articleDraft,
      partnerImageNames,
    }: {
      partnerId: string;
      articleDraft: ArticleDraft;
      partnerImageNames: string[];
    }) => {
      // This is completely suboptimal, and should be removed once the backend handles
      // this name duplication checking
      const imageName = getNewArticleThumbnailImageName({
        articleId: articleDraft.id,
        partnerImageNames,
      });

      const newThumbnail = await uploadArticleThumbnailImage({
        partnerId,
        articleDraft,
        imageName,
      });

      return newThumbnail;
    },
    onSuccess: (newThumbnail, { partnerId }) => {
      queryClient.setQueryData<Blob>(
        partnersQueryKeys.imageData(partnerId, newThumbnail.fileName),
        newThumbnail.blob
      );

      queryClient.setQueryData<PartnerImage[]>(partnersQueryKeys.images(partnerId), images => [
        ...(images || []),
        {
          fileName: newThumbnail.fileName,
          source: partnerId,
          metadata: newThumbnail.metadata,
        },
      ]);
    },
  });
};

export const useUpdateFullArticle = (partnerId: string) => {
  const articlesApi = useArticlesApi();
  const queryClient = useQueryClient();
  const uploadArticleThumbnailImage = useUploadArticleThumbnailImage();

  // Just preemptively triggering the fetching of partner images
  // this will be used in the mutation to check if the new thumbnail name is unique
  usePartnerImages(partnerId);

  return useMutation({
    mutationFn: async (draft: ArticleDraft) => {
      if (!articlesApi) {
        throw new Error('articlesApi is not initialized');
      }

      const { id, metadata: draftMetadataWithThumbnailBlob, texts } = draft;
      const { thumbnailBlobPath, ...updatedMetadata } = draftMetadataWithThumbnailBlob;

      // If the thumbnailBlobPath is present, but the image id (thumbnail) is not,
      // it means that the user has uploaded a new image in the browser, but it
      // has not been uploaded to the backend yet.
      const imageNeedsToBeUploaded = thumbnailBlobPath && !updatedMetadata.thumbnail;
      if (imageNeedsToBeUploaded) {
        const partnerImageNames = await queryClient
          .ensureQueryData(getPartnerImagesQueryConfig(partnerId))
          .then(images => images.map(image => image.fileName.split('.')[0]));

        const newThumbnail = await uploadArticleThumbnailImage.mutateAsync({
          partnerId,
          articleDraft: draft,
          partnerImageNames,
        });

        updatedMetadata.thumbnail = newThumbnail.name;
      }

      await articlesApi.put(
        '/articles/v1/:type/:articleId',
        { metadata: updatedMetadata, texts },
        {
          params: {
            type: 'selfcare',
            articleId: id,
          },
        }
      );

      return {
        updatedArticle: {
          ...draft,
          metadata: updatedMetadata,
        } as Article,
      };
    },
    onSuccess: ({ updatedArticle }) => {
      queryClient.setQueryData<Article>(
        articlesQueryKeys.getFullArticle({
          partnerId,
          id: updatedArticle.id,
          type: 'selfcare',
        }),
        updatedArticle
      );
    },
    retry: 2,
  });
};

enum SELF_CARE_STATUS {
  SYSTEM_DEFAULT = 'SYSTEM_DEFAULT',
  LOCAL = 'LOCAL',
  SYSTEM_DEFAULT_AND_LOCAL = 'SYSTEM_DEFAULT_AND_LOCAL',
  REPLACED_SYSTEM_DEFAULT = 'REPLACED_SYSTEM_DEFAULT',
}

enum SELF_CARE_CONTENT_STATUS {
  EDITABLE = 'EDITABLE',
  ONLY_REPLACE = 'ONLY_REPLACE',
}

interface ArticleInfo {
  id: string;
  languageToStatus: { [key in LANGS]?: SELF_CARE_STATUS };
  languageToContentPolicy: { [key in LANGS]?: SELF_CARE_CONTENT_STATUS };
  languages: LANGS[];
  status: SELF_CARE_STATUS;
  contentTypes: RICH_TEXT_CONTENT_TYPES[];
}

export const useDeleteArticleLocalizedFile = () => {
  const queryClient = useQueryClient();
  const articlesApi = useArticlesApi();

  return useMutation({
    mutationFn: ({
      partnerId,
      articleInfo,
      lang,
    }: {
      partnerId?: string | null;
      articleInfo: ArticleInfo;
      lang: LANGS;
    }) => {
      if (!partnerId) {
        throw new Error('Missing partnerId');
      }
      if (!articlesApi) {
        throw new Error('articlesApi is not initialized');
      }
      if (articleInfo.languageToContentPolicy[lang] !== SELF_CARE_CONTENT_STATUS.EDITABLE) {
        throw new Error('Cannot remove non-editable self care advice');
      }

      const articleId = articleInfo.id;
      return articlesApi
        .delete('/text/v1/:type/:textId/:language', undefined, {
          params: {
            type: 'selfcare',
            textId: articleId,
            language: lang,
          },
        })
        .then(() => {
          queryClient.invalidateQueries(
            articlesQueryKeys.getFullArticle({ id: articleId, type: 'selfcare', partnerId })
          );
        });
    },
  });
};

export const useDeleteArticle = () => {
  const articlesApi = useArticlesApi();

  return useMutation({
    mutationFn: async (articleId: string) => {
      if (!articlesApi) {
        throw new Error('articlesApi is not initialized');
      }

      return articlesApi.delete('/articles/v1/:type/:articleId', undefined, {
        params: {
          type: 'selfcare',
          articleId,
        },
      });
    },
  });
};
