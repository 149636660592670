import { parse } from 'query-string';
import { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';

interface QueryParams {
  responseCode?: string | undefined;
}

enum LOGIN_ERROR_CODES {
  UNAUTHORIZED = 'login.error.failed',
  FORBIDDEN = 'login.error.forbidden',
  NOT_FOUND = 'login.error.not_found',
  INTERNAL_ERROR = 'login.error.failed',
  NO_CARE_UNIT = 'login.error.forbidden',
}

export function useLoginErrorCode() {
  const { search } = useLocation();
  const intl = useIntl();
  const { flashMessageService } = useContext(RootStoreContext);

  useEffect(() => {
    const { responseCode }: QueryParams = parse(search);

    if (!!responseCode && responseCode in LOGIN_ERROR_CODES) {
      flashMessageService.error(intl.formatMessage({ id: LOGIN_ERROR_CODES[responseCode] }));
    } else if (responseCode) {
      // default message when code isn't error available
      flashMessageService.error(intl.formatMessage({ id: LOGIN_ERROR_CODES.UNAUTHORIZED }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
}
