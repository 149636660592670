import {
  MedicineBoxOutlined,
  CaretDownFilled,
  CaretRightFilled,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { observer } from 'mobx-react';
import React, { Component, HtmlHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { AccessScopeRole } from 'api/permissionsApi';
import withRootStoreProp, { RootStoreProps } from 'components/HOC/withRootStoreProp';
import OptionalTooltip from 'components/OptionalTooltip';
import { ACCESS_SCOPE_TYPES } from 'constants/roles';

import styles from './ManagePersonalRoles.module.css';
import ManageRolesStore, { ManageViewScope } from '../../stores/ManageRolesStore';
import AddManageRoleModal from '../AddManageRoleModal';
import EditManageRoleModal from '../EditManageRoleModal';
import { isStarLabel } from '../utils';

interface Props extends RootStoreProps, RouteComponentProps<{ id: string }> {}

const addDataTestIdUnit = (scopeType: string) => {
  if (scopeType === ACCESS_SCOPE_TYPES.CARE_PROVIDER) {
    return '-cp';
  } else if (scopeType === ACCESS_SCOPE_TYPES.CARE_UNIT) {
    return '-cu';
  }
  return '';
};

@observer
class ManagePersonalRoles extends Component<Props> {
  manageRolesStore: ManageRolesStore;

  constructor(props: Props) {
    super(props);
    this.manageRolesStore = new ManageRolesStore(props.rootStore, props.match.params.id);
  }

  componentDidMount() {
    this.manageRolesStore.fetchUserScopedRoles();
  }

  onRowHandler = (record: ManageViewScope) =>
    ({
      'data-testid': record['data-testid'] || record?.id,
    } as HtmlHTMLAttributes<{ 'data-testid': string }>);

  getColumns = (): ColumnsType<ManageViewScope> => {
    return [
      {
        title: <FormattedMessage id="roles.scope" />,
        render: this.renderScope,
      },
      {
        title: <FormattedMessage id="roles.role" />,
        render: (_: string, record: ManageViewScope) => {
          if ('roles' in record && record.roles !== undefined && !record?.children?.length) {
            return record.roles.map((r: AccessScopeRole, index: number) => (
              <div key={index} data-testid={`scope-role-${record.scopeValue}-${r.name}`}>
                <OptionalTooltip translationKey={`scoped-role.${r.name}.tooltip`}>
                  <FormattedMessage id={`roles.roleid.${r.name}`} />
                </OptionalTooltip>
              </div>
            ));
          }
          return null;
        },
      },
      {
        title: <FormattedMessage id="general.actions" />,
        width: 100,
        align: 'right',
        render: (_: string, record: ManageViewScope) => {
          return (
            <>
              <Popconfirm
                title={<FormattedMessage id="general.sure-to-delete" />}
                data-testid={`pop-up-${record['data-testid'] || record.id}`}
                cancelText={<FormattedMessage id="general.cancel" />}
                onConfirm={() =>
                  this.manageRolesStore.handleDeleteRole(record, this.props.match.params.id)
                }
              >
                <Button
                  data-testid={`delete-btn${addDataTestIdUnit(record.scopeType)}-${
                    record['data-testid'] || record.id
                  }`}
                  type="link"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </>
          );
        },
      },
    ];
  };

  renderScope = (_: string, record: ManageViewScope) => {
    const allText = <FormattedMessage id="roles.roleid.all" />;
    const isPartner = record.scopeType === ACCESS_SCOPE_TYPES.PARTNER;
    const isCareProvider = record.scopeType === ACCESS_SCOPE_TYPES.CARE_PROVIDER;
    const isOriginGroup =
      record.scopeType === ACCESS_SCOPE_TYPES.ORIGIN && record.children.length > 0;

    if (isPartner) {
      return (
        <span data-testid={`partner-${record['data-testid'] || record.id}`}>
          <MedicineBoxOutlined className={styles.icon} />
          {record.partnerId}
        </span>
      );
    }

    if (isCareProvider) {
      return (
        <span data-testid={`care-provider-${record['data-testid'] || record.id}`}>
          <FormattedMessage id="general.care-provider" />:{' '}
          {isStarLabel(record.scopeName) ? allText : record.scopeName}
        </span>
      );
    }

    if (isOriginGroup) {
      return (
        <span data-testid={`origin-group-${record['data-testid'] || record.id}`}>
          <FormattedMessage id={`access-scope.${record.scopeType}`} />
        </span>
      );
    }
    const label = record.scopeName || record.scopeValue;

    const getText = (record: ManageViewScope, isStarLabel: boolean) => {
      if (record.scopeType === ACCESS_SCOPE_TYPES.CARE_UNIT) {
        return (
          <span data-testid={`care-unit-${record['data-testid'] || record.id}`}>
            <FormattedMessage id="general.care-unit" />: {isStarLabel ? allText : label}
          </span>
        );
      } else if (record.scopeType === ACCESS_SCOPE_TYPES.ORIGIN) {
        return (
          <span data-testid={`origin-${record['data-testid'] || record.id}`}>
            <FormattedMessage id="general.origin" />: {isStarLabel ? allText : label}
          </span>
        );
      }
      return label;
    };
    return getText(record, isStarLabel(label));
  };

  render() {
    const { userPermissionsStore } = this.props.rootStore;
    return (
      <>
        <div className={styles.header}>
          <Typography.Title level={3}>
            <FormattedMessage id="roles.manage24-roles" />
          </Typography.Title>
          <Button
            data-testid="add-manage-role"
            shape="round"
            type="primary"
            onClick={this.manageRolesStore.handleAddRole}
          >
            <FormattedMessage id="add-roles-form.add-btn" />
          </Button>
        </div>
        <Table
          columns={this.getColumns()}
          data-testid="managePersonalRolesTable"
          rowKey="uuid"
          onRow={this.onRowHandler}
          dataSource={this.manageRolesStore.personalManageRoles}
          loading={this.manageRolesStore.isLoading || userPermissionsStore.isLoading()}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              record.children?.length ? (
                <Button
                  type="link"
                  data-testid={`expandable-button-${record['data-testid'] || record.id}`}
                  icon={expanded ? <CaretDownFilled /> : <CaretRightFilled />}
                  onClick={e => onExpand(record, e)}
                />
              ) : (
                <span className={styles.spacer} />
              ),
          }}
          pagination={false}
        />
        {this.manageRolesStore.activeScopedRole && (
          <EditManageRoleModal
            data={this.manageRolesStore.activeScopedRole}
            onSubmit={this.manageRolesStore.handleUpdateRole}
            onClose={this.manageRolesStore.handleClearActiveRole}
          />
        )}
        {this.manageRolesStore.newRole && (
          <AddManageRoleModal
            data={this.manageRolesStore.newRole}
            editedUserScopedRoles={this.manageRolesStore.personalManageRoles}
            practitionerId={this.props.match.params.id}
            onSubmit={this.manageRolesStore.handleSaveAddedRole}
            onClose={this.manageRolesStore.handleClearActiveRole}
          />
        )}
      </>
    );
  }
}

export default withRootStoreProp(withRouter(ManagePersonalRoles));
