import { Text } from '@vx/text';
import React, { FunctionComponent } from 'react';

import { QUESTION_SHORT_RESPONSES } from 'modules/Content24/Condition/constants/code24types';

import { ShapeProps } from './shapeModel';
import styles from './shapes.module.css';

/**
 * @notExported
 */
interface CircleProps extends ShapeProps {
  type?: QUESTION_SHORT_RESPONSES;
  radius?: number;
  cx?: number;
  cy?: number;
}

const Circle: FunctionComponent<CircleProps> = ({
  width = 60,
  height = 60,
  radius = 30,
  cx = 30,
  cy = 30,
  type = QUESTION_SHORT_RESPONSES.YES,
  title,
  text,
  onClick,
  isDimmed,
  className,
  ...restProps
}) => {
  const padding = 2;
  const totalWidth = width + padding;
  const totalHeight = height + padding;
  const textPadding = 15;

  const opacity = isDimmed ? 0.2 : 1;

  return (
    <svg
      width={totalWidth}
      height={totalHeight}
      onClick={onClick}
      className={className}
      {...restProps}
    >
      <circle
        className={styles[type]}
        cx={cx}
        cy={cy}
        r={radius}
        strokeWidth={1}
        opacity={opacity}
      />
      {text && (
        <Text
          width={width - textPadding * 2}
          x="50%"
          y="50%"
          dy="0.3em"
          textAnchor="middle"
          opacity={opacity}
          className={styles.text}
        >
          {text}
        </Text>
      )}
    </svg>
  );
};

export default Circle;
