import isEqual from 'lodash/isEqual';
import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import useDeepCompareEffect from 'use-deep-compare-effect';
import uuid from 'uuid';

import { LANGS } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';
import { TranslatedText } from 'types/types';
import { settleAll } from 'utils/appUtils';

import {
  fetchCode24Text,
  fetchPartnerSpecificAttributes,
  PartnerAttributeWithValueId,
  PartnerAttribute,
  updateCode24Text,
  createCode24Text,
  deleteCode24Text,
  PartnerSpecificAttributes,
} from '../api/partnerCode24api';

export type AttributeWithValue = {
  attribute: PartnerAttributeWithValueId;
  value: TranslatedText;
};

export type UsePartnerAttributesResult = {
  // Used to store partner attribute properties like label and value id
  partnerAttributesWithValues: AttributeWithValue[];
  // Used to store partner attribute value - it would be possible to use
  // partnerAttributesWithValues for that purpose, but the change handled
  // by the handleChange method will be not efficient.
  partnerAttributesValues: Record<string, TranslatedText>;
  handlePartnerAttributeUpdate: () => Promise<string[]>;
  handlePartnerAttributeChange: (id: string, value: string) => void;
  isSavingPartnerAttributes: boolean;
  isAttributesContentDirty: boolean;
};

function getPartnerAttributesValuesFromSource(
  source: AttributeWithValue[],
  previousState: Record<string, TranslatedText>
) {
  return source.reduce((accumulator, attributeWithValue) => {
    return {
      ...accumulator,
      [attributeWithValue.attribute.value]: {
        ...attributeWithValue.value,
        ...previousState[attributeWithValue.attribute.value],
      },
    };
  }, {});
}

export function usePartnerAttributes(
  type: string,
  activeLanguage: LANGS,
  partnerAttributesWithValueId: PartnerAttributeWithValueId[]
): UsePartnerAttributesResult {
  const intl = useIntl();
  const { partnersStore, flashMessageService } = useContext(RootStoreContext);
  const [partnerAttributes, setPartnerAttributes] = useState<PartnerSpecificAttributes>();
  const partnerId = partnersStore.partnerId;
  const [partnerAttributesWithValues, setPartnerAttributesWithValues] = useState<
    AttributeWithValue[]
  >([]);
  const [isSavingPartnerAttributes, setIsSavingPartnerAttributes] = useState<boolean>(false);
  const [partnerAttributesValues, setPartnerAttributesValues] = useState<
    Record<string, TranslatedText>
  >({});
  const initialValues = useRef({});

  const handleChange = (id: string, value: string) => {
    setPartnerAttributesValues({
      ...partnerAttributesValues,
      [id]: { ...partnerAttributesValues[id], [activeLanguage]: value },
    });
  };

  const handleUpdate = async () => {
    setIsSavingPartnerAttributes(true);
    const initialValues = partnerAttributesWithValues.reduce((accumulator, attributeWithValue) => {
      return {
        ...accumulator,
        [attributeWithValue.attribute.value]: attributeWithValue.value,
      };
    }, {});
    const updatedValues = partnerAttributesValues;
    const successfullyUpdatedAttributes = await settleAll(
      Object.keys(initialValues).map(async key => {
        const languageVersions = Object.keys(initialValues[key]) as LANGS[];
        const languageVersionsToUpdate = await settleAll(
          languageVersions.map(async languageKey => {
            const initialValue = initialValues[key][languageKey];
            const updatedValue = updatedValues[key][languageKey];
            const isValueChanged = initialValue && updatedValue && initialValue !== updatedValue;
            const isValueAdded = !initialValue && updatedValue;
            const isValueRemoved = initialValue && !updatedValue;
            const typeId = partnerAttributesWithValues.find(
              ({ attribute }) => attribute.value === key
            )?.attribute.id;

            if (!typeId) {
              throw new Error('Attribute not found');
            }

            if (isValueChanged) {
              await updateCode24Text(partnerId, typeId, key, languageKey, updatedValue);
            }

            if (isValueAdded) {
              await createCode24Text(partnerId, typeId, key, languageKey, updatedValue);
            }

            if (isValueRemoved) {
              await deleteCode24Text(partnerId, typeId, key, languageKey);
            }

            return languageKey;
          })
        );

        const updateError = languageVersionsToUpdate.find(
          updatedLanguageKey => updatedLanguageKey instanceof Error
        );

        return updateError !== undefined ? updateError : key;
      })
    );

    if (successfullyUpdatedAttributes.some(id => id instanceof Error)) {
      flashMessageService.error(
        intl.formatMessage({ id: 'condition-edit.partner-attributes.some-not-saved' })
      );
    }

    setIsSavingPartnerAttributes(false);

    return successfullyUpdatedAttributes.filter(id => !(id instanceof Error)) as string[];
  };

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await fetchPartnerSpecificAttributes(partnerId);
        setPartnerAttributes(data);
      } catch {
        flashMessageService.error(
          intl.formatMessage({ id: 'condition-edit.partner-attributes.not-loaded' })
        );
      }
    }

    loadData();
    // Should run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    // If no partner attributes or all attributes have a value in chosen language, there's no need to fetch anything
    if (
      !partnerAttributes ||
      (partnerAttributesWithValues.length &&
        !partnerAttributesWithValues.some(({ value }) => value[activeLanguage] === undefined))
    ) {
      return;
    }

    let active = true;
    loadData();
    return () => {
      active = false;
    };

    async function loadData() {
      try {
        const availablePartnerAttributesOfType: PartnerAttribute[] =
          partnerAttributes && partnerAttributes[type] ? partnerAttributes[type] : [];
        const partnerAttributesWithFetchedValues = await Promise.all<AttributeWithValue>(
          availablePartnerAttributesOfType.map(async (partnerAttribute: PartnerAttribute) => {
            // Attribute already saved in hook state with its value
            const attributeWithValue = partnerAttributesWithValues.find(
              ({ attribute }) => attribute.id === partnerAttribute.id
            );
            // Attribute with value id delivered from the form
            const attributeWithValueId = partnerAttributesWithValueId.find(
              value => value.id === partnerAttribute.id
            );

            // If there is an attribute with value id delivered from the form but there is no value or no value in active language in hook state, fetch the code24 text
            if (
              attributeWithValueId &&
              (!attributeWithValue || attributeWithValue.value[activeLanguage] === undefined)
            ) {
              // Even though attribute is saved as a whole, with the title displayed in Clinic that was valid in the time of saving,
              // in Manage we should display the current (newest) value of the title, from the attribute definition.
              const attributeWithValueIdAndCurrentTitle = {
                ...attributeWithValueId,
                displayPractitioner: partnerAttribute.displayPractitioner,
              };

              try {
                const { data: code24TextValue } = await fetchCode24Text(
                  partnerId,
                  partnerAttribute.id,
                  attributeWithValueId.value,
                  activeLanguage
                );

                return {
                  attribute: attributeWithValueIdAndCurrentTitle,
                  value: {
                    ...attributeWithValue?.value,
                    [activeLanguage]: String(code24TextValue),
                  },
                };
              } catch (error: any) {
                // Return empty value on error
                // Display error message but only if it's not complaining about not found content
                const isNotFoundError = error.response?.status === 404;

                if (!isNotFoundError) {
                  flashMessageService.error(
                    intl.formatMessage({ id: 'condition-edit.partner-attributes.not-all-loaded' })
                  );
                }

                return {
                  attribute: attributeWithValueIdAndCurrentTitle,
                  value: { ...attributeWithValue?.value, [activeLanguage]: '' },
                };
              }
            }

            // If there is an attribute with value id delivered from the form and there is an attribute with value set in hook state
            // and a corresponding language version value is present there, return it without changes
            if (
              attributeWithValueId &&
              attributeWithValue &&
              attributeWithValue.value[activeLanguage] !== undefined
            ) {
              return attributeWithValue;
            }

            // If there is no attribute with value id delivered from the form:
            // a) return existing attribute with the autogenerated unique id and empty string value (this happens if one language version exists and others not)
            // b) return attribute definition with autogenerated unique id and empty string value (this happens if no language version exists)
            return {
              attribute: attributeWithValue?.attribute || { ...partnerAttribute, value: uuid.v4() },
              value: { ...attributeWithValue?.value, [activeLanguage]: '' },
            };
          })
        );

        if (!active) {
          return;
        }

        setPartnerAttributesWithValues(partnerAttributesWithFetchedValues);
        initialValues.current = getPartnerAttributesValuesFromSource(
          partnerAttributesWithFetchedValues,
          initialValues.current
        );
        setPartnerAttributesValues(
          getPartnerAttributesValuesFromSource(
            partnerAttributesWithFetchedValues,
            partnerAttributesValues
          )
        );
      } catch {
        flashMessageService.error(
          intl.formatMessage({ id: 'condition-edit.partner-attributes.not-loaded' })
        );
      }
    }
  }, [
    activeLanguage,
    partnerAttributesWithValueId,
    partnerAttributesWithValues,
    partnerAttributes,
    partnerAttributesValues,
  ]);

  return {
    partnerAttributesWithValues,
    partnerAttributesValues,
    handlePartnerAttributeUpdate: handleUpdate,
    handlePartnerAttributeChange: handleChange,
    isSavingPartnerAttributes,
    isAttributesContentDirty: !isEqual(partnerAttributesValues, initialValues.current),
  };
}
