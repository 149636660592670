export const BOOKING_SYSTEM_OPTIONS = [
  { label: 'TakeCare', value: 'TAKE_CARE' },
  { label: 'Clinic 24', value: 'CLINIC24' },
];

export const BASIC_DETAILS_FORM_FIELDS = {
  NAME: 'name',
  EXTERNAL_ORG_ID: 'externalOrgId',
  VISITING_ADDRESS: 'visitingAddress',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  BOOKING_SYSTEM: 'bookingSystem',
  LOCATION: 'location',
  PHONE_NUMBER: 'phoneNumber',
  DEFAULT_ORIGIN_ID: 'defaultOriginId',
  REGION_ID: 'regionId',
};
