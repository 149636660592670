import { Collapse, Space, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import css from './SwedishSecureBankIdLogin.module.css';

export const SwedishSecureBankIdLoginInstructions = ({ text }: { text?: string | null }) => {
  return (
    <Space align="center" direction="vertical">
      <Typography.Title className={css.instructionTitle}>
        <FormattedMessage id="login.bank-id.instruction-title" />
      </Typography.Title>
      <Typography className={css.instructionDescription}>
        {text ? (
          text
        ) : (
          <span data-testid="login.default-instruction-text">
            <FormattedMessage id="login.bank-id.instruction-description" />
          </span>
        )}
      </Typography>
    </Space>
  );
};
