import axios from 'axios';

import { HasUUID, FetchOriginRulesResponse } from 'types/types';

import { batchAddUUID, removeUUID, batchRemoveUUID } from './uuidUtils';

// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchCollectionWithUUID<T extends HasUUID>(url: string, ...args: any[]) {
  const response = await axios.get<T[]>(url, ...args);

  if (!Array.isArray(response.data)) {
    throw Error('"data" is not an Array');
  }

  response.data = batchAddUUID(response.data);

  return response;
}

// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function createWithUUID<T extends HasUUID>(url: string, data: T, ...args: any[]) {
  const cleanData = removeUUID(data);
  return axios.post(url, cleanData, ...args);
}

// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function updateWithUUID<T extends HasUUID>(url: string, data: T[], ...args: any[]) {
  const cleanData = batchRemoveUUID(data);
  return axios.put(url, cleanData, ...args);
}

// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchOriginRulesWithUUID<T extends HasUUID>(url: string, ...args: any[]) {
  const response = await axios.get<FetchOriginRulesResponse<T>>(url, ...args);

  response.data.default = batchAddUUID(response.data.default);
  response.data.origin = batchAddUUID(response.data.origin);

  return response;
}
