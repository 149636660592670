import { Typography } from 'antd';
import map from 'lodash/map';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { OriginSave } from 'api/originsApi';
import { PARTNER_ROLES } from 'constants/roles';
import RootStoreContext from 'context/RootStoreContext';

import styles from './RootOriginBasicDetails.module.css';
import OriginsDetailsForm from '../components/OriginsDetailsForm';

interface Props extends WrappedComponentProps, RouteComponentProps<{ originId: string }> {}

@observer
class RootOriginBasicDetails extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable isAddRoleModalShown = false;

  originRolesOptions = map(PARTNER_ROLES, role => ({ label: role, value: role }));

  componentDidMount() {
    this.context.practitionersStore.fetchPractitioners();
  }

  handleSubmit = async (data: OriginSave) => {
    const {
      intl,
      match: {
        params: { originId },
      },
    } = this.props;
    const { originStore, flashMessageService } = this.context;

    try {
      await originStore.updateCurrentOrigin(originId, data);

      flashMessageService.success(intl.formatMessage({ id: 'origins.origin-updated-message' }));
      /* eslint-disable no-empty */
    } catch {}
  };

  render() {
    const { userPermissionsStore, originStore } = this.context;

    if (!originStore.currentOrigin) {
      return null;
    }

    return (
      <div className={styles.container} data-testid="root-origin-basic-details">
        <Typography.Title level={2}>
          <FormattedMessage id="origins.basic-details" />
        </Typography.Title>
        <OriginsDetailsForm
          initialValues={originStore.currentOrigin}
          isSaving={originStore.isSaving() || originStore.isLoading()}
          onSubmit={this.handleSubmit}
          isDisabled={
            /**
             * NB: user should be able to edit origin if user is a super admin, has origin role write or admin or has new role config admin in the origin in question
             * TODO: remove !userPermissionsStore.canEditCurrentPartner after the migration to new roles
             */
            !userPermissionsStore.canEditCurrentPartner &&
            !this.context.userPermissionsStore.canEditOrigin(this.props.match.params.originId)
          }
        />
      </div>
    );
  }
}

export default injectIntl(RootOriginBasicDetails);
