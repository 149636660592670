import React from 'react';
import { NormalComponents } from 'react-markdown/lib/complex-types';

import { Image } from 'components/Image';

export const MarkdownImage: NormalComponents['img'] = ({ src, alt }) => {
  if (src && alt) {
    return <Image src={src} alt={alt} />;
  }
  return null;
};
